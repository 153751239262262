import axios from "axios";
import { toast } from "react-toastify";
import { authorizationHeaders } from "../../../../services/queries";
import {
  LOAD_DATA_ERROR,
  LOAD_DATA_START,
  LOAD_DATA_SUCCESS,
  SUBMIT_QUERY
} from "../../../common/redux/actions/search/searchActions";
import { CUSTOMER_INVOICES } from "../../../common/redux/types/viewTypes";
import { extractQueryParameters } from "../../../../services/query";

export const SET_CUSTOMER_INVOICES_COUNTRIES_DROPDOWN = payload => ({
  type: "SET_CUSTOMER_INVOICES_COUNTRIES_DROPDOWN",
  payload: payload
});

export const getCustomerInvoiceQuery = payload => {
  return dispatch => {
    dispatch(SUBMIT_QUERY(CUSTOMER_INVOICES, payload));
    dispatch(LOAD_DATA_START(CUSTOMER_INVOICES));
    CustomerInvoiceQuery(payload)
      .then(data => dispatch(LOAD_DATA_SUCCESS(CUSTOMER_INVOICES, data)))
      .catch(error => {
        dispatch(LOAD_DATA_ERROR(CUSTOMER_INVOICES, error));
        toast.error(error.message);
      });
  };
};

export const getCustomerInvoicesDropdownData = () => {
  return dispatch => {
    getCustomerInvoiceCountriesDropdownData().then(d =>
      dispatch(SET_CUSTOMER_INVOICES_COUNTRIES_DROPDOWN(d))
    );
  };
};

//QUERIES

export const getCustomerInvoiceCountriesDropdownData = async () => {
  try {
    const data = await axios.post(
      "/graphql",
      {
        query: `
    query {
      countries {
        id
        code
      }
     }
`
      },
      authorizationHeaders()
    );
    return data.data.data.countries;
  } catch (err) {
    toast.error("Error loading countries");
    return [];
  }
};

export const CustomerInvoiceQuery = async payload => {
  const queryData = extractQueryParameters(payload);
  console.log(queryData);
  const data = await axios.post(
    "/graphql",
    {
      query: `
    query {
      customerInvoices(${queryData}) {
        id
        company{name}
        customerId
        invoiceNo
        totalInEur
        invoiceDate
        dueDate
        netto
        currentStatusCl
        vat
        reseller{name}
        exchangeRateToEur
        issuedCountryId
        currencyCl
        totalInSecondCurrency
        customerInvoicePaymentHistory {
          id,
          paymentTime,
          payment,
          invoiceBalance,
          paymentBalance
        }
        customerInvoiceStatusHistory {
          id
          statusCl
          createdOn
          createdByUser {
            id
            firstName
          }
        }
      }
  }
  `
    },
    authorizationHeaders()
  );
  return data.data.data.customerInvoices;
};

const getCustomerInvoiceQueryData = ({
  count,
  customer,
  invoice_no,
  is_it_paid,
  is_virtual,
  issued_country,
  invoice_status,
  customer_country,
  reseller,
  date_from,
  due_date_until,
  date_to
}) => {
  const queryData = `${count ? `count: ${count}` : ""} ${
    customer ? `customer: "${customer}"` : ""
  } ${invoice_no ? `invoice: "${invoice_no}"` : ""} 
  ${issued_country ? `issued_country: ${issued_country}` : ""}
  ${is_it_paid ? `is_it_paid: "${is_it_paid}"` : ""} 
  ${invoice_status ? `invoice_status: "${invoice_status}"` : ""}
  ${reseller ? `reseller: ${reseller}` : ""}
  ${customer_country ? `customer_country: ${customer_country}` : ""}
  ${due_date_until ? `due_date_until: "${due_date_until}"` : ""}
  ${is_virtual ? `is_it_virtual: "${is_virtual}"` : ""} ${
    date_from ? `date_from: "${date_from}"` : ""
  } ${date_to ? `date_to: "${date_to}"` : ""}`;
  return queryData;
};
