import React from "react";
import { Formik } from "formik";
import {
  FormBody,
  FormCol,
  FormFlexWrapper,
  InputField,
  SelectInputField
} from "../../../common/form/form";
import { Button } from "../../../common/buttons/button";
import useAppClassifications from "../../../layout/hooks/useAppClassifications";

const CustomerInvoiceDetails = ({ data, t }) => {
  const CustomerInvoiceTransNs = "customerInvoice.";
  const { customerInvoicesStatusHistoryTypes } = useAppClassifications();
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        company: data.company ? data.company[0].name : "",
        netto: data.netto || "",
        exchangeRateToEur: data.exchangeRateToEur || "",
        invoiceNo: data.invoiceNo || "",
        vat: data.vat || "",
        dueDate: data.dueDate || "",
        invoiceDate: data.invoiceDate || "",
        invoiceStatus: data.currentStatusCl || "",
        balance: data.balance || "",
        totalAmount: data.totalInEur || ""
      }}
      onSubmit={(values, { setSubmitting }) => {
        console.log("SUBMIT");
      }}
    >
      {({
        values,
        errors,
        status,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting
      }) => (
        <FormBody>
          <FormFlexWrapper>
            <FormCol className="u-margin-right-small">
              <InputField
                id="customerInvoicesDetailsCustomer"
                name="customer"
                disabled={true}
                classes="form__group form__group--display"
                onChange={handleChange}
                label={t(CustomerInvoiceTransNs + "CustomerName")}
                value={values.company}
              />
              <InputField
                id="customerInvoicesDetailsNetAmount"
                name="net__amount"
                classes="form__group form__group--display"
                disabled={true}
                onChange={handleChange}
                label={t(CustomerInvoiceTransNs + "NetAmountStr")}
                value={values.netto}
              />
              <InputField
                id="customerInvoicesDetailsExchangeRate"
                name="exchange_rate"
                disabled={true}
                onChange={handleChange}
                classes="form__group form__group--display"
                label={t(CustomerInvoiceTransNs + "ExchangeRate")}
                value={values.exchangeRateToEur}
              />

              <Button
                btnStyle="primary"
                size="small"
                onChange={handleChange}
                style={{ width: "30%" }}
                classes="button__flex__noGrow"
                label={t("customerInvoice.details.OpenPDF")}
              />
            </FormCol>
            <FormCol>
              <InputField
                id="customerInvoicesDetailsInvoiceNo"
                name="invoice_no"
                disabled={true}
                onChange={handleChange}
                classes="form__group form__group--display"
                label={t(CustomerInvoiceTransNs + "InvoiceNo")}
                value={values.invoiceNo}
              />
              <InputField
                id="customerInvoicesDetailsVat"
                name="vat"
                disabled={true}
                onChange={handleChange}
                classes="form__group form__group--display"
                label={t(CustomerInvoiceTransNs + "VatAmountStr")}
                value={values.vat}
              />
              <InputField
                id="customerInvoicesDetailsDueDate"
                name="due_date"
                disabled={true}
                onChange={handleChange}
                classes="form__group form__group--display"
                label={t(CustomerInvoiceTransNs + "DueDate")}
                value={values.dueDate}
              />
              <InputField
                id="customerInvoicesDetailsInvoiceDate"
                name="invoice_date"
                disabled={true}
                onChange={handleChange}
                classes="form__group form__group--display"
                label={t(CustomerInvoiceTransNs + "InvoiceDate")}
                value={values.invoiceDate}
              />
            </FormCol>

            <FormCol className="u-margin-left-small">
              <SelectInputField
                label={t(CustomerInvoiceTransNs + "details.CurrentStatus")}
                id="currentStatus"
                classes="form__group"
                onChange={handleChange}
                value={values.invoiceStatus}
                options={
                  customerInvoicesStatusHistoryTypes.length
                    ? [
                        { code: t("countries.None"), id: "" },
                        ...customerInvoicesStatusHistoryTypes.map(item => {
                          return Object.assign(
                            {},
                            {
                              name: t(
                                `classifications.CONTRACT_STATUS.${
                                  item.name.split(".")[1]
                                }`
                              ),
                              value: item.value
                            }
                          );
                        })
                      ]
                    : []
                }
              />
              <InputField
                id="customerInvoicesDetailsBalance"
                name="balance"
                disabled={true}
                onChange={handleChange}
                classes="form__group form__group--display"
                label={t(CustomerInvoiceTransNs + "details.BalanceStr")}
                value={values.balance}
              />

              <InputField
                id="customerInvoicesDetailsTotalAmount"
                name="total_amount"
                disabled={true}
                onChange={handleChange}
                classes="form__group form__group--display"
                label={t(CustomerInvoiceTransNs + "TotalAmountEurStr")}
                value={values.totalAmount}
              />
            </FormCol>
          </FormFlexWrapper>
        </FormBody>
      )}
    </Formik>
  );
};

export default CustomerInvoiceDetails;
