import React from "react";
import { useTranslation } from "react-i18next";
import CustomerContactInformationTable from "./customerContactInformationTable";
import CustomerContactInformationForm from "./forms/customerContactInformationForm";
import useTabState from "../../../common/redux/hooks/useTabState";
import useTabTableActionDispatchers from "../../../common/redux/actions/table/useTabTableActionDispatchers";
import {
  CONTACT_INFORMATION,
  CUSTOMERS
} from "../../../common/redux/types/viewTypes";
import CustomerContactInformationAddressForm from "./customerContactInformationAddressForm";

const CustomerContactInformation = () => {
  const { t } = useTranslation();
  const { selectedItem } = useTabState("customers", "contactInformation");

  const { handleRowSelect } = useTabTableActionDispatchers(
    CUSTOMERS,
    CONTACT_INFORMATION
  );

  return (
    <div className="form form--flex" style={{ paddingBottom: "0.7rem" }}>
      <div className="form-col--small u-margin-right-tiny">
        <h3 className="u-margin-bottom-small">Contact Persons</h3>
        <CustomerContactInformationTable
          t={t}
          handleRowClick={handleRowSelect}
          selectedRow={selectedItem}
        />
        <CustomerContactInformationAddressForm t={t} data={selectedItem} />
      </div>
      <div className="form-col">
        <CustomerContactInformationForm t={t} data={selectedItem} />
      </div>
    </div>
  );
};

export default CustomerContactInformation;
