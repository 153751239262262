import React from "react";
import { Formik } from "formik";
import {
  FormBody,
  FormFlexWrapper,
  InputField,
  SelectInputField
} from "../../../../common/form/form";
import { Button } from "../../../../common/buttons/button";

const BankImportGeneralForm = ({ t, data }) => {
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        customer: data.customer ? data.customer[0].name : "",
        chipCurrentStatusCl: data.chipCurrentStatusCl || "",
        magneticStripeCurrentStatusCl: data.magneticStripeCurrentStatusCl || "",
        vehicleRegistrationNo: data.vehicleRegistrationNo || "",
        vehicleRegistrationNoForInvoice:
          data.vehicleRegistrationNoForInvoice || "",
        cardType: data.cardType ? data.cardType[0].cardTypeCl : "",
        chipNo: data.chipNo || "",
        magneticStripeNo: data.magneticStripeNo || "",
        isActiveCl: data.isActiveCl || "",
        chipLastUsedDate: data.chipLastUsedDate || "",
        validFrom: data.validFrom || "",
        validThru: data.validThru || "",
        additionalInfo: data.additionalInfo || ""
      }}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          alert(JSON.stringify(values, null, 2));
          setSubmitting(false);
        }, 400);
      }}
    >
      {({
        values,
        errors,
        status,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting
      }) => (
        <FormBody>
          <FormFlexWrapper direction="column">
            <SelectInputField
              id="bankFileImportGeneralBank"
              name="bank"
              classes="form__group"
              options={[{ name: "", value: "" }]}
              style={{ width: "30%" }}
              label={t("bankFileImport.Bank")}
            />
            <FormFlexWrapper style={{ alignItems: "center" }}>
              <InputField
                id="bankFileImportGeneralBankFile"
                name="bank_file"
                classes="form__group"
                style={{ width: "50%" }}
                options={[{ name: "", value: "" }]}
                label={t("bankFileImport.BankFile")}
              />
              <Button
                btnStyle="primary"
                label={t("bankFileImport.BtnOpenFile")}
                size="medium"
                style={{
                  height: "50%",
                  alignSelf: "center",
                  marginLeft: "1rem"
                }}
              />
            </FormFlexWrapper>
          </FormFlexWrapper>
        </FormBody>
      )}
    </Formik>
  );
};

export default BankImportGeneralForm;
