import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import FactoringInvoicesSectionDetailsForm from "../details/factoringInvoicesSectionDetailsForm";
import useFactoringInvoicesState from "./useFactoringInvoicesState";
import {
  details,
  factoringInvoices,
  paymentHistory
} from "../../../common/redux/types/selectorTypes";
import {
  DETAILS,
  FACTORING_INVOICES,
  PAYMENT_HISTORY
} from "../../../common/redux/types/viewTypes";
import FactoringInvoicesPaymentHistoryTable from "../payment_history/factoringInvoicesPaymentHistoryTable";

const useFactoringInvoicesConsts = () => {
  const { t } = useTranslation();
  const { selectedRow } = useFactoringInvoicesState();
  const filterLabels = {
    customer: t("factoring.invoices.FactoringInvoice"),
    factoring_debtor: t("factoring.invoices.FactoringDebtor"),
    invoice_no: t("factoring.invoices.InvoiceNo"),
    contract_annex_no: t("factoring.invoices.ContractAnnexNo"),
    is_in_contract_annex: t("factoring.invoices.FilterIsInContractAnnex"),
    is_paid: t("factoring.invoices.FilterIsPaid"),
    date_from: t("factoring.invoices.DateFrom"),
    date_to: t("factoring.invoices.DateTo"),
    due_date_from: t("factoring.invoices.DueDateFrom"),
    due_date_to: t("factoring.invoices.DueDateTo"),
    is_summary_invoice: t("factoring.invoices.FilterIsSummaryInvoice"),
    has_cancellation: t("factoring.invoices.FilterHasCancellation"),
    count: "Result Count"
  };

  const tabs = useMemo(
    () => [
      {
        label: t("factoring.invoices.FactoringInvoiceDetails"),
        component: (
          <FactoringInvoicesSectionDetailsForm t={t} data={selectedRow} />
        ),
        viewSelector: factoringInvoices,
        viewType: FACTORING_INVOICES,
        tabSelector: details,
        tabType: DETAILS
      },
      {
        label: t("factoring.invoices.FactoringInvoicePaymentHistory"),
        component: (
          <FactoringInvoicesPaymentHistoryTable t={t} data={selectedRow} />
        ),
        viewSelector: factoringInvoices,
        viewType: FACTORING_INVOICES,
        tabSelector: paymentHistory,
        tabType: PAYMENT_HISTORY
      }
    ],
    [t, selectedRow]
  );

  return { filterLabels, tabs };
};

export default useFactoringInvoicesConsts;
