import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Table from "../../../common/table";
import useTabTableActionDispatchers from "../../../common/redux/actions/table/useTabTableActionDispatchers";
import {
  CLIENT_AND_INVOICE_SETTINGS,
  FACTORING_DEBTOR
} from "../../../common/redux/types/viewTypes";

const FactoringDebtorClientInvoiceSettingsTable = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { handleRowSelect } = useTabTableActionDispatchers(
    FACTORING_DEBTOR,
    CLIENT_AND_INVOICE_SETTINGS
  );

  const selectedRow = useSelector(
    state => state.factoringDebtor.tabs.clientAndInvoiceSettings.selectedItem
  );
  const data = useSelector(
    state => state.factoringDebtor.data.selectedItem.customerBankAccounts
  );
  const getColumns = () => {
    const columns = [
      {
        Header: t("customer.customerInvoiceSettings.BankAccount"),
        accessor: "bankAccount"
      },
      {
        id: "isPrimary",
        Header: t("customer.customerInvoiceSettings.Primary"),
        accessor: d => {
          return d.isPrimary ? "✓" : "";
        }
      }
    ];
    return columns;
  };

  return (
    <Table
      data={data || []}
      columns={getColumns()}
      onRowSelect={handleRowSelect}
      height={200}
      selectedRow={selectedRow}
    />
  );
};
export default FactoringDebtorClientInvoiceSettingsTable;
