import React, { useRef } from "react";
import {
  SectionBody,
  SectionHeader,
  SectionMain
} from "../../../common/section";
import usePartnerLayoutActions from "../../hooks/layout/usePartnerLayoutActions";
import usePartnerLayoutState from "../../hooks/layout/usePartnerLayoutState";
import PartnerTable from "../../partnerTable";
import { useTranslation } from "react-i18next";
import usePartnerState from "../../hooks/usePartnerState";
import PartnerButtons from "./partnerButtons";
import usePartnerActions from "../../hooks/usePartnerActions";
import usePartnerConsts from "../../hooks/usePartnerConsts";

const PartnerMainSection = () => {
  const { t } = useTranslation();
  const tableRef = useRef();
  const {
    handleSearchToggle,
    handleTableToggle,
    handleExpandersToggle
  } = usePartnerLayoutActions();
  const {
    tableVisible,
    expandersVisible,
    searchOpen
  } = usePartnerLayoutState();
  const { searchQuery, selectedRow } = usePartnerState();
  const { filterLabels, tabs } = usePartnerConsts();
  const { handleSearch, handleRowSelect } = usePartnerActions();

  return (
    <SectionMain toggleSearch={handleSearchToggle}>
      <SectionHeader
        sectionHeading={t("partner.Partner")}
        getFilters={payload => {
          return payload;
        }}
        searchQuery={searchQuery}
        filterLabels={filterLabels}
        tableVisible={tableVisible}
        expandersVisible={expandersVisible}
        tableEnabled={true}
        expandersEnabled={true}
        tabsEnabled={false}
        onToggleTable={handleTableToggle}
        onToggleExpanders={handleExpandersToggle}
        onToggleSearch={handleSearchToggle}
        onSearch={handleSearch}
        searchOpen={searchOpen}
      />

      <SectionBody
        TableComponent={PartnerTable}
        ButtonsComponent={PartnerButtons}
        shortcutsEnabled={true}
        selectedRow={selectedRow}
        tableVisible={tableVisible}
        onRowSelect={handleRowSelect}
        tableRef={tableRef}
        expandersEnabled={expandersVisible}
        tabs={tabs}
      />
    </SectionMain>
  );
};

export default PartnerMainSection;
