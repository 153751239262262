import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Table from "../../common/table";
import { TableResultData } from "../../common/tableFooter";
import useDataState from "../../common/redux/hooks/useDataState";
import { factoringDebtorAccount } from "../../common/redux/types/selectorTypes";
import useTableState from "../../common/redux/hooks/useTableState";
import useTableLayoutActionDispatchers from "../../common/redux/actions/layout/table/hooks/useTableLayoutActionDispatchers";
import { FACTORING_DEBTOR_ACCOUNT } from "../../common/redux/types/viewTypes";
import useTableActionDispatchers from "../../common/redux/actions/table/hooks/useTableActionDispatchers";
import { numberWithSpaces } from "../../../services/numberFunctions";

const FactoringDebtorAccountTable = props => {
  const { t } = useTranslation();
  const { items, selectedItem, isLoading } = useDataState(
    factoringDebtorAccount
  );
  const { height } = useTableState(factoringDebtorAccount);
  const { handleTableHeightSet } = useTableLayoutActionDispatchers(
    FACTORING_DEBTOR_ACCOUNT
  );
  const { handleRowSelect } = useTableActionDispatchers(
    FACTORING_DEBTOR_ACCOUNT
  );

  const getColumns = useCallback(() => {
    const TranslationNS = "customerAccount.";
    const columns = [
      {
        Header: t(TranslationNS + "EntryNumber"),
        accessor: "entryNo"
      },
      {
        id: "company",
        Header: t(TranslationNS + "CustomerName"),
        accessor: d => (d.customer ? d.customer[0].name : "")
      },
      {
        Header: t(TranslationNS + "EntryDate"),
        accessor: "entryTime",
        width: 100
      },
      {
        id: "sum",
        Header: t(TranslationNS + "details.Amount"),
        accessor: d => (d.sum ? numberWithSpaces(d.sum) : ""),
        cellAlign: "right"
      },
      {
        id: "balance",
        cellAlign: "right",
        Header: t(TranslationNS + "EntryBalance"),
        accessor: d => (d.balance ? numberWithSpaces(d.balance) : "")
      },
      {
        id: "entrySourceTypeCl",
        Header: t(TranslationNS + "EntrySourceType"),
        accessor: d => {
          switch (d.entrySourceTypeCl) {
            case "ENTRY_SOURCE_TYPE.FACTORING_INVOICE": {
              return t("factoring.invoices.FactoringInvoice");
            }
            default: {
              return "";
            }
          }
        }
      },
      {
        Header: t(TranslationNS + "EntrySource"),
        accessor: "entrySource"
      },
      {
        Header: t(TranslationNS + "AdditionalInfo"),
        accessor: "additionalInfo"
      }
    ];
    return columns;
  }, [t]);

  const tableColumns = useMemo(() => getColumns(), [getColumns]);

  return (
    <React.Fragment>
      <Table
        columns={tableColumns}
        isLoading={isLoading}
        onRowSelect={handleRowSelect}
        selectedRow={selectedItem}
        selectedRowsState={[]}
        data={items || []}
        height={height}
        setTableHeight={handleTableHeightSet}
      />
      <TableResultData
        count={items.length}
        enabled={false}
        selectedRows={[]}
        onRowSelect={handleRowSelect}
        selectedRow={selectedItem}
        onRemoveItem={() => {}}
      />
    </React.Fragment>
  );
};

export default FactoringDebtorAccountTable;
