import React from "react";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import {
  FormBody,
  FormCol,
  InputField,
  SelectInputField
} from "../../../common/form/form";
import { useSelector } from "react-redux";

const FactoringDebtorAddressesForm = () => {
  const { t } = useTranslation();
  const countries = useSelector(
    state => state.layout.classifications.countries
  );
  const legalAddress = useSelector(
    state => state.factoringDebtor.data.selectedItem.legalAddress
  );
  const postalAddress = useSelector(
    state => state.factoringDebtor.data.selectedItem.postalAddress
  );

  const boardMemberAddress = useSelector(
    state => state.factoringDebtor.data.selectedItem.boardMemberAddress
  );
  const AddressTranslation = "customer.customerAddresses.";
  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={{
          legalAddressCountry:
            legalAddress && legalAddress[0].country
              ? legalAddress[0].country[0].code
              : "",
          legalAddressCity:
            legalAddress && legalAddress[0].city
              ? legalAddress[0].city[0].name
              : "",
          legalAddressStreet: legalAddress
            ? legalAddress[0].street[0].name
            : "",
          legalAddressHouse: legalAddress
            ? legalAddress[0].houseOrApartment
            : "",
          legalAddressZipCode: legalAddress ? legalAddress[0].zipCode : "",

          postalAddressCountry:
            postalAddress && postalAddress[0].country
              ? postalAddress[0].country[0].code
              : "",
          postalAddressCity:
            postalAddress && postalAddress[0].city
              ? postalAddress[0].city[0].name
              : "",
          // postalAddressStreet: postalAddress
          //   ? postalAddress[0]
          //     ? postalAddress[0].street[0].name
          //     : ""
          //   : "",
          postalAddressStreet: postalAddress
            ? postalAddress.length
              ? postalAddress[0].street
                ? postalAddress[0].street[0].name
                : ""
              : ""
            : "",
          postalAddressHouse: postalAddress
            ? postalAddress[0].houseOrApartment
            : "",
          postalAddressZipCode: postalAddress ? postalAddress[0].zipCode : "",

          boardMemberAddressCountry:
            boardMemberAddress && boardMemberAddress.length
              ? boardMemberAddress[0].country[0].code
              : "",
          boardMemberAddressCity:
            boardMemberAddress && boardMemberAddress[0].city
              ? boardMemberAddress[0].city[0].name
              : "",
          boardMemberAddressStreet:
            boardMemberAddress && boardMemberAddress[0].street
              ? boardMemberAddress[0].street[0].name
              : "",
          boardMemberAddressHouse: boardMemberAddress
            ? boardMemberAddress[0].houseOrApartment
            : "",
          boardMemberAddressZipCode:
            boardMemberAddress && boardMemberAddress.length
              ? boardMemberAddress[0].zipCode
              : ""
        }}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            alert(JSON.stringify(values, null, 2));
            setSubmitting(false);
          }, 400);
        }}
      >
        {({ isSubmitting, handleChange, values }) => (
          <FormBody flex={"true"}>
            <FormCol>
              <h4 className="heading__quaternary u-margin-bottom-small">
                {t(AddressTranslation + "HeaderLegalAddress")}
              </h4>
              <SelectInputField
                label={t(AddressTranslation + "Country")}
                options={[
                  { name: "", value: "" },
                  ...countries.map(item => {
                    return Object.assign(
                      {},
                      {
                        name: t(`countries.${item.code}`),
                        value: item.code
                      }
                    );
                  })
                ]}
                classes="form__group"
                value={values.legalAddressCountry}
                onChange={handleChange}
                id="legalAddressCountry"
              />
              <InputField
                label={t(AddressTranslation + "City")}
                classes="form__group"
                value={values.legalAddressCity}
                onChange={handleChange}
                id="legalAddressCity"
              />
              <InputField
                label={t(AddressTranslation + "Street")}
                id="legalAddressStreet"
                value={values.legalAddressStreet}
                onChange={handleChange}
                classes="form__group"
              />
              <InputField
                label={t(AddressTranslation + "House")}
                id="legalAddressHouse"
                value={values.legalAddressHouse}
                onChange={handleChange}
                classes="form__group"
              />
              <InputField
                label={t(AddressTranslation + "ZipCode")}
                id="legalAddressZipCode"
                value={values.legalAddressZipCode}
                onChange={handleChange}
                classes="form__group"
              />
            </FormCol>
            <FormCol className="u-margin-left-small">
              <h4 className="heading__quaternary u-margin-bottom-small">
                {t(AddressTranslation + "HeaderPostalAddress")}
              </h4>
              <SelectInputField
                label={t(AddressTranslation + "Country")}
                options={[
                  { name: "", value: "" },
                  ...countries.map(item => {
                    return Object.assign(
                      {},
                      {
                        name: t(`countries.${item.code}`),
                        value: item.code
                      }
                    );
                  })
                ]}
                onChange={handleChange}
                classes="form__group"
                value={values.postalAddressCountry}
                id="postalAddressCountry"
              />
              <InputField
                label={t(AddressTranslation + "City")}
                classes="form__group"
                onChange={handleChange}
                value={values.postalAddressCity}
                id="postalAddressCity"
              />
              <InputField
                label={t(AddressTranslation + "Street")}
                id="postalAddressStreet"
                onChange={handleChange}
                value={values.postalAddressStreet}
                classes="form__group"
              />
              <InputField
                label={t(AddressTranslation + "House")}
                id="postalAddressHouse"
                onChange={handleChange}
                value={values.postalAddressHouse}
                classes="form__group"
              />
              <InputField
                label={t(AddressTranslation + "ZipCode")}
                id="postalAddressZipCode"
                onChange={handleChange}
                value={values.postalAddressZipCode}
                classes="form__group"
              />
            </FormCol>
            <FormCol className="u-margin-left-small">
              <h4 className="heading__quaternary u-margin-bottom-small">
                {t(AddressTranslation + "HeaderBoardMemberAddress")}
              </h4>
              <SelectInputField
                label={t(AddressTranslation + "Country")}
                options={[
                  { name: "", value: "" },
                  ...countries.map(item => {
                    return Object.assign(
                      {},
                      {
                        name: t(`countries.${item.code}`),
                        value: item.code
                      }
                    );
                  })
                ]}
                onChange={handleChange}
                classes="form__group"
                value={values.boardMemberAddressCountry}
                id="boardMemberAddressCountry"
              />
              <InputField
                label={t(AddressTranslation + "City")}
                classes="form__group"
                onChange={handleChange}
                value={values.boardMemberAddressCity}
                id="boardMemberAddressCity"
              />
              <InputField
                label={t(AddressTranslation + "Street")}
                id="boardMemberAddressStreet"
                onChange={handleChange}
                value={values.boardMemberAddressStreet}
                classes="form__group"
              />
              <InputField
                label={t(AddressTranslation + "House")}
                id="boardMemberAddressHouse"
                onChange={handleChange}
                value={values.boardMemberAddressHouse}
                classes="form__group"
              />
              <InputField
                label={t(AddressTranslation + "ZipCode")}
                id="boardMemberAddressZipCode"
                onChange={handleChange}
                value={values.boardMemberAddressZipCode}
                classes="form__group"
              />
            </FormCol>
          </FormBody>
        )}
      </Formik>
    </div>
  );
};

export default FactoringDebtorAddressesForm;
