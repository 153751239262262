import {useCallback} from "react";
import {useDispatch} from "react-redux";
import {
  TOGGLE_CUSTOMER_DEPOSIT_EXPANDER_VIEW,
  TOGGLE_CUSTOMER_DEPOSIT_SEARCH,
  TOGGLE_CUSTOMER_DEPOSIT_TABLE
} from "../../actions/layout/customerDepositLayoutActions";

const useCustomerDepositLayoutActions = () => {
  const dispatch = useDispatch();

  const handleTableToggle = useCallback(() => {
    dispatch(TOGGLE_CUSTOMER_DEPOSIT_TABLE());
  }, [dispatch]);

  const handleExpandersToggle = useCallback(() => {
    dispatch(TOGGLE_CUSTOMER_DEPOSIT_EXPANDER_VIEW());
  }, [dispatch]);

  const handleSearchToggle = useCallback(() => {
    dispatch(TOGGLE_CUSTOMER_DEPOSIT_SEARCH());
  }, [dispatch]);

  return { handleTableToggle, handleExpandersToggle, handleSearchToggle };
};

export default useCustomerDepositLayoutActions;
