import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Table from "../../../common/table";
import useTabState from "../../../common/redux/hooks/useTabState";
import {
  contactInformation,
  factoringDebtor
} from "../../../common/redux/types/selectorTypes";
import useTabTableActionDispatchers from "../../../common/redux/actions/table/useTabTableActionDispatchers";
import {
  CONTACT_INFORMATION,
  FACTORING_DEBTOR
} from "../../../common/redux/types/viewTypes";
import useDataState from "../../../common/redux/hooks/useDataState";

const FactoringDebtorContactPersonsTable = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { table, selectedItem } = useTabState(
    factoringDebtor,
    contactInformation
  );
  const { handleRowSelect } = useTabTableActionDispatchers(
    FACTORING_DEBTOR,
    CONTACT_INFORMATION
  );

  const { selectedItem: data } = useDataState(factoringDebtor);

  const getColumns = () => {
    const columns = [
      {
        id: "name",
        Header: t("customer.customerContactData.PersonName"),
        accessor: d => d.name
      },
      {
        id: "contactPersonTypeCl",
        Header: t("customer.customerContactData.Position"),
        accessor: d => {
          return t(
            `classifications.CONTACT_PERSON_TYPE.${
              d.contactPersonTypeCl.split(".")[1]
            }`
          );
        }
      },
      {
        Header: t("customer.customerContactData.PersonSignatory"),
        accessor: "hasSignatureRight"
      },
      {
        id: "isPrimary",
        Header: t("customer.customerContactData.Primary"),
        accessor: d => d.isPrimary || "X",
        cellAlign: "center"
      }
    ];
    return columns;
  };

  return (
    <Table
      columns={getColumns()}
      selectedRow={selectedItem}
      onRowSelect={handleRowSelect}
      height={table.height}
      data={data.customerContactPerson || []}
    />
  );
};
export default FactoringDebtorContactPersonsTable;
