import React from "react";
import { Formik } from "formik";
import {
  FormBody,
  FormCol,
  InputField,
  SelectInputField
} from "../../../../common/form/form";
import { Loading } from "../../../../common/loading";
import useFormsActionDispatchers from "../../../../common/redux/actions/forms/hooks/useFormsActionDispatchers";
import { CUSTOMERS } from "../../../../common/redux/types/viewTypes";
import useFormsState from "../../../../common/redux/hooks/useFormsState";
import { customers } from "../../../../common/redux/types/selectorTypes";
import {
  getFormikChangeHack,
  handleInputChange
} from "../../../../../services/formService";
import AutoCompleteField from "../../../../common/autocomplete/autocompleteField";
import { loadCityAutocompleteData } from "../../../../common/autocomplete/graphql/fetchCityAutocomplete";
import { loadStreetAutocompleteData } from "../../../../common/autocomplete/graphql/fetchStreetAutocomplete";

const CustomerFormAddresses = ({ data, t }) => {
  const AddressTranslation = "customer.customerAddresses.";
  const { handleFormsChange } = useFormsActionDispatchers(CUSTOMERS);
  const { formsState } = useFormsState(customers);

  // useEffect(() => {
  //   if (!_.isEmpty(formsState, handleFormsChange)) {
  //     handleFormsChange(Object.assign(formsState, handleFormsChange, data));
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [data]);

  if (data) {
    const dataLegal = data.legalAddress ? data.legalAddress[0] : {};
    const dataPostal = data.postalAddress ? data.postalAddress[0] : {};
    const dataBoardMember = data.boardMemberAddress
      ? data.boardMemberAddress[0]
      : {};

    return (
      <div>
        <Formik
          enableReinitialize={true}
          validate={values => getFormikChangeHack(handleFormsChange, values)}
          initialValues={{
            legalAddressCountry: dataLegal.country
              ? dataLegal.country[0].code
              : "",
            legalAddressCity: dataLegal.city ? dataLegal.city[0].name : "",
            legalAddressStreet: dataLegal.street
              ? dataLegal.street[0].name
              : "",
            legalAddressHouse: dataLegal.houseOrApartment
              ? dataLegal.houseOrApartment
              : "",
            legalAddressZipCode: dataLegal.zipCode || "",
            postalAddressCountry: dataPostal.country
              ? dataPostal.country[0].code
              : "",
            postalAddressCity: dataPostal.city ? dataPostal.city[0].name : "",
            postalAddressStreet: dataPostal.street
              ? dataPostal.street[0].name
              : "",
            postalAddressHouse: dataPostal.houseOrApartment || "",
            postalAddressZipCode: dataPostal.zipCode || ""
          }}
          onSubmit={(values, { setSubmitting }) => {}}
        >
          {({ isSubmitting, handleChange, values, setFieldValue }) => (
            <FormBody flex={"true"}>
              <FormCol>
                <h4 className="heading__quaternary u-margin-bottom-small">
                  {t(AddressTranslation + "HeaderLegalAddress")}
                </h4>
                <SelectInputField
                  id="legalAddressCountry"
                  label={t(AddressTranslation + "Country")}
                  options={[
                    { name: "", value: "" },
                    { name: "Estonia", value: "EE" }
                  ]}
                  classes="form__group"
                  value={values.legalAddressCountry}
                  onChange={e => {
                    handleChange(e);
                  }}
                />
                {/*<InputField*/}
                {/*  label={t(AddressTranslation + "City")}*/}
                {/*  name="legalAddressCity"*/}
                {/*  classes="form__group"*/}
                {/*  value={values.legalAddressCity}*/}
                {/*  onChange={e => {*/}
                {/*    handleChange(e);*/}
                {/*    handleInputChange(e, values, formsState, handleFormsChange);*/}
                {/*  }}*/}
                {/*  id="legalAddressCity"*/}
                {/*/>*/}
                <AutoCompleteField
                  type="text"
                  classes="form__group"
                  id="legalAddressCity"
                  name="legalAddressCity"
                  value={values.legalAddressCity}
                  handleChange={setFieldValue}
                  label={t(AddressTranslation + "City")}
                  autocomplete="password"
                  data-lpignore="true"
                  fetchAutcompleteData={loadCityAutocompleteData}
                />
                <AutoCompleteField
                  type="text"
                  classes="form__group"
                  id="customersAddressesLegalAddressStreet"
                  name="legalAddressStreet"
                  value={values.legalAddressStreet}
                  handleChange={setFieldValue}
                  label={t(AddressTranslation + "Street")}
                  autocomplete="password"
                  data-lpignore="true"
                  fetchAutcompleteData={loadStreetAutocompleteData}
                />

                <InputField
                  label={t(AddressTranslation + "House")}
                  id="legalAddressHouse"
                  value={values.legalAddressHouse}
                  onChange={e => {
                    handleChange(e);
                    handleInputChange(e, values, formsState, handleFormsChange);
                  }}
                  classes="form__group"
                />
                <InputField
                  label={t(AddressTranslation + "ZipCode")}
                  id="legalAddressZipCode"
                  value={values.legalAddressZipCode}
                  onChange={e => {
                    handleChange(e);
                  }}
                  classes="form__group"
                />
              </FormCol>
              <FormCol className="u-margin-left-small">
                <h4 className="heading__quaternary u-margin-bottom-small">
                  {t(AddressTranslation + "HeaderPostalAddress")}
                </h4>
                <SelectInputField
                  label={t(AddressTranslation + "Country")}
                  options={[
                    { name: "", value: "" },
                    { name: "Estonia", value: "EE" }
                  ]}
                  classes="form__group"
                  value={values.postalAddressCountry}
                  onChange={e => {
                    handleChange(e);
                  }}
                  id="postalAddressCountry"
                />

                <AutoCompleteField
                  type="text"
                  classes="form__group"
                  id="customersAddressesPostalAddressCity"
                  name="postalAddressCity"
                  value={values.postalAddressCity}
                  handleChange={setFieldValue}
                  label={t(AddressTranslation + "City")}
                  autocomplete="password"
                  data-lpignore="true"
                  fetchAutcompleteData={loadCityAutocompleteData}
                />
                <AutoCompleteField
                  type="text"
                  classes="form__group"
                  id="customersAddressesPostalAddressStreet"
                  name="postalAddressStreet"
                  value={values.postalAddressStreet}
                  handleChange={setFieldValue}
                  label={t(AddressTranslation + "Street")}
                  autocomplete="password"
                  data-lpignore="true"
                  fetchAutcompleteData={loadStreetAutocompleteData}
                />
                <InputField
                  label={t(AddressTranslation + "House")}
                  id="postalAddressHouse"
                  value={values.postalAddressHouse}
                  onChange={e => {
                    handleChange(e);
                  }}
                  classes="form__group"
                />
                <InputField
                  label={t(AddressTranslation + "ZipCode")}
                  id="postalAddressZipCode"
                  value={values.postalAddressZipCode}
                  onChange={e => {
                    handleChange(e);
                  }}
                  classes="form__group"
                />
              </FormCol>
            </FormBody>
          )}
        </Formik>
      </div>
    );
  } else {
    return <Loading />;
  }
};

export default CustomerFormAddresses;
