import axios from 'axios';
import {authorizationHeaders} from "../../../services/queries";


export const fetchContractExpanders = async (invoiceType, ) => {

    const data = await axios.post("/graphql", {
        query: `
        query {
            contractExpanderClassification(
            contract_type: "${invoiceType}"){
            expanderKey
            }
        }
        `}, authorizationHeaders());

    return data.data.data.contractExpanderClassification;

};