import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Table from "../../../common/table";
import useTabState from "../../../common/redux/hooks/useTabState";
import { customers } from "../../../common/redux/types/selectorTypes";
import useDataState from "../../../common/redux/hooks/useDataState";
import useTabTableActionDispatchers from "../../../common/redux/actions/table/useTabTableActionDispatchers";
import {
  CONTRACT_OVERVIEW,
  CUSTOMERS
} from "../../../common/redux/types/viewTypes";

const CustomerContractOverviewTable = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { selectedItem } = useDataState(customers);
  const { table } = useTabState(customers, "contractOverview");
  const {
    handleTableHeightSet,
    handleRowSelect
  } = useTabTableActionDispatchers(CUSTOMERS, CONTRACT_OVERVIEW);
  const CustomerContractOverviewTrans = "customer.contractOverview.";
  const columns = [
    {
      Header: t(CustomerContractOverviewTrans + "ContractNoFilter"),
      accessor: "contractNo"
    },
    {
      Header: t(CustomerContractOverviewTrans + "DateFilter"),
      accessor: "currentStatusDate"
    },
    {
      id: "contractTypeCl",
      Header: t(CustomerContractOverviewTrans + "TypeFilter"),
      accessor: d =>
        d.contractTypeCl ? t(`classifications.${d.contractTypeCl}`) : ""
    },
    {
      Header: t(CustomerContractOverviewTrans + "ConditionsFilter"),
      accessor: "conditions"
    }
  ];

  return (
    <Table
      classes="u-margin-top-small"
      height={table.height}
      setTableHeight={handleTableHeightSet}
      columns={columns}
      data={selectedItem.contracts || []}
      selectedRow={selectedItem}
      onRowSelect={handleRowSelect}
    />
  );
};

export default CustomerContractOverviewTable;
