import axios from "axios";
import { extractQueryParameters } from "../../../../services/query";

export const fetchBrokerClaimsData = async payload => {
  const queryData = extractQueryParameters(payload);
  console.log(queryData);
  const data = await axios.post("/graphql", {
    query: `
        query {
            brokerClaims(${queryData}){
            id
            customer{name}
        customerId
        claimDate
        brokerClaimTypeCl
        claimNo
        sum
        additionalInfo
        addToInvoice
        customerInvoice{invoiceNo}

            }
        
        }
        `
  });
  console.log(data);
  return data;
};
