import {
  CLEAR_CUSTOMERS_FORMS,
  SUBMIT_ADD_NEW_CUSTOMER_QUERY_ERROR,
  SUBMIT_ADD_NEW_CUSTOMER_QUERY_START,
  SUBMIT_ADD_NEW_CUSTOMER_QUERY_SUCCESS,
  SUBMIT_DELETE_CUSTOMER_QUERY_ERROR,
  SUBMIT_DELETE_CUSTOMER_QUERY_START,
  SUBMIT_DELETE_CUSTOMER_QUERY_SUCCESS,
  SUBMIT_UPDATE_CUSTOMER_QUERY_ERROR,
  SUBMIT_UPDATE_CUSTOMER_QUERY_START,
  SUBMIT_UPDATE_CUSTOMER_QUERY_SUCCESS
} from "./customersCrudActions";
import { CLEAR_CUSTOMERS_SELECTED_ROW } from "../../actions/customersActions";
import axios from "axios";
import { toast } from "react-toastify";
import { authorizationHeaders } from "../../../../../services/queries";
import { loadCustomers } from "../customersDataQueries";
import { extractQueryParameters } from "../../../../../services/query";

export const addNewCustomer = payload => {
  return dispatch => {
    dispatch(SUBMIT_ADD_NEW_CUSTOMER_QUERY_START());
    console.log("add new payload", payload);
    addNewCustomerQuery(payload)
      .then(d => {
        dispatch(SUBMIT_ADD_NEW_CUSTOMER_QUERY_SUCCESS(d));
        toast.success(`Successfully added CUSTOMER - ${payload.name}`);
        dispatch(CLEAR_CUSTOMERS_SELECTED_ROW());
        dispatch(CLEAR_CUSTOMERS_FORMS());
      })
      .catch(err => {
        dispatch(SUBMIT_ADD_NEW_CUSTOMER_QUERY_ERROR(err));
        toast.error(`Error adding CUSTOMER - ${payload.name}`);
      });
  };
};

export const deleteCustomer = (payload, searchQuery) => {
  return dispatch => {
    dispatch(SUBMIT_DELETE_CUSTOMER_QUERY_START());
    deleteCompanyQuery(payload)
      .then(d => {
        dispatch(SUBMIT_DELETE_CUSTOMER_QUERY_SUCCESS(d));
        dispatch(loadCustomers(searchQuery));
        dispatch(CLEAR_CUSTOMERS_SELECTED_ROW());
      })
      .catch(err => dispatch(SUBMIT_DELETE_CUSTOMER_QUERY_ERROR(err)));
  };
};

export const addNewCustomerQuery = async payload => {
  const queryParameters = extractQueryParameters(payload);
  const data = await axios.post(
    "/graphql",
    {
      query: `mutation {
          createCompanyMutation(input: {
            ${queryParameters}
            
            invoiceLanguageCl:"LANGUAGE.ET-EE"
            invoiceCurrencyCl:"CURRENCY.EUR"
            isInvoiceAutoClearingAllowedCl:"BOOLEAN.NO"
            invoicingFrequencyCl:"INVOICING_FREQUENCY_TYPE.WEEKLY"
            isDiscountCl:"BOOLEAN.YES"
            isYxInNokCl:"BOOLEAN.NO"
            isSendingOriginalInvoiceAllowedCl:"BOOLEAN.NO"
            companyTypeCl:"COMPANY_TYPE.CUSTOMER"
          })
    }`
    },
    authorizationHeaders()
  );
  return data.data.data.createCompanyMutation;
};

export const updateCustomerQuery = async payload => {
  const queryParameters = extractQueryParameters(payload);
  const data = await axios.post(
    "/graphql",
    {
      query: `mutation {
          updateCompanyMutation(input: {
            isDeleted : false
            ${queryParameters}
            invoiceLanguageCl: "LANGUAGE.ET-EE"
            invoiceCurrencyCl: "CURRENCY.EUR"
            invoicingFrequencyCl: "INVOICING_FREQUENCY_TYPE.WEEKLY"
            isInvoiceAutoClearingAllowedCl: "BOOLEAN.NO"
            isYxInNokCl: "BOOLEAN.NO"
            isDiscountCl: "BOOLEAN.NO"
            isSendingOriginalInvoiceAllowedCl : "BOOLEAN.NO"
            createdOn: "2019-1-1"
            modifiedByUserId:1
            modifiedOn: "2019-1-1"
            penaltyPercent:0
            companyTypeCl: "COMPANY_TYPE.CUSTOMER"
          })
    }`
    },
    authorizationHeaders()
  );
  return data.data.data.createCompany;
};

export const deleteCompanyQuery = async payload => {
  console.log(payload);
  console.log("DELETE COMPANY");
  const data = await axios.post(
    "/graphql",
    {
      query: `mutation {
        deleteCompanyMutation(input: {
          id:${payload}
          
        }) {
          id
        }
       }
       `
    },
    authorizationHeaders()
  );
  return data;
};

export const updateCustomer = payload => {
  console.log("update payload", payload);
  return dispatch => {
    dispatch(SUBMIT_UPDATE_CUSTOMER_QUERY_START());
    updateCustomerQuery(payload)
      .then(d => {
        dispatch(SUBMIT_UPDATE_CUSTOMER_QUERY_SUCCESS(d));
        toast.success(`Successfully updated CUSTOMER - ${payload.name}`);
      })
      .catch(err => {
        dispatch(SUBMIT_UPDATE_CUSTOMER_QUERY_ERROR(err));
        console.log(err.message);
      });
  };
};
