import React from "react";
import {
  FormCol,
  InputField,
  SelectInputField
} from "../../../common/form/form";
import { Formik } from "formik";
import useAppClassifications from "../../../layout/hooks/useAppClassifications";

const CustomerContactInformationAddressForm = ({ t, data }) => {
  const AddressTranslation = "customer.customerAddresses.";
  const { countries } = useAppClassifications();
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        country: data.address ? data.address[0].country[0].code : "",
        city: data.address ? data.address[0].city : "",
        street: data.address ? data.address[0].street : "",
        houseOrApartment: data.address ? data.address[0].houseOrApartment : "",
        zipCode: data.address ? data.address[0].zipCode : ""
      }}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          alert(JSON.stringify(values, null, 2));
          setSubmitting(false);
        }, 400);
      }}
    >
      {({ values, handleChange }) => (
        <FormCol>
          <h3 className="u-margin-bottom-small u-margin-top-small">Address</h3>
          <SelectInputField
            id="customersContactPersonAddressCountry"
            name="country"
            label={t(AddressTranslation + "Country")}
            options={[
              { name: "", value: "" },
              ...countries.map(item => {
                return {
                  name: t(`countries.${item.code}`),
                  value: item.code
                };
              })
            ]}
            classes="form__group-stack"
            value={values.country}
            onChange={e => {
              handleChange(e);
            }}
          />
          <InputField
            id="customersContactPersonAddressCity"
            name="city"
            label={t(AddressTranslation + "City")}
            classes="form__group-stack"
            value={values.city}
            onChange={e => {
              handleChange(e);
            }}
          />
          <InputField
            id="customersContactPersonAddressStreet"
            name="street"
            label={t(AddressTranslation + "Street")}
            value={values.street}
            onChange={e => {
              handleChange(e);
            }}
            classes="form__group-stack"
          />
          <InputField
            id="customersContactPersonAddressHouseOrApartment"
            name="houseOrApartment"
            label={t(AddressTranslation + "House")}
            value={values.houseOrApartment}
            onChange={e => {
              handleChange(e);
            }}
            classes="form__group-stack"
          />
          <InputField
            id="customersContactPersonAddressZipCode"
            name="zipCode"
            label={t(AddressTranslation + "ZipCode")}
            value={values.zipCode}
            onChange={e => {
              handleChange(e);
            }}
            classes="form__group-stack"
          />
        </FormCol>
      )}
    </Formik>
  );
};

export default CustomerContactInformationAddressForm;
