import React from "react";
import { Formik } from "formik";
import {
  BoolSelectInputField,
  DateInputField,
  FormBody,
  FormCol,
  InputField
} from "../../../common/form/form";
import { handleInputChange } from "../../../../services/formService";
import { useTranslation } from "react-i18next";
import useFormsState from "../../../common/redux/hooks/useFormsState";
import { factoringDebtorOverdueInvoices } from "../../../common/redux/types/selectorTypes";
import useFormsActionDispatchers from "../../../common/redux/actions/forms/hooks/useFormsActionDispatchers";
import { FACTORING_DEBTOR_OVERDUE_INVOICES } from "../../../common/redux/types/viewTypes";

const FactoringDebtorOverdueInvoicesDetailsForm = ({ data }) => {
  const { t } = useTranslation();
  const { formsState } = useFormsState(factoringDebtorOverdueInvoices);
  const { handleFormsChange } = useFormsActionDispatchers(
    FACTORING_DEBTOR_OVERDUE_INVOICES
  );
  const TranslationNS = "factoring.debtor.overdueInvoices.";
  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={{
          customerName: data.customerName || "",
          invoiceNo: data.invoiceNo || "",
          invoiceDate: data.invoiceDate || "",
          totalInPcy: data.totalInPcy || "",
          dueDate: data.dueDate || "",
          isEmailSentCl: data.isEmailSentCl || "",
          issuedForInvoiceNo: ""
        }}
        onSubmit={(values, { setSubmitting, resetForm }) => {}}
      >
        {({
          values,
          errors,
          state,
          status,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          resetForm
        }) => (
          <FormBody>
            <div className="form--flex">
              <FormCol>
                <InputField
                  label={t(TranslationNS + "FilterFactoringDebtor")}
                  type="text"
                  classes="form__group"
                  name="customerName"
                  id="customerDetailsFormNo"
                  value={values.customerName}
                  onBlur={handleBlur}
                  onChange={e => {
                    handleChange(e);
                    handleInputChange(e, values, formsState, handleFormsChange);
                  }}
                />

                <InputField
                  label={t(TranslationNS + "TotalPenalties")}
                  name="totalInPcy"
                  type="text"
                  id="factoringDebtorOverdueInvoicesTotalInPcy"
                  classes="form__group"
                  value={values.totalInPcy}
                  onChange={e => {
                    handleChange(e);
                    handleInputChange(e, values, formsState, handleFormsChange);
                  }}
                />
                <BoolSelectInputField
                  label={t(TranslationNS + "FilterIsEmailSent")}
                  type="text"
                  name="isEmailSentCl"
                  isCl
                  id="factoringDebtorOverdueInvoicesIsEmailSent"
                  value={values.isEmailSentCl}
                  classes="form__group"
                  onChange={e => {
                    handleChange(e);
                    handleInputChange(e, values, formsState, handleFormsChange);
                  }}
                />
              </FormCol>

              <FormCol className="u-margin-left-small">
                <InputField
                  label={t(TranslationNS + "FilterInvoiceNo")}
                  type="text"
                  classes="form__group"
                  name="invoiceNo"
                  id="factoringDebtorOverdueInvoicesInvoiceNo"
                  value={values.invoiceNo}
                  onChange={e => {
                    handleChange(e);
                    handleInputChange(e, values, formsState, handleFormsChange);
                  }}
                />
                <InputField
                  label={t(TranslationNS + "FilterIssuedForInvoiceNo")}
                  type="text"
                  classes="form__group"
                  name="issuedForInvoiceNo"
                  value={values.issuedForInvoiceNo}
                  id="factoringDebtorOverdueInvoicesIssuedForInvoiceNo"
                  onChange={e => {
                    handleChange(e);
                    handleInputChange(e, values, formsState, handleFormsChange);
                  }}
                />
              </FormCol>
              <FormCol className="u-margin-left-small">
                <DateInputField
                  label={t(TranslationNS + "PenaltyInvoiceDate")}
                  type="text"
                  classes="form__group"
                  name="invoiceDate"
                  id="factoringDebtorOverdueInvoicesInvoiceDate"
                  value={values.invoiceDate}
                  onChange={e => {
                    handleChange(e);
                    handleInputChange(e, values, formsState, handleFormsChange);
                  }}
                />
                <DateInputField
                  label={t(TranslationNS + "DueDate")}
                  type="text"
                  classes="form__group"
                  name="dueDate"
                  value={values.dueDate}
                  id="factoringDebtorOverdueInvoicesDueDate"
                  onChange={e => {
                    handleChange(e);
                    handleInputChange(e, values, formsState, handleFormsChange);
                  }}
                />
              </FormCol>
            </div>
          </FormBody>
        )}
      </Formik>
    </div>
  );
};

export default FactoringDebtorOverdueInvoicesDetailsForm;
