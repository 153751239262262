import React from "react";
import { Button } from "../../../common/buttons/button";
import { useTranslation } from "react-i18next";

const ServiceButtons = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Button
        btnStyle="primary"
        size="small"
        classes="btn--footer"
        label={t("service.BtnAdd")}
        disabled={true}
      />
      <Button
        btnStyle="danger"
        size="small"
        disabled={true}
        classes="btn--footer"
        label={t("service.BtnCancel")}
      />
      <Button
        btnStyle="danger"
        size="small"
        disabled={true}
        classes="btn--footer"
        label={t("service.BtnDelete")}
      />
      <Button
        btnStyle="green"
        size="small"
        disabled={true}
        classes="btn--footer"
        label={t("service.BtnSave")}
      />
      <Button
        btnStyle="lime"
        size="small"
        disabled={true}
        classes="btn--footer"
        label={t("service.BtnAddToInvoice")}
      />
      <Button
        btnStyle="lime"
        size="small"
        disabled={true}
        classes="btn--footer"
        label={t("service.ServicesToCsv")}
      />
    </React.Fragment>
  );
};

export default ServiceButtons;
