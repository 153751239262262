import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import {
  FormBody,
  FormCol,
  FormFlexWrapper,
  InputField,
  MultilineInputField
} from "../../../common/form/form";

const FactoringDebtorAccDetailsForm = () => {
  const { t } = useTranslation();
  const data = useSelector(
    state => state.factoringDebtorAccount.data.selectedItem
  );

  const getEntrySourceTypeCl = entrySourceTypeCl => {
    switch (entrySourceTypeCl) {
      case "ENTRY_SOURCE_TYPE.FACTORING_INVOICE": {
        return t("factoring.invoices.FactoringInvoice");
      }
      default: {
        return "";
      }
    }
  };

  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={{
          factoringDebtor: data.customer ? data.customer[0].name : "",
          entryTime: data.entryTime || "",
          entrySource: data.entrySource || "",
          transactionEntry: data.entrySourceTypeCl
            ? data.entrySourceTypeCl
            : "",
          sum: data.sum || "",
          additionalInfo: data.additionalInfo || ""
        }}
        onSubmit={(values, { setSubmitting }) => {}}
      >
        {({
          values,
          errors,
          status,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting
        }) => (
          <FormBody flex={"true"}>
            <FormFlexWrapper direction="column" style={{ width: "100%" }}>
              <FormFlexWrapper>
                <FormCol>
                  <InputField
                    type="text"
                    id="factoringDebtor"
                    value={values.factoringDebtor}
                    classes="form__group"
                    label={t("customerAccount.FactoringDebtor")}
                    onChange={handleChange}
                  />
                  <FormFlexWrapper>
                    <InputField
                      type="text"
                      classes="form__group"
                      id="factoringDebtorAccountDetailsEntryTime"
                      name="entryTime"
                      value={values.entryTime}
                      label={t("customerAccount.EntryDate")}
                      onChange={handleChange}
                      disabled={true}
                    />
                    <InputField
                      type="text"
                      classes="form__group u-margin-left-small"
                      id="factoringDebtorAccountDetailsSum"
                      name="sum"
                      value={values.sum}
                      label={t("customerAccount.details.Total")}
                      onChange={handleChange}
                    />
                  </FormFlexWrapper>
                </FormCol>
                <FormCol className="u-margin-left-small">
                  <InputField
                    type="text"
                    id="transactionEntry"
                    value={getEntrySourceTypeCl(values.transactionEntry)}
                    classes="form__group"
                    label={t("customerAccount.EntrySourceType")}
                    onChange={handleChange}
                  />
                  <InputField
                    type="text"
                    classes="form__group"
                    id="entrySource"
                    value={values.entrySource}
                    label={t("customerAccount.EntrySource")}
                    onChange={handleChange}
                  />
                </FormCol>
              </FormFlexWrapper>
              <MultilineInputField
                id="factoringDebtorAccountDetailsAdditionalInfo"
                name="additionalInfo"
                classes="form__group"
                label="Additional info"
              />
            </FormFlexWrapper>
          </FormBody>
        )}
      </Formik>
    </div>
  );
};

export default FactoringDebtorAccDetailsForm;
