import React, { useRef } from "react";
import { SearchSection } from "../../../../common/section";
import useFactoringDebtorAccountLayoutState from "../../hooks/layout/useFactoringDebtorAccountLayoutState";
import FactoringDebtorAccountVerticalSearchForm from "../../search/forms/factoringDebtorAccountVertialSearchForm";
import { useTranslation } from "react-i18next";
import useLayoutActionDispatchers from "../../../../common/redux/actions/layout/hooks/useLayoutActionDispatchers";
import { FACTORING_DEBTOR_ACCOUNT } from "../../../../common/redux/types/viewTypes";
import useSearchState from "../../../../common/redux/hooks/useSearchState";
import { factoringDebtorAccount } from "../../../../common/redux/types/selectorTypes";
import useDataState from "../../../../common/redux/hooks/useDataState";
import { loadData } from "../../../../common/graphql/loadData";
import { fetchFactoringDebtorAccData } from "../../graphql/factoringDebtorDataQueries";
import { useDispatch } from "react-redux";

const FactoringDebtorAccountSearchSection = () => {
  const { t } = useTranslation();
  const { searchOpen } = useFactoringDebtorAccountLayoutState();
  const dispatch = useDispatch();
  const { handleSearchToggle } = useLayoutActionDispatchers(
    FACTORING_DEBTOR_ACCOUNT
  );
  const formRef = useRef();
  const { isLoading } = useDataState(factoringDebtorAccount);
  const { searchQuery } = useSearchState(factoringDebtorAccount);
  return (
    <SearchSection
      isOpen={searchOpen}
      formRef={formRef}
      onSearchToggle={handleSearchToggle}
      dataLoading={isLoading}
    >
      <FactoringDebtorAccountVerticalSearchForm
        ref={formRef}
        t={t}
        handleSearch={payload =>
          dispatch(
            loadData(
              payload,
              FACTORING_DEBTOR_ACCOUNT,
              "customerAccounts",
              fetchFactoringDebtorAccData
            )
          )
        }
        query={searchQuery}
      />
    </SearchSection>
  );
};

export default FactoringDebtorAccountSearchSection;
