import axios from "axios";
import { extractQueryParameters } from "../../../../services/query";
import { toast } from "react-toastify";
import { authorizationHeaders } from "../../../../services/queries";
import {
  LOAD_DATA_ERROR,
  LOAD_DATA_START,
  LOAD_DATA_SUCCESS,
  SUBMIT_QUERY
} from "../../../common/redux/actions/search/searchActions";
import { CUSTOMER_ACCOUNT } from "../../../common/redux/types/viewTypes";

export const loadCustomerAccountData = payload => {
  console.log(payload, "CUSTOMERACC PAYLOAD");
  return dispatch => {
    dispatch(SUBMIT_QUERY(CUSTOMER_ACCOUNT, payload));
    dispatch(LOAD_DATA_START(CUSTOMER_ACCOUNT));
    customerAccountQuery(payload)
      .then(data =>
        dispatch(
          LOAD_DATA_SUCCESS(CUSTOMER_ACCOUNT, data.data.data.customerAccounts)
        )
      )
      .catch(error => {
        toast.error(error.message);
        dispatch(LOAD_DATA_ERROR(CUSTOMER_ACCOUNT, error));
      });
  };
};

const customerAccountQuery = async payload => {
  const queryData = extractQueryParameters(payload);
  const data = await axios.post(
    "/graphql",
    {
      query: `
      query {
        customerAccounts(${queryData}) {
          entryBalance
          id
          entryNo
          customer {
            name
          }
          entryTime
          sum
          total
          entrySourceTypeCl
          entrySource
          additionalInfo
          relatedCustomerClearingDocumentItems {
            id
            description
            balance
            sum
            created_on
            customer_account_id
            customer_clearing_document_id
            entry_time
            additional_info
          }
        }
    }
  
    `
    },
    authorizationHeaders()
  );
  return data;
};
