import axios from "axios";
import { authorizationHeaders } from "../../../../services/queries";
import { extractQueryParameters } from "../../../../services/query";

export const fetchFactoringDebtorData = async payload => {
  const queryData = extractQueryParameters(payload);
  const data = await axios.post(
    "/graphql",
    {
      query: `
    query{
      companies(${queryData}, type: FACTORING_DEBTOR){
        remainingCreditLimit
        id
        name
        debt
        creditLimit
        balance
        customerCreditLimitLog{
          createdOn
          creditLimit
        }
        customerContactPerson {      
          name
          contactPersonTypeCl
          languageCl
          personalCode
          phone
          email
          additionalInfo
          
          documentNo
          identificationDate
          documentIssueDate
          documentExpireDate
        }
        legalAddress {      
          country {        
            code        
          }
          city {      
            name
          }
          street {
            name
          }
          houseOrApartment
          zipCode
        }
        postalAddress {
          country {        
            code        
          }
          city {
            name
          }
          street {
            name
          }
          houseOrApartment
          zipCode
        }
        boardMemberAddress {      
          country {        
            code        
          }
          city {
            name
          }
          street {
            name
          }
          houseOrApartment
          zipCode
        }
        regNo
        createdOn
        
        customerContactPerson{
          name,
          id,
          personalCode,
          isPrimary,
          hasSignatureRight,
          contactPersonTypeCl,
          additionalInfo
        }

        customerComments{
          id,
          comment,
          createdOn,
          createdByUserId,
          customerId
          
        }
        customerVatPayer {
          country {
            code
          }
          number
        }
        customerExcisePayer {
          country {
            code
          }
          number
        }
        customerBankAccounts{bankAccount isPrimary id}

        customerCreditLimitLog{id createdOn creditLimit}

        contracts(type: "K"){
          guarantorPerson {
            name
          }
          validThru
          amountGuaranteed
        }
    paymentTermInDays,
    penaltyPercent,
    idsPaymentTermInDays,
    automaticPenaltyFrom,
    invoiceEmail,
    isDiscountCl,
    businessAreaCode,
    isSendingOriginalInvoiceAllowedCl,
    invoiceLanguageCl,
    invoiceCurrencyCl,
    isYxInNokCl,
    polskaVatRegionCode

        isActiveCl
        generalFax
        generalEmail
        generalPhone
        additionalInfo
        
      }
     }
      `
    },
    authorizationHeaders()
  );
  return data;
};
