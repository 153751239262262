import React, { forwardRef } from "react";
import { Formik } from "formik";
import {
  BoolSelectInputField,
  InputField,
  SelectInputField
} from "../../../common/form/form";
import { useTranslation } from "react-i18next";

const SalespointVerticalSearchForm = forwardRef(
  ({ handleSearch, query }, ref) => {
    const { t } = useTranslation();
    return (
      <div className="section__search-form">
        <Formik
          ref={ref}
          enableReinitialize={true}
          initialValues={{}}
          onSubmit={(values, { setSubmitting }) => {
            alert("SUBMIT");
          }}
        >
          {({
            values,
            handleChange,
            handleSubmit,
            validateField,
            validateOnChange,
            errors,
            touched,
            setFieldValue
          }) => (
            <form onSubmit={handleSubmit}>
              <InputField
                id="salespointSearchSalespointNo"
                name="salespoint_no"
                classes="form__group-stack"
                label={t("salespoint.SalesPointNo")}
                value={""}
                onChange={handleChange}
              />
              <InputField
                id="salespointSearchSalespointName"
                name="salespoint_name"
                classes="form__group-stack"
                label={t("salespoint.SalesPointName")}
                value={""}
                onChange={handleChange}
              />

              <SelectInputField
                id="salespointSearchSalespointGroup"
                name="salespoint_group"
                classes="form__group-stack"
                options={[{ name: "", value: "" }]}
                label={t("salespoint.SalesPointGroupName")}
                value={""}
                onChange={handleChange}
              />
              <InputField
                id="salespointSearchCode"
                name="code"
                classes="form__group-stack"
                label={t("salespoint.HeaderCode")}
                value={""}
                onChange={handleChange}
              />
              <SelectInputField
                id="salespointSearchPartner"
                name="salespoint_partner"
                classes="form__group-stack"
                options={[{ name: "", value: "" }]}
                label={t("salespoint.FilterPartner")}
                value={""}
                onChange={handleChange}
              />
              <SelectInputField
                id="salespointSearchCountry"
                name="salespoint_country"
                classes="form__group-stack"
                options={[{ name: "", value: "" }]}
                label={t("salespoint.FilterCountry")}
                value={""}
                onChange={handleChange}
              />
              <SelectInputField
                id="salespointSearchLogoType"
                name="logo_type"
                classes="form__group-stack"
                options={[{ name: "", value: "" }]}
                label={t("salespoint.LogoType")}
                value={""}
                onChange={handleChange}
              />
              <BoolSelectInputField
                id="salespointSearchShowOnWeb"
                name="show_on_web"
                classes="form__group-stack"
                label={t("salespoint.ShowOnWeb")}
                value={""}
                onChange={handleChange}
              />
              <BoolSelectInputField
                id="salespointSearchIsPreferable"
                name="is_preferable"
                classes="form__group-stack"
                label={t("salespoint.IsPreferable")}
                value={""}
                onChange={handleChange}
              />

              <BoolSelectInputField
                id="salespointSearchCardType"
                name="card_type"
                classes="form__group-stack"
                options={[{ name: "", value: "" }]}
                label={t("salespoint.CardType")}
                value={""}
                onChange={handleChange}
              />
              <InputField
                id="salespointSearchResultCount"
                name="count"
                classes="form__group-stack"
                label={t("partner.RowCount")}
                value={""}
                onChange={handleChange}
              />
            </form>
          )}
        </Formik>
      </div>
    );
  }
);

export default SalespointVerticalSearchForm;
