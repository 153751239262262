import { useEffect } from "react";
import { checkIfDatabaseIsUp } from "../graphql/layoutQueries";
import useInterval from "../../common/utils/hooks/useInterval";
import { useDispatch } from "react-redux";

const useDbUpTimeCheck = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(checkIfDatabaseIsUp());
  }, [dispatch]);

  useInterval(() => {
    dispatch(checkIfDatabaseIsUp());
  }, 30000);
};

export default useDbUpTimeCheck;
