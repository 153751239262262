import React, { forwardRef, useEffect, useState } from "react";
import { Formik } from "formik";
import { useSelector } from "react-redux";
import {
  BoolSelectInputField,
  DateInputField,
  InputField,
  SelectInputField
} from "../../../../common/form/form";

import { loadCompanyAutocompleteData } from "../../../../common/autocomplete/graphql/fetchCustomerAutocomplete";
import AutoCompleteField from "../../../../common/autocomplete/autocompleteField";

const CustomerAccountVerticalSearchForm = forwardRef(
  ({ handleSearch, query, t, classificationsLoading }, ref) => {
    const classifications = useSelector(state => state.layout.classifications);

    const [dropdowns, setDropdowns] = useState({ entry_source_types: [] });
    useEffect(() => {
      if (!classificationsLoading) {
        const entrySourceTypes = [
          { name: "", value: "" },
          ...classifications.entry_source_types.map(item => {
            return Object.assign(
              {},
              {
                name: t(
                  `classifications.ENTRY_SOURCE_TYPE.${item.name.split(".")[1]}`
                ),
                value: item.value
              }
            );
          })
        ];
        setDropdowns(
          Object.assign({}, { entry_source_types: entrySourceTypes })
        );
      }
    }, [classificationsLoading, classifications, t]);

    return (
      <Formik
        ref={ref}
        enableReinitialize={true}
        initialValues={{
          count: query.count || 1000,
          client: query.client || "",
          period_start: query.period_start || "",
          period_end: query.period_end || "",
          transaction_entry: query.transactionEntry || "",
          has_entry_balance: query.hasEntryBalance ? query.hasEntryBalance : "",
          money_recieved: query.isMoneyRecieved ? query.isMoneyRecieved : ""
        }}
        onSubmit={(values, { setSubmitting }) => {
          handleSearch(values);
        }}
      >
        {({
          values,
          errors,
          status,
          touched,
          handleBlur,
          handleChange,
          handleSearch,
          handleSubmit,
          isSubmitting,
          setFieldValue
        }) => (
          <form onSubmit={handleSubmit}>
            <AutoCompleteField
              type="text"
              classes="form__group-stack"
              id="customerAccountSearchCustomer"
              name="client"
              value={values.client}
              handleChange={setFieldValue}
              label={t("customer.customerView.FilterCustomerName")}
              autocomplete="password"
              data-lpignore="true"
              fetchAutcompleteData={loadCompanyAutocompleteData}
            />

            <DateInputField
              type="date"
              classes="form__group-stack"
              id="customerAccountSearchPeriodStart"
              name="period_start"
              value={values.period_start}
              handleChange={setFieldValue}
              label={t("customerAccount.PeriodStart")}
            />
            <DateInputField
              type="date"
              classes="form__group-stack"
              id="customerAccountSearchPeriodEnd"
              name="period_end"
              value={values.period_end}
              handleChange={setFieldValue}
              label={t("customerAccount.PeriodEnd")}
            />
            <SelectInputField
              type="text"
              classes="form__group-stack"
              options={
                dropdowns.entry_source_types.length
                  ? dropdowns.entry_source_types
                  : []
              }
              id="customerAccountSearchTransactionEntry"
              name="transaction_entry"
              value={values.transaction_entry}
              onChange={handleChange}
              label={t("customerAccount.TransactionEntry")}
            />

            <SelectInputField
              type="text"
              options={[
                { name: "", value: "" },
                { name: t("common.ReplyYes"), value: true },
                { name: t("common.ReplyNo"), value: false }
              ]}
              classes="form__group-stack"
              id="customerAccountSearchHasEntryBalance"
              name="has_entry_balance"
              value={values.has_entry_balance}
              onChange={handleChange}
              label={t("customerAccount.FilterHasEntryBalance")}
            />
            <BoolSelectInputField
              type="text"
              classes="form__group-stack"
              id="customerAccountSearchIsMoneyRecieved"
              name="money_recieved"
              value={values.money_recieved}
              onChange={handleChange}
              label={t("customerAccount.IsMoneyReceived")}
            />
            <InputField
              type="number"
              classes="form__group-stack"
              id="count"
              value={values.count}
              onChange={handleChange}
              label={t("customerAccount.ResultsNumber")}
            />
          </form>
        )}
      </Formik>
    );
  }
);

export default CustomerAccountVerticalSearchForm;
