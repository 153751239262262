import React, { useRef } from "react";
import { SearchSection } from "../../../common/section";
import ContractVerticalSearchForm from "../../search/forms/contractVerticalSearchForm";
import useContractActions from "../../hooks/useContractActions";
import { useTranslation } from "react-i18next";
import useContractState from "../../hooks/useContractState";
import useLayoutActionDispatchers from "../../../common/redux/actions/layout/hooks/useLayoutActionDispatchers";
import { CONTRACT } from "../../../common/redux/types/viewTypes";
import useDataState from "../../../common/redux/hooks/useDataState";
import { contract } from "../../../common/redux/types/selectorTypes";
import useLayoutState from "../../../common/redux/hooks/useLayoutState";

const ContractSearchSection = () => {
  const { t } = useTranslation();
  const { searchOpen } = useLayoutState(contract);
  const { handleSearch } = useContractActions();
  const { isLoading } = useDataState(contract);
  const { handleSearchToggle } = useLayoutActionDispatchers(CONTRACT);
  const { searchQuery, resellers, serviceProviders } = useContractState();
  const formRef = useRef();
  return (
    <SearchSection
      isOpen={searchOpen}
      formRef={formRef}
      onSearchToggle={handleSearchToggle}
      dataLoading={isLoading}
    >
      <ContractVerticalSearchForm
        ref={formRef}
        t={t}
        handleSearch={handleSearch}
        query={searchQuery}
        resellers={resellers}
        serviceProviders={serviceProviders}
      />
    </SearchSection>
  );
};

export default ContractSearchSection;
