import React, { useRef } from "react";
import { SearchSection } from "../../../../common/section";
import { useTranslation } from "react-i18next";
import useLayoutState from "../../../../common/redux/hooks/useLayoutState";
import { customers } from "../../../../common/redux/types/selectorTypes";
import useSearchState from "../../../../common/redux/hooks/useSearchState";
import useLayoutActionDispatchers from "../../../../common/redux/actions/layout/hooks/useLayoutActionDispatchers";
import {
  CUSTOMERS,
  FACTORING_DEBTOR_OVERDUE_INVOICES
} from "../../../../common/redux/types/viewTypes";
import FactoringDebtorOverdueInvoicesSearchForm from "./factoringDebtorOverdueInvoicesSearchForm";
import { loadData } from "../../../../common/graphql/loadData";
import { fetchFactoringDebtorOverdueInvoicesData } from "../../graphql/factoringDebtorOverdueInvoicesQueries";
import { useDispatch } from "react-redux";

const FactoringDebtorOverdueInvoicesSearchSection = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { searchQuery } = useSearchState(customers);
  const { searchOpen } = useLayoutState(customers);
  const { handleSearchToggle } = useLayoutActionDispatchers(CUSTOMERS);
  const formRef = useRef();
  return (
    <SearchSection
      isOpen={searchOpen}
      formRef={formRef}
      onSearchToggle={handleSearchToggle}
    >
      <FactoringDebtorOverdueInvoicesSearchForm
        t={t}
        handleSearch={payload =>
          dispatch(
            loadData(
              payload,
              FACTORING_DEBTOR_OVERDUE_INVOICES,
              "penaltyInvoices",
              fetchFactoringDebtorOverdueInvoicesData
            )
          )
        }
        query={searchQuery}
        ref={formRef}
      />
    </SearchSection>
  );
};

export default FactoringDebtorOverdueInvoicesSearchSection;
