import React, { useCallback, useMemo } from "react";
import Table from "../common/table";
import { TableResultData } from "../common/tableFooter";
import { useTranslation } from "react-i18next";
import useTableLayoutActionDispatchers from "../common/redux/actions/layout/table/hooks/useTableLayoutActionDispatchers";
import useTableState from "../common/redux/hooks/useTableState";
import { contract } from "../common/redux/types/selectorTypes";
import { CONTRACT } from "../common/redux/types/viewTypes";
import useContractState from "./hooks/useContractState";
import useTableActionDispatchers from "../common/redux/actions/table/hooks/useTableActionDispatchers";

const ContractTable = ({ tableRef }) => {
  const { t } = useTranslation();
  const { height, selectedRowsState } = useTableState(contract);
  const { handleRowSelect } = useTableActionDispatchers(CONTRACT);
  const { isLoading, contractData, selectedRow } = useContractState();
  const { handleTableHeightSet } = useTableLayoutActionDispatchers(CONTRACT);

  const getColumns = useCallback(() => {
    const ContractViewTrans = "contract.";
    const columns = [
      {
        id: "contractTypeCl",
        Header: t(ContractViewTrans + "ContractType"),
        accessor: d =>
          t(`classifications.CONTRACT_TYPE.${d.contractTypeCl.split(".")[1]}`)
      },
      {
        Header: t(ContractViewTrans + "ContractNo"),
        accessor: "contractNo"
      },
      {
        Header: t(ContractViewTrans + "ValidFrom"),
        accessor: "validFrom",
        width: 100
      },
      {
        id: "validThru",
        Header: t(ContractViewTrans + "ValidThru"),
        accessor: d => d.validThru || ""
      },
      {
        id: "customer",
        Header: t(ContractViewTrans + "CustomerName"),
        accessor: d => d.customer[0].name
      },
      {
        id: "currentStatusCl",
        Header: t(ContractViewTrans + "ContractStatus"),
        accessor: d =>
          t(
            `classifications.CONTRACT_STATUS.${d.currentStatusCl.split(".")[1]}`
          )
      },
      {
        id: "reseller",
        Header: t(ContractViewTrans + "ResellerName"),
        accessor: d =>
          d.reseller ? (d.reseller.length ? d.reseller[0].name : "") : ""
      },
      {
        id: "serviceProvider",
        Header: t(ContractViewTrans + "ServiceProviderName"),
        accessor: d => d.serviceProvider[0].name
      }
    ];

    return columns;
  }, [t]);

  const tableColumns = useMemo(() => getColumns(), [getColumns]);

  return (
    <React.Fragment>
      <Table
        columns={tableColumns}
        height={height}
        setTableHeight={handleTableHeightSet}
        selectedRowsState={selectedRowsState}
        setSelectedRowsState={() => {}}
        isLoading={isLoading}
        data={contractData}
        onRowSelect={handleRowSelect}
        selectedRow={selectedRow}
        onToggleRow={() => {}}
        tableRef={tableRef}
      />
      <TableResultData
        count={contractData.length}
        selectedRows={[]}
        onRowSelect={handleRowSelect}
        selectedRow={selectedRow}
        onRemoveItem={[]}
      />
    </React.Fragment>
  );
};

export default ContractTable;
