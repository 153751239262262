import { useSelector } from "react-redux";

const useServiceState = () => {
  const searchQuery = useSelector(state => state.service.search.query);
  const serviceData = useSelector(state => state.service.data.items);
  const selectedRow = useSelector(state => state.service.data.selectedRow);
  const serviceTypeNames =
    useSelector(state => state.service.search.dropdowns.serviceTypes) || [];
  const isLoading = useSelector(state => state.service.data.isLoading);
  const countriesDropdownData = useSelector(
    state => state.layout.classifications.countries
  );
  return {
    searchQuery,
    serviceData,
    selectedRow,
    isLoading,
    serviceTypeNames,
    countriesDropdownData
  };
};

export default useServiceState;
