import { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { SET_SERVICE_TABLE_HEIGHT } from "../graphql/serviceDataQueries";

const useServiceUtils = () => {
  const dispatch = useDispatch();

  const onSetTableHeight = useMemo(
    () => payload => {
      dispatch(SET_SERVICE_TABLE_HEIGHT(payload));
    },
    [dispatch]
  );

  const getServiceFilters = useCallback(payload => {
    return payload;
  }, []);

  return {
    onSetTableHeight,
    getServiceFilters
  };
};

export default useServiceUtils;
