import React from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import Table from "../../../common/table";
import useTabTableActionDispatchers from "../../../common/redux/actions/table/useTabTableActionDispatchers";
import {
  CREDIT_LIMIT_HISTORY,
  FACTORING_DEBTOR
} from "../../../common/redux/types/viewTypes";
import useTabState from "../../../common/redux/hooks/useTabState";
import useDataState from "../../../common/redux/hooks/useDataState";
import {
  creditLimitHistory,
  factoringDebtor
} from "../../../common/redux/types/selectorTypes";

const FactoringDebtorCreditLimitHistoryTable = () => {
  const { t } = useTranslation();

  const { handleRowSelect } = useTabTableActionDispatchers(
    FACTORING_DEBTOR,
    CREDIT_LIMIT_HISTORY
  );

  const { selectedItem } = useTabState(factoringDebtor, creditLimitHistory);
  const { selectedItem: data } = useDataState(factoringDebtor);

  const getColumns = () => {
    const columns = [
      {
        Header: t("factoring.debtor.CreditLimit"),
        accessor: "creditLimit"
      },
      {
        Header: t("customerAccount.EntryDate"),
        accessor: "createdOn"
      }
    ];
    return columns;
  };

  console.log("DATA", data, "CREIDT");

  return (
    <Table
      data={!_.isEmpty(data) ? data.customerCreditLimitLog : []}
      onRowSelect={handleRowSelect}
      columns={getColumns()}
      selectedRow={selectedItem}
      height={200}
    />
  );
};
export default FactoringDebtorCreditLimitHistoryTable;
