import React, { useRef } from "react";
import {
  SectionBody,
  SectionHeader,
  SectionMain
} from "../../../common/section";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import useContractState from "../../hooks/useContractState";
import useContractConsts from "../../hooks/useContractConsts";
import useContractActions from "../../hooks/useContractActions";
import ContractTable from "../../contractTable";
import ContractButtons from "./contractButtons";
import useLayoutState from "../../../common/redux/hooks/useLayoutState";
import { contract } from "../../../common/redux/types/selectorTypes";
import useLayoutActionDispatchers from "../../../common/redux/actions/layout/hooks/useLayoutActionDispatchers";
import { CONTRACT } from "../../../common/redux/types/viewTypes";

const ContractMainSection = () => {
  const { t } = useTranslation();
  const tableRef = useRef();
  const { searchOpen, tableVisible, expandersVisible } = useLayoutState(
    contract
  );
  const {
    handleTableToggle,
    handleExpandersToggle,
    handleSearchToggle
  } = useLayoutActionDispatchers(CONTRACT);
  const {
    handleSearch,
    handleRowSelect,
    handleRowToggle
  } = useContractActions();
  const { searchQuery, selectedRow } = useContractState();
  const { filterLabels, tabs } = useContractConsts();

  return (
    <SectionMain toggleSearch={handleSearchToggle}>
      <SectionHeader
        sectionHeading={t("contract.Contract")}
        getFilters={payload => {
          return payload;
        }}
        searchQuery={searchQuery}
        filterLabels={filterLabels}
        tableVisible={tableVisible}
        expandersVisible={expandersVisible}
        tableEnabled={true}
        expandersEnabled={true}
        tabsEnabled={false}
        onToggleTable={handleTableToggle}
        onToggleExpanders={handleExpandersToggle}
        onToggleSearch={handleSearchToggle}
        onSearch={handleSearch}
        searchOpen={searchOpen}
      />

      <SectionBody
        TableComponent={ContractTable}
        ButtonsComponent={ContractButtons}
        shortcutsEnabled={false}
        selectedRow={selectedRow}
        tableVisible={tableVisible}
        onRowSelect={handleRowSelect}
        onToggleRow={handleRowToggle}
        selectedItem={
          !_.isEmpty(selectedRow)
            ? selectedRow.customer[0].name + " - " + selectedRow.contractNo
            : ""
        }
        tableRef={tableRef}
        expandersEnabled={expandersVisible}
        tabs={tabs}
      />
    </SectionMain>
  );
};

export default ContractMainSection;
