import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from "react";
import { InputFeedback, Label } from "../form/form";

const AutoCompleteField = ({
  type,
  id,
  label,
  name,
  classes,
  error,
  value,
  handleChange,
  className,
  autocomplete,
  actionType,
  selectorType,
  fetchAutcompleteData,
  style,
  ...props
}) => {
  const dropdownRef = useRef();
  const inputRef = useRef();
  const selectedItem = useRef(0);
  const [data, setData] = useState([]);
  const [inputValue, setInputValue] = useState("");

  const handleValueSelect = useCallback(
    value => {
      console.log("SELECT");

      setInputValue(value);
      handleChange(name, value);
      handleDataClear();
    },
    [handleChange, name]
  );

  useEffect(() => {
    handleValueSelect(value);
  }, [handleValueSelect, value]);

  const handleDataClear = () => {
    setData([]);
  };

  const loadAutocompleteData = async input => {
    const query = input.target.value;
    if (query.length > 2) {
      handleChange(name, query);
      const queryData = await fetchAutcompleteData(query);
      console.log(queryData);
      setData([...queryData]);
    } else {
      handleChange(name, query);
      handleDataClear();
    }
  };

  useLayoutEffect(() => {
    const parentEl = dropdownRef.current;
    const inputEl = document.querySelector(`#${id}`);
    const childElements = parentEl.getElementsByTagName("li");

    inputEl.parentElement.addEventListener("keydown", e => {
      if (e.keyCode === 38 && childElements.length) {
        e.preventDefault();
        if (selectedItem.current - 1 > 0) {
          selectedItem.current = selectedItem.current - 1;
          childElements[selectedItem.current - 1].focus();
        } else if (selectedItem.current === 1) {
          inputEl.focus();
          selectedItem.current -= 1;
        }
      } else if (e.keyCode === 40 && childElements.length) {
        e.preventDefault();
        if (childElements.length >= selectedItem.current + 1) {
          childElements[selectedItem.current].focus();
          selectedItem.current += 1;
        } else if (selectedItem.current === 0 && childElements.length === 1) {
          childElements[selectedItem.current].focus();
        }
      } else if (e.keyCode === 13 && childElements.length) {
        const name = childElements[selectedItem.current - 1].innerText;
        handleValueSelect(name);
        selectedItem.current = 0;
        inputEl.focus();
        e.preventDefault();
      } else {
        inputEl.focus();
      }
    });
  }, []);

  return (
    <div
      className={"autocomplete " + classes}
      style={{ ...style }}
      onBlur={() => handleDataClear()}
      onFocus={e => loadAutocompleteData(e)}
    >
      <Label htmlFor={id} error={error}>
        {label}
      </Label>
      <input
        id={id}
        type={type}
        name={name}
        value={inputValue}
        onChange={e => {
          console.log(e);
          setInputValue(e.target.value);
          loadAutocompleteData(e);
        }}
        data-lpignore="true"
        ref={inputRef}
        {...props}
        autoComplete="no"
      />
      <InputFeedback error={error} />
      <ul className="autocomplete__dropdown" ref={dropdownRef}>
        {data.length
          ? data.map((item, i) => (
              <li
                className="autocomplete__dropdown-item"
                key={i}
                tabIndex={i}
                onMouseDown={() => {
                  handleValueSelect(item.name);
                }}
              >
                {item.name}
              </li>
            ))
          : []}
      </ul>
    </div>
  );
};

export default AutoCompleteField;
