import React from "react";
import { Button } from "../../../common/buttons/button";
import { useTranslation } from "react-i18next";

const ContractButtons = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Button
        btnStyle="primary"
        size="small"
        classes="btn--footer"
        label={t("contract.BtnAddContract")}
        disabled={true}
      />
      <Button
        btnStyle="danger"
        size="small"
        disabled={true}
        classes="btn--footer"
        label={t("contract.BtnDeleteContract")}
      />
      <Button
        btnStyle="green"
        size="small"
        disabled={true}
        classes="btn--footer"
        label={t("contract.BtnSaveContract")}
      />
      <Button
        btnStyle="lime"
        size="small"
        disabled={true}
        classes="btn--footer"
        label={t("contract.BtnContractsToCsv")}
      />
      <Button
        btnStyle="lime"
        size="small"
        disabled={true}
        classes="btn--footer"
        label={t("contract.BtnExportContract")}
      />
    </React.Fragment>
  );
};

export default ContractButtons;
