import React, { useCallback, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import ContractViewDetailsForm from "../details/forms/contractViewDetailsForm";
import useContractState from "./useContractState";
import ContractViewStatusHistoryTable from "../status_history/contractViewStatusHistoryTable";
import ContractFactoringBankAccountTypesForm from "../factoring_bank_account_types/contractFactoringBankAccountTypesTable";
import ContractViewFactoringBankAccount from "../factoring_bank_account/contractViewFactoringBankAccount";
import FactoringDebtorsForm from "../factoring_debtors/factoringDebtorsForm";
import ContractViewShowProductCostTable from "../showProductCost/contractSectionShowProductCostTable";
import useDataState from "../../common/redux/hooks/useDataState";
import {
  bankAccountTypes,
  contract,
  details,
  factoringBankAccount,
  factoringDebtors,
  factoringInvoices,
  productCost,
  statusHistory
} from "../../common/redux/types/selectorTypes";
import {
  BANK_ACCOUNT_TYPES,
  CONTRACT,
  DETAILS,
  FACTORING_BANK_ACCOUNT,
  FACTORING_DEBTORS,
  FACTORING_INVOICES,
  PRODUCT_COST,
  STATUS_HISTORY
} from "../../common/redux/types/viewTypes";
import { fetchContractExpanders } from "../graphql/contractExpanderPermissionsQuery";
import { checkPermissionsContractTab } from "../../../services/permissions";
import ContractFactoringInvoicesTable from "../factoring_invoices/contractFactoringInvoicesTable";

const useContractConsts = () => {
  const { t } = useTranslation();
  const {
    resellers,
    contractTypes,
    contractStatuses,
    serviceProviders
  } = useContractState();

  const [tabPermissions, setTabPermissions] = useState([]);

  const { selectedItem } = useDataState(contract);

  // useEffect(() => {
  //     fetchContractExpanders("CONTRACT_TYPE.INVOICE_FACTORING").then(d => setTabPermissions(d));
  // }, []);

  useEffect(() => {
    if (!_.isEmpty(selectedItem)) {
      fetchContractExpanders(selectedItem.contractTypeCl).then(d =>
        setTabPermissions(d)
      );
    } else {
      fetchContractExpanders("CONTRACT_TYPE_INVOICE_FACTORING").then(d => {
        setTabPermissions(d);
      });
    }
  }, [selectedItem]);

  const filterLabels = {
    count: t("contract.FilterRowCount"),
    customer: t("contract.FilterContractCustomer"),
    customerNo: t("contract.FilterCustomerNo"),
    contract_type: t("contract.FilterContractType"),
    contractNo: t("contract.FilterContractNo"),
    contract_status: t("contract.FilterContractStatus"),
    contractExpiresFrom: t("contract.FilterContractExpiresFrom"),
    contractExpiresUntil: t("contract.FilterContractExpiresUntil"),
    contractReseller: t("contract.FilterContractReseller"),
    contractServiceProvicer: t("contract.FilterContractServiceProvicer"),
    customer_country: t("contract.FilterCustomerCountry"),
    salesagent: t("contract.FilterSalesAgent"),
    is_active: t("contract.FilterIsActive")
  };

  const getTabs = useCallback(() => {
    const tabItems = [
      {
        label: t("contract.ContractDetailData"),
        component: (
          <ContractViewDetailsForm
            t={t}
            data={selectedItem}
            resellers={resellers}
            contractTypes={contractTypes}
            contractStatus={contractStatuses}
            serviceProviders={serviceProviders}
          />
        ),
        viewSelector: contract,
        tabSelector: details,
        viewType: CONTRACT,
        tabType: DETAILS,
        permission: "exempt"
      },
      {
        label: t("contract.ContractStatusHistory"),
        component: (
          <ContractViewStatusHistoryTable
            t={t}
            data={selectedItem.contractStatusHistory || []}
          />
        ),
        viewSelector: contract,
        tabSelector: statusHistory,
        viewType: CONTRACT,
        tabType: STATUS_HISTORY,
        permission: "Contract.StatusHistory"
      },
      {
        label: t("contract.ContractShowProductCost"),
        component: (
          <ContractViewShowProductCostTable
            data={selectedItem.productCostOverview}
          />
        ),
        viewSelector: contract,
        tabSelector: productCost,
        viewType: CONTRACT,
        tabType: PRODUCT_COST,
        permission: "Contract.ShowProductCost"
      },
      {
        label: t("contract.ContractVatBankAccountData"),
        component: <ContractFactoringBankAccountTypesForm t={t} />,
        viewSelector: contract,
        tabSelector: bankAccountTypes,
        viewType: CONTRACT,
        tabType: BANK_ACCOUNT_TYPES,
        permission: "Contract.VatBankAccount"
      },
      {
        label: t("contract.ContractFactoringBankAccount"),
        component: <ContractViewFactoringBankAccount />,
        viewSelector: contract,
        tabSelector: factoringBankAccount,
        viewType: CONTRACT,
        tabType: FACTORING_BANK_ACCOUNT,
        permission: "Contract.FactoringBankAccountt"
      },
      {
        label: t("contract.ContractFactoringDebtor"),
        component: <FactoringDebtorsForm />,
        viewSelector: contract,
        tabSelector: factoringDebtors,
        viewType: CONTRACT,
        tabType: FACTORING_DEBTORS,
        permission: "Contract.FactoringDebtors"
      }
      // {
      //   label: t("contract.FactoringInvoicesHeader"),
      //   component: <ContractFactoringInvoicesTable />,
      //   viewSelector: contract,
      //   tabSelector: factoringInvoices,
      //   viewType: CONTRACT,
      //   tabType: FACTORING_INVOICES,
      //   permission: "exempt"
      // }
    ];
    return checkPermissionsContractTab(tabItems, tabPermissions);
  }, [
    tabPermissions,
    contractStatuses,
    contractTypes,
    resellers,
    selectedItem,
    serviceProviders,
    t
  ]);
  const tabs = useMemo(() => getTabs(), [tabPermissions]);
  return { filterLabels, tabs };
};

export default useContractConsts;
