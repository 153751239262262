import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import ServiceSectionDetailsForm from "../details/serviceSectionDetailsForm";
import useDataState from "../../common/redux/hooks/useDataState";
import { details, service } from "../../common/redux/types/selectorTypes";
import { DETAILS, SERVICE } from "../../common/redux/types/viewTypes";

const useServiceConsts = () => {
  const { t } = useTranslation();
  const { selectedItem } = useDataState(service);
  const filterLabels = {
    customer: t("service.CustomerName"),
    add_to_invoice: t("service.FilterAddToInvoice"),
    service_type: t("service.FilterAddToInvoice"),
    invoice_no: t("service.FilterInvoiceNo"),
    services_from: t("service.FilterServicesFrom"),
    services_to: t("service.FilterServicesTo"),
    is_private: t("service.FilterIsPrivate"),
    created_from: t("service.FilterCreatedFrom"),
    country: t("service.FilterCountry"),
    partner: t("service.FilterPartner"),
    without_customer_invoice: t("service.FilterWithoutCustomerInvoice"),
    customer_country: t("service.FilterCustomerCountry"),
    sales_agent: t("service.SalesAgent"),
    count: t("service.FilterRowCount")
  };

  const tabs = useMemo(
    () => [
      {
        label: t("service.ServiceDetailData"),
        component: (
          <ServiceSectionDetailsForm t={t} data={selectedItem || {}} />
        ),
        viewSelector: service,
        tabSelector: details,
        viewType: SERVICE,
        tabType: DETAILS
      }
    ],
    [selectedItem, t]
  );

  return { filterLabels, tabs };
};

export default useServiceConsts;
