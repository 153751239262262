import React, { useRef } from "react";

import _ from "lodash";
import { useTranslation } from "react-i18next";
import {
  SectionBody,
  SectionHeader,
  SectionMain
} from "../../../../common/section";
import useFactoringDebtorActions from "../../../debtor/hooks/useFactoringDebtorActions";
import useLayoutActionDispatchers from "../../../../common/redux/actions/layout/hooks/useLayoutActionDispatchers";
import { FACTORING_DEBTOR_OVERDUE_INVOICES } from "../../../../common/redux/types/viewTypes";
import useFactoringDebtorOverdueInvoicesConsts from "../../hooks/useFactoringDebtorOverdueInvoicesConsts";
import useLayoutState from "../../../../common/redux/hooks/useLayoutState";
import { factoringDebtorOverdueInvoices } from "../../../../common/redux/types/selectorTypes";
import FactoringDebtorOverdueInvoicesTable from "../../factoringDebtorOverdueInvoicesTable";
import FactoringDebtorOverdueInvoicesButtons from "./factoringDebtorOverdueInvoicesButtons";
import useSearchState from "../../../../common/redux/hooks/useSearchState";
import useDataState from "../../../../common/redux/hooks/useDataState";

const FactoringDebtorOverdueInvoicesMainSection = () => {
  const { t } = useTranslation();
  const tableRef = useRef();
  const {
    handleSearchToggle,
    handleExpandersToggle,
    handleTableToggle
  } = useLayoutActionDispatchers(FACTORING_DEBTOR_OVERDUE_INVOICES);
  const { filterLabels, tabs } = useFactoringDebtorOverdueInvoicesConsts();
  const { tableVisible, expandersVisible, searchOpen } = useLayoutState(
    factoringDebtorOverdueInvoices
  );
  const { handleSearch, handleRowSelect } = useFactoringDebtorActions();
  const { searchQuery } = useSearchState(factoringDebtorOverdueInvoices);
  const { selectedItem } = useDataState(factoringDebtorOverdueInvoices);

  return (
    <SectionMain toggleSearch={handleSearchToggle}>
      <SectionHeader
        sectionHeading={t("factoring.debtor.overdueInvoices.Header")}
        getFilters={() => {}}
        searchQuery={searchQuery}
        filterLabels={filterLabels}
        tableVisible={tableVisible}
        expandersVisible={expandersVisible}
        tableEnabled={true}
        expandersEnabled={true}
        searchOpen={searchOpen}
        tabsEnabled={false}
        onToggleTable={handleTableToggle}
        onToggleExpanders={handleExpandersToggle}
        onToggleSearch={handleSearchToggle}
        onSearch={handleSearch}
      />

      <SectionBody
        TableComponent={FactoringDebtorOverdueInvoicesTable}
        ButtonsComponent={FactoringDebtorOverdueInvoicesButtons}
        shortcutsEnabled={false}
        tableVisible={tableVisible}
        onRowSelect={handleRowSelect}
        selectedItem={!_.isEmpty(selectedItem) ? selectedItem.customerName : ""}
        tableRef={tableRef}
        expandersEnabled={expandersVisible}
        tabs={tabs}
      />
    </SectionMain>
  );
};

export default FactoringDebtorOverdueInvoicesMainSection;
