import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { Formik } from "formik";
import {
  BoolSelectInputField,
  DateInputField,
  FormBody,
  FormCol,
  FormGroupMultiCol,
  FormGroupSmall,
  InputField,
  TextareaInputField
} from "../../../../common/form/form";
import { numberWithSpaces } from "../../../../../services/numberFunctions";
import useFormsState from "../../../../common/redux/hooks/useFormsState";
import { customers } from "../../../../common/redux/types/selectorTypes";
import useFormsActionDispatchers from "../../../../common/redux/actions/forms/hooks/useFormsActionDispatchers";
import { CUSTOMERS } from "../../../../common/redux/types/viewTypes";
import { getFormikChangeHack } from "../../../../../services/formService";

const CustomerDetailsForm = ({ data }) => {
  const { t } = useTranslation();
  const CustomerDetails = "customer.customerDetails.";
  const { formsState, dataChanged } = useFormsState(customers);
  const {
    handleFormsChange,
    handleFormsStateClear
  } = useFormsActionDispatchers(CUSTOMERS);
  const [formState, setFormState] = useState({});

  // useEffect(() => {
  //   return () => {
  //     handleFormsStateClear();
  //   };
  // }, []);

  useEffect(() => {
    handleFormsStateClear();
  }, [data]);

  useEffect(() => {
    // handleFormsStateClear();
    if (!_.isEmpty(formsState)) {
      console.log("eff");
      setFormState(Object.assign(formState, data));
      handleFormsChange(Object.assign(formsState, data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Formik
      validate={values => getFormikChangeHack(handleFormsChange, values)}
      enableReinitialize={true}
      initialValues={{
        id: (!dataChanged ? data.id : formsState.id) || "",
        name: (!dataChanged ? data.name : formsState.name) || "",
        regNo: data.regNo || "",
        createdOn: data.createdOn || "",
        generalFax: data.generalFax || "",
        generalEmail: data.generalEmail || "",
        generalPhone: data.generalPhone || "",
        salesAgent: "",
        isActiveCl: data.isActiveCl || "",
        additionalInfo: data.additionalInfo || "",
        balance: data.balance || 0,
        debt: data.debt || 0,
        creditLimit: data.creditLimit || "",
        customerManager: data.customerManagerId || "",
        portOneContractsCount: data.portOneContractsCount || 0,
        otherContractsCount: data.otherContractsCount || 0
      }}
      onSubmit={(values, { setSubmitting, resetForm }) => {}}
    >
      {({
        values,
        errors,
        state,
        status,
        touched,
        handleBlur,
        handleChange,
        setFieldValue,
        handleSubmit,
        isSubmitting,
        resetForm
      }) => (
        <FormBody>
          <input type="hidden" value="something" />
          <div className="form--flex">
            <FormCol>
              <InputField
                label={t(CustomerDetails + "CustomerId")}
                type="text"
                classes="form__group"
                name="id"
                disabled={true}
                id="customerDetailsFormNo"
                value={values.id}
                onBlur={handleBlur}
                onChange={e => {
                  handleChange(e);
                  // handleInputChange(e, values, formsState, handleFormsChange);
                }}
              />

              <InputField
                label={t(CustomerDetails + "Name")}
                name="name"
                type="text"
                id="customerDetailsFormName"
                classes="form__group"
                value={values.name}
                onChange={e => {
                  handleChange(e);
                  // handleInputChange(e, values, formsState, handleFormsChange);
                }}
              />
              <InputField
                label={t(CustomerDetails + "RegNo")}
                type="text"
                name="regNo"
                id="customerDetailsFormRegNo"
                value={values.regNo}
                classes="form__group"
                onChange={e => {
                  handleChange(e);
                  // handleInputChange(e, values, formsState, handleFormsChange);
                }}
              />
              <DateInputField
                label={t(CustomerDetails + "ValidFrom")}
                type="text"
                placement="right"
                name="createdOn"
                id="customerDetailsFormCreatedOn"
                classes="form__group"
                value={values.createdOn}
                handleChange={e => {
                  setFieldValue(e);
                  // handleFormChange(e, values, formsState, handleFormsChange);
                }}
              />
              <InputField
                label={t(CustomerDetails + "CustomerManager")}
                type="text"
                classes="form__group"
                id="customerDetailsFormCustomerManager"
                value={values.customerManager}
                onChange={e => {
                  handleChange(e);
                  // handleInputChange(e, values, formsState, handleFormsChange);
                }}
              />
            </FormCol>

            <FormCol className="u-margin-left-medium">
              <InputField
                label={t(CustomerDetails + "GeneralFax")}
                type="text"
                classes="form__group"
                name="generalFax"
                id="customerDetailsFormGeneralFax"
                value={values.generalFax}
                onChange={e => {
                  handleChange(e);
                  // handleInputChange(e, values, formsState, handleFormsChange);
                }}
              />
              <InputField
                label={t(CustomerDetails + "GeneralPhone")}
                type="text"
                classes="form__group"
                name="generalPhone"
                value={values.generalPhone}
                id="customerDetailsFormGeneralPhone"
                onChange={e => {
                  handleChange(e);
                  // handleInputChange(e, values, formsState, handleFormsChange);
                }}
              />
              <InputField
                label={t(CustomerDetails + "GeneralEmail")}
                type="text"
                classes="form__group"
                name="generalEmail"
                id="customerDetailsFormGeneralEmail"
                value={values.generalEmail}
                onChange={e => {
                  handleChange(e);
                  // handleInputChange(e, values, formsState, handleFormsChange);
                }}
              />
              <InputField
                label={t(CustomerDetails + "SalesAgent")}
                type="text"
                classes="form__group"
                name="salesAgent"
                id="customerDetailsFormSalesAgent"
                value={values.salesAgent}
                onChange={e => {
                  handleChange(e);
                  // handleInputChange(e, values, formsState, handleFormsChange);
                }}
              />

              <FormGroupMultiCol>
                <BoolSelectInputField
                  isCl={true}
                  label={t(CustomerDetails + "Active")}
                  id="customerDetailsFormIsActive"
                  name="isActiveCl"
                  value={values.isActiveCl}
                  classes="form__group"
                  onChange={e => {
                    handleChange(e);
                    // handleInputChange(e, values, formsState, handleFormsChange);
                  }}
                />
                <FormGroupSmall>
                  <label>{t(CustomerDetails + "CollectingAgent")}</label>
                  <input type="text" name="insurance" />
                </FormGroupSmall>
                <FormGroupSmall>
                  <InputField
                    label={t(CustomerDetails + "CreditLimit")}
                    type="text"
                    classes="form__group"
                    name="creditLimit"
                    id="customerDetailsCreditLimit"
                    value={values.creditLimit}
                    onChange={e => {
                      handleChange(e);
                      // handleInputChange(e, values, formsState, handleFormsChange);
                    }}
                  />
                </FormGroupSmall>
              </FormGroupMultiCol>
            </FormCol>
            <FormCol className="u-margin-left-medium customer__misc">
              <div className="form__group">
                <label>{t(CustomerDetails + "Balance")}</label>
                <p>
                  {values.balance
                    ? numberWithSpaces(
                        values.balance.toFixed(2).replace(/[.,]00$/, "")
                      )
                    : 0}
                </p>
              </div>
              <div className="form__group">
                <label>{t(CustomerDetails + "Debt")}</label>
                <p>
                  {values.debt ? numberWithSpaces(values.debt.toFixed(2)) : 0}
                </p>
              </div>
              <div className="form__group">
                <label>{t(CustomerDetails + "NumberOfP1Contracts")}</label>
                <p>
                  {values.portOneContractsCount
                    ? values.portOneContractsCount
                    : 0}
                </p>
              </div>
              <div className="form__group">
                <label>{t(CustomerDetails + "NumberOfOtherContracts")}</label>
                <p>
                  {values.otherContractsCount ? values.otherContractsCount : 0}
                </p>
              </div>
              <div className="form__group">
                <label>{t(CustomerDetails + "EMTARisk")}</label>
                <p>0</p>
              </div>
            </FormCol>
            <div className="form-col customer__misc">
              <div className="form__group">
                <label>{t(CustomerDetails + "PaymentAccuracy")}</label>
                <p>0</p>
              </div>
              <div className="form__group">
                <label>{t(CustomerDetails + "DepositBalance")}</label>
                <p>0</p>
              </div>
              <div className="form__group">
                <label>{t(CustomerDetails + "OffEntry")}</label>
                <p>0</p>
              </div>
              <div className="form__group">
                <label>{t(CustomerDetails + "NrGuarantors")}</label>
                <p>0</p>
              </div>
              <div className="form__group">
                <label>{t(CustomerDetails + "GuaranteeSum")}</label>
                <p>0</p>
              </div>
            </div>
          </div>

          <TextareaInputField
            type="text"
            id="customersDetailsFormAdditionalInfo"
            name="additionalInfo"
            label={t(CustomerDetails + "AdditionalInfo")}
            value={values.additionalInfo}
            onChange={handleChange}
            classes="form__block form__group form__block--multiline"
            textareaClasses="u-border-radius-3px"
          />
        </FormBody>
      )}
    </Formik>
  );
};

export default CustomerDetailsForm;
