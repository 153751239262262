import React, { useRef } from "react";
import {
  SectionBody,
  SectionHeader,
  SectionMain
} from "../../../common/section";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import useDataState from "../../../common/redux/hooks/useDataState";
import { clearing } from "../../../common/redux/types/selectorTypes";
import useSearchState from "../../../common/redux/hooks/useSearchState";
import useLayoutState from "../../../common/redux/hooks/useLayoutState";
import useLayoutActionDispatchers from "../../../common/redux/actions/layout/hooks/useLayoutActionDispatchers";
import { CLEARING } from "../../../common/redux/types/viewTypes";
import ClearingTable from "../../clearingTable";
import ClearingButtons from "./clearingButtons";
import useClearingConsts from "../../hooks/useClearingConsts";

const ClearingMainSection = () => {
  const tableRef = useRef();
  const { t } = useTranslation();
  const {
    handleTableToggle,
    handleSearchToggle,
    handleExpandersToggle
  } = useLayoutActionDispatchers(CLEARING);
  const { selectedItem } = useDataState(clearing);
  const { searchQuery } = useSearchState(clearing);
  const { filterLabels, tabs } = useClearingConsts();
  const { tableVisible, expandersVisible, searchOpen } = useLayoutState(
    clearing
  );

  return (
    <SectionMain toggleSearch={handleSearchToggle}>
      <SectionHeader
        sectionHeading={t("clearing.Header")}
        getFilters={payload => payload}
        searchQuery={searchQuery}
        filterLabels={filterLabels}
        tableVisible={tableVisible}
        expandersVisible={expandersVisible}
        tableEnabled={true}
        expandersEnabled={true}
        tabsEnabled={false}
        onToggleTable={handleTableToggle}
        onToggleExpanders={handleExpandersToggle}
        onToggleSearch={handleSearchToggle}
        onSearch={() => {}}
        searchOpen={searchOpen}
      />

      <SectionBody
        TableComponent={ClearingTable}
        ButtonsComponent={ClearingButtons}
        shortcutsEnabled={false}
        selectedItem={
          !_.isEmpty(selectedItem) ? selectedItem.customer[0].name : ""
        }
        tableVisible={tableVisible}
        onRowSelect={() => {}}
        tableRef={tableRef}
        expandersEnabled={expandersVisible}
        tabs={tabs}
      />
    </SectionMain>
  );
};

export default ClearingMainSection;
