import React from "react";
import { Button } from "../../../common/buttons/button";
import { useTranslation } from "react-i18next";
import { ToggleContent } from "../../../common/modal/ToggleContent";
import ClearingAddModal from "../modals/clearingAdd/clearingAddModal";

const ClearingButtons = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <ToggleContent
        toggle={show => (
          <Button
            btnStyle="lime"
            label={t("clearing.BtnAdd")}
            handleClick={show}
            classes={"btn--footer"}
            disabled={false}
            size="medium"
          />
        )}
        content={hide => <ClearingAddModal hide={hide} />}
      />
      <Button
        btnStyle="primary"
        disabled={true}
        classes={"btn--footer"}
        label={t("clearing.BtnDelete")}
        size="medium"
      />
    </React.Fragment>
  );
};

export default ClearingButtons;
