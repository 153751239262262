import React, { useCallback, useRef } from "react";
import Table from "../../common/table";
import { useTranslation } from "react-i18next";
import useTableState from "../../common/redux/hooks/useTableState";
import { brokerClaims } from "../../common/redux/types/selectorTypes";
import useDataState from "../../common/redux/hooks/useDataState";
import useTableLayoutActionDispatchers from "../../common/redux/actions/layout/table/hooks/useTableLayoutActionDispatchers";
import { BROKER_CLAIMS } from "../../common/redux/types/viewTypes";
import useTableActionDispatchers from "../../common/redux/actions/table/hooks/useTableActionDispatchers";
import useDataActionsDispatchers from "../../common/redux/actions/data/hooks/useDataActionsDispatchers";
import { Loading } from "../../common/loading";
import { TableResultData } from "../../common/tableFooter";

const BrokerClaimsTable = () => {
  const { t } = useTranslation();
  const { height } = useTableState(brokerClaims);
  const { items, selectedItem, selectedRows, isLoading } = useDataState(
    brokerClaims
  );
  const tableRef = useRef();
  const { handleTableHeightSet } = useTableLayoutActionDispatchers(
    BROKER_CLAIMS
  );
  const { handleRowSelect } = useTableActionDispatchers(BROKER_CLAIMS);
  const { selectedRowsState } = useTableState(brokerClaims);
  const onSetSelectedRowsState = useCallback(payload => {}, []);

  const { handleSetSelectedRowsState } = useDataActionsDispatchers(
    BROKER_CLAIMS
  );

  const BrokerInvoiceTableTrans = "broker.claims.";
  const columns = [
    {
      id: "addToInvoice",
      Header: t(BrokerInvoiceTableTrans + "AddToInvoice"),
      accessor: d =>
        d.addToInvoice ? t("common.ReplyYes") : t("common.ReplyNo")
    },

    {
      id: "customer",
      Header: t(BrokerInvoiceTableTrans + "Customer"),
      accessor: d => (d.customer ? d.customer[0].name : "")
    },
    {
      Header: t(BrokerInvoiceTableTrans + "BrokerClaimDate"),
      accessor: "claimDate"
    },
    {
      id: "brokerClaimTypeCl",
      Header: t(BrokerInvoiceTableTrans + "BrokerClaimType"),
      accessor: d =>
        d.brokerClaimTypeCl ? t(`classifications.${d.brokerClaimTypeCl}`) : ""
    },
    {
      Header: t(BrokerInvoiceTableTrans + "BrokerClaimNo"),
      accessor: "claimNo"
    },

    {
      Header: t(BrokerInvoiceTableTrans + "Sum"),
      accessor: "sum"
    },

    {
      Header: t(BrokerInvoiceTableTrans + "AdditionalInfo"),
      accessor: "additionalInfo"
    }
  ];

  return (
    <React.Fragment>
      <Table
        columns={columns}
        data={items}
        height={height}
        setTableHeight={handleTableHeightSet}
        setUserTableHeight={handleTableHeightSet}
        isLoading={isLoading}
        loadingComponent={Loading}
        onRowSelect={handleRowSelect}
        selectedRow={selectedItem}
        selectedRows={selectedRows}
        selectedRowsState={selectedRowsState}
        setSelectedRowsState={handleSetSelectedRowsState}
        tableRef={tableRef}
      />
      <TableResultData count={items.length} />
    </React.Fragment>
  );
};

export default BrokerClaimsTable;
