import React from "react";
import { useDispatch } from "react-redux";
import Table from "../../../common/table";
import { SET_CUSTOMERS_INVOICE_TABLE_HEIGHT } from "../actions/customersActions";
import useTabState from "../../../common/redux/hooks/useTabState";
import { customers } from "../../../common/redux/types/selectorTypes";
import { Button } from "../../../common/buttons/button";
import useTabTableActionDispatchers from "../../../common/redux/actions/table/useTabTableActionDispatchers";
import {
  CUSTOMERS,
  INVOICE_SETTINGS
} from "../../../common/redux/types/viewTypes";

const CustomerInvoiceSettingsTable = ({ t, selectedItem }) => {
  const dispatch = useDispatch();
  const { table } = useTabState(customers, "invoiceSettings");
  const { handleRowSelect } = useTabTableActionDispatchers(
    CUSTOMERS,
    INVOICE_SETTINGS
  );

  const onSetTableHeight = payload => {
    dispatch(SET_CUSTOMERS_INVOICE_TABLE_HEIGHT(payload));
  };
  const CustomerInvoiceSettingsTrans = "customer.customerInvoiceSettings.";

  const columns = [
    {
      Header: t(CustomerInvoiceSettingsTrans + "BankAccount"),
      accessor: "bankAccount"
    },
    {
      id: "isPrimary",
      Header: t(CustomerInvoiceSettingsTrans + "Primary"),
      accessor: d => (d.isPrimary ? t("common.ReplyYes") : t("common.ReplyNo"))
    }
  ];

  const sortColumn = "name";
  return (
    <div className="customer__comments__table">
      <Table
        columns={columns}
        height={table.height}
        setTableHeight={onSetTableHeight}
        data={selectedItem.customerBankAccounts || []}
        onRowSelect={handleRowSelect}
        sortColumn={sortColumn}
        selectedRow={{}}
      />
      <div
        className="invoice-settings-buttons"
        style={{
          padding: "0.5rem 0rem 0.8rem 0rem",
          display: "flex",
          justifyContent: "flex-end"
        }}
      >
        <Button
          label={t("buttons.MakePrimary")}
          btnStyle="lime"
          size="medium"
          classes="btn--footer u-margin-right-tiny"
        />
        <Button
          label={t("buttons.Add")}
          btnStyle="primary"
          size="medium"
          classes="btn--footer u-margin-right-tiny"
        />
        <Button
          label={t("buttons.Delete")}
          btnStyle="danger"
          size="medium"
          classes="btn--footer"
        />
      </div>
    </div>
  );
};

export default CustomerInvoiceSettingsTable;
