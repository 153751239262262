export const extractQueryParameters = payload => {
  let queryParameters = "";
  Object.keys(payload).forEach(key => {
    if (payload[key] !== "") {
      switch (typeof payload[key]) {
        case "string":
          if (
            (key === "is_active" ||
              key === "isActive" ||
              key === "is_valid" ||
              key === "money_recieved" ||
              key === "is_private" ||
              key === "has_entry_balance" ||
              key === "without_customer_manager" ||
              key === "is_it_paid",
            key === "has_cancellation",
            key === "is_summary_invoice",
            key === "is_in_contract_annex",
            key === "")
          ) {
            queryParameters += `${key}: ${payload[key]} `;
          } else {
            queryParameters += `${key}: "${payload[key]}" `;
          }

          break;

        case "number":
          queryParameters += `${key}: ${payload[key]} `;

          break;
        default: {
          queryParameters += `${key}: "${payload[key]}"`;
        }
      }
    }
  });

  return queryParameters;
};

export const extractQueryParametersAsIs = payload => {
  let queryParameters = "";
  Object.keys(payload).forEach(key => {
    if (payload[key] !== "") {
      queryParameters += `${key}: ${payload[key]} `;
    }
  });

  return queryParameters;
};
