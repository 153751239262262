import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../../../common/buttons/button";

const BankImportButtons = () => {
  const { t } = useTranslation();
  return (
    <div
      className="u-margin-bottom-tiny u-margin-top-tiny"
      style={{ display: "flex", width: "100%", justifyContent: "flex-end" }}
    >
      <Button
        btnStyle="lime"
        label={t("bankFileImport.Import")}
        classes={"btn--footer"}
        disabled={true}
        size="medium"
      />
    </div>
  );
};

export default BankImportButtons;
