import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";
import TransactionsSectionDetailsForm from "../details/forms/TransactionsSectionDetailsForm";
import {details, transactions} from "../../common/redux/types/selectorTypes";
import {DETAILS, TRANSACTIONS} from "../../common/redux/types/viewTypes";

const useTransactionsConsts = () => {
  const { t } = useTranslation();
  const filterLabels = {
    name: t("customer.customerView.FilterCustomerName"),
    id: t("customer.customerView.FilterCustomerId"),
    woCusManager: t("customer.customerView.WithoutCustomerManager"),
    regNo: t("customer.customerView.FilterRegNo"),
    vatNo: t("customer.customerView.VatNo"),
    contactPerson: t("customer.customerView.FilterContactPerson"),
    resultCount: t("customer.customerView.FilterResultCount"),
    generalPhone: t("customer.customerView.FilterGeneralPhone"),
    generalEmail: t("customer.customerView.FilterGeneralOrInvoiceEmail"),
    salesAgent: t("customer.customerView.SalesAgent"),
    customerManager: t("customer.customerView.CustomerManager"),
    withoutCustomerManager: t("customer.customerView.WithoutCustomerManager"),
    isActive: t("customer.customerView.FilterIsActive"),
    validFrom: t("customer.customerView.FilterCustomerValidFrom"),
    portOneContractsCount: t(
      "customer.customerView.SearchResultColumnNumberOfP1Contracts"
    ),
    otherContractsCount: t(
      "customer.customerView.SearchResultColumnNumberOfOtherContracts"
    )
  };

  const tabs = useMemo(() => {
    const data = [
      {
        label: t("transactions.TransactionDetailData"),
        component: <TransactionsSectionDetailsForm t={t} />,
        viewSelector: transactions,
        viewType: TRANSACTIONS,
        tabSelector: details,
        tabType: DETAILS
      }
    ];
    return data;
  }, [t]);

  return { tabs, filterLabels };
};

export default useTransactionsConsts;
