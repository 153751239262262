import { useEffect } from "react";
import { useDispatch } from "react-redux";

export const getFormChange = (e, values) => {
  return { ...values, [e.target.name]: e.target.value };
};

export const handleInputChange = (e, values, formsState, dispatcher) => {
  dispatcher(Object.assign(formsState, getFormChange(e, values)));
};

export const handleFormRefChange = (reference, e, values) => {
  reference = getFormChange(e, values);
};

export const useFormChange = (e, values) => {
  const dispatch = useDispatch();

  useEffect(
    (e, values) => {
      dispatch(handleInputChange(e, values));
    },
    [e, values, dispatch]
  );
};

export const getFormikChangeHack = (dispatcher, values) => {
  dispatcher(values);
};
