import React, { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import Table from "../common/table";
import { useTranslation } from "react-i18next";
import {
  SELECT_SERVICE_ROW,
  SET_SERVICE_TABLE_SELECTED_ROWS_STATE
} from "./graphql/serviceDataQueries";
import { TableResultData } from "../common/tableFooter";
import useServiceState from "./hooks/useServiceState";
import useServiceActions from "./hooks/useServiceActions";
import useDataState from "../common/redux/hooks/useDataState";
import { service } from "../common/redux/types/selectorTypes";
import useTableLayoutActionDispatchers from "../common/redux/actions/layout/table/hooks/useTableLayoutActionDispatchers";
import { SERVICE } from "../common/redux/types/viewTypes";
import useTableState from "../common/redux/hooks/useTableState";

const ServiceTable = ({ tableRef }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const ServiceTrans = "service.";
  const { items: data, selectedItem: selectedRow } = useDataState(service);
  const { isLoading } = useServiceState();
  const { height, selectedRowsState } = useTableState(service);
  const { handleRowSelect } = useServiceActions();
  const { handleTableHeightSet } = useTableLayoutActionDispatchers(SERVICE);
  const onSetSelectedRowsState = useCallback(
    payload => {
      dispatch(SET_SERVICE_TABLE_SELECTED_ROWS_STATE(payload));
    },
    [dispatch]
  );

  const onToggleRow = useCallback(
    payload => {
      dispatch(SELECT_SERVICE_ROW(payload));
    },
    [dispatch]
  );

  const getColumns = useCallback(() => {
    const columns = [
      {
        id: "addToInvoice",
        Header: t(ServiceTrans + "AddToInvoice"),
        accessor: d => {
          if (d.addToInvoice) {
            return t("common.ReplyYes");
          } else {
            return t("common.ReplyNo");
          }
        }
      },
      {
        id: "isPrivate",
        Header: t(ServiceTrans + "IsPrivate"),
        accessor: d => {
          if (d.serviceType[0].isPrivate) {
            return t("common.ReplyYes");
          } else {
            return t("common.ReplyNo");
          }
        }
      },
      {
        id: "customer",
        Header: t(ServiceTrans + "CustomerName"),
        accessor: d => d.customer[0].name
      },
      {
        Header: t(ServiceTrans + "ServiceDate"),
        accessor: "serviceDate"
      },
      {
        id: "serviceType",
        Header: t(ServiceTrans + "ServiceType"),
        accessor: d => d.serviceType[0].name
      },
      {
        Header: t(ServiceTrans + "UnitPrice"),
        accessor: "unitPrice"
      },
      {
        Header: t(ServiceTrans + "Quantity"),
        accessor: "quantity"
      },
      {
        Header: t(ServiceTrans + "Sum"),
        accessor: "total"
      },
      {
        Header: t(ServiceTrans + "AdditionalInfo"),
        accessor: "additionalInfo",
        minWidth: 200
      }
    ];

    return columns;
  }, [t]);

  const tableColumns = useMemo(() => getColumns(), [getColumns]);

  return (
    <React.Fragment>
      <Table
        columns={tableColumns}
        height={height}
        setTableHeight={handleTableHeightSet}
        isLoading={isLoading}
        onRowSelect={handleRowSelect}
        selectedRowsState={selectedRowsState}
        setSelectedRowsState={onSetSelectedRowsState}
        selectedRow={selectedRow}
        onToggleRow={onToggleRow}
        onToggleAllRows={onToggleRow}
        data={data || []}
        tableRef={tableRef}
      />
      <TableResultData
        count={data.length}
        selectedRows={[]}
        onRowSelect={handleRowSelect}
        selectedRow={selectedRow}
        onRemoveItem={[]}
      />
    </React.Fragment>
  );
};
export default ServiceTable;
