import { useMemo } from "react";
import { useDispatch } from "react-redux";

const useFactoringDebtorUtils = () => {
  const dispatch = useDispatch();

  const getFilters = payload => {
    return payload;
  };

  return {
    getFilters
  };
};

export default useFactoringDebtorUtils;
