import React, { useMemo } from "react";
import useTabState from "../../../../common/redux/hooks/useTabState";
import {
  customerInvoices,
  generateModal
} from "../../../../common/redux/types/selectorTypes";
import useTabTableActionDispatchers from "../../../../common/redux/actions/table/useTabTableActionDispatchers";
import {
  CUSTOMER_INVOICES,
  GENERATE_MODAL
} from "../../../../common/redux/types/viewTypes";
import { useTranslation } from "react-i18next";
import Table from "../../../../common/table";

const CustomerInvoiceGenerateInvoiceModalTable = ({ tableRef }) => {
  const { t } = useTranslation();
  const { table, selectedItem } = useTabState(customerInvoices, generateModal);
  const {
    handleTableHeightSet,
    handleRowSelect
  } = useTabTableActionDispatchers(CUSTOMER_INVOICES, GENERATE_MODAL);

  const CustomerInvoiceTableTrans = "customerInvoice.";
  const columns = useMemo(
    () => [
      {
        id: "company",
        Header: t(CustomerInvoiceTableTrans + "CustomerName"),
        accessor: d => d.company[0].name
      },
      {
        id: "country",
        Header: "Riik",
        accessor: d => d.country
      },
      {
        id: "discount",
        Header: "Soodustus?",
        accessor: d => d.discount
      },
      {
        id: "customerStatus",
        Header: "Kliendi staatus",
        accessor: d => d.customerStatus
      },
      {
        id: "debt",
        Header: "Võlg",
        accessor: d => d.debt
      },
      {
        id: "reseller",
        Header: "Edasimüüja",
        accessor: d => d.reseller
      }
    ],
    [t]
  );

  return (
    <React.Fragment>
      <Table
        columns={columns}
        height={300}
        setTableHeight={handleTableHeightSet}
        data={[]}
        isLoading={false}
        onRowSelect={handleRowSelect}
        selectedRowsState={[]}
        selectedRow={selectedItem}
        tableRef={tableRef}
      />
    </React.Fragment>
  );
};

export default CustomerInvoiceGenerateInvoiceModalTable;
