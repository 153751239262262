import React, { useRef } from "react";
import useCustomersActions from "../../hooks/useCustomersActions";
import {
  SectionBody,
  SectionHeader,
  SectionMain
} from "../../../../common/section";
import CustomerTable from "../../customerTable";
import useCustomersConsts from "../../hooks/useCustomersConsts";
import CustomerButtons from "../main/customerButtons";
import { getCustomerFilters } from "../../hooks/customersUtils";
import { useTranslation } from "react-i18next";
import useLayoutState from "../../../../common/redux/hooks/useLayoutState";
import { CUSTOMERS } from "../../../../common/redux/types/viewTypes";
import useLayoutActionDispatchers from "../../../../common/redux/actions/layout/hooks/useLayoutActionDispatchers";
import useTableActionDispatchers from "../../../../common/redux/actions/table/hooks/useTableActionDispatchers";
import useSearchState from "../../../../common/redux/hooks/useSearchState";
import { customers } from "../../../../common/redux/types/selectorTypes";
import useDataState from "../../../../common/redux/hooks/useDataState";

const CustomerMainSection = () => {
  const { t } = useTranslation();
  const tableRef = useRef();
  const { removeRowToggle } = useCustomersActions();
  const { handleRowSelect, handleRowToggle } = useTableActionDispatchers(
    CUSTOMERS
  );
  const {
    handleExpandersToggle,
    handleTableToggle,
    handleSearchToggle
  } = useLayoutActionDispatchers(CUSTOMERS);

  const { tableVisible, expandersVisible, searchOpen } = useLayoutState(
    customers
  );

  const { searchQuery } = useSearchState(customers);
  const { selectedItem } = useDataState(customers);
  const { onSetCustomersTabsState } = useCustomersActions();
  const {
    customersShortcuts,
    customersTabs,
    customersLabels
  } = useCustomersConsts();

  return (
    <SectionMain toggleSearch={handleSearchToggle}>
      <SectionHeader
        sectionHeading={t("customer.customerView.Header")}
        getFilters={getCustomerFilters}
        searchQuery={searchQuery}
        filterLabels={customersLabels}
        tableVisible={tableVisible}
        expandersVisible={expandersVisible}
        tableEnabled={true}
        expandersEnabled={true}
        tabsEnabled={false}
        onToggleTable={handleTableToggle}
        onToggleExpanders={handleExpandersToggle}
        onToggleTabs={onSetCustomersTabsState}
        onToggleSearch={handleSearchToggle}
        searchOpen={searchOpen}
      />

      <SectionBody
        TableComponent={CustomerTable}
        ButtonsComponent={CustomerButtons}
        shortcutsEnabled={true}
        selectedRow={selectedItem}
        tableVisible={tableVisible}
        onRowSelect={handleRowSelect}
        removeItem={removeRowToggle}
        onToggleRow={handleRowToggle}
        tableRef={tableRef}
        shortcuts={customersShortcuts}
        expandersEnabled={expandersVisible}
        tabs={customersTabs}
      />
    </SectionMain>
  );
};

export default CustomerMainSection;
