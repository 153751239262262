import React, { useRef } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { BANK_IMPORT } from "../../../../common/redux/types/viewTypes";
import { bankImport } from "../../../../common/redux/types/selectorTypes";
import useLayoutActionDispatchers from "../../../../common/redux/actions/layout/hooks/useLayoutActionDispatchers";
import useDataState from "../../../../common/redux/hooks/useDataState";
import useSearchState from "../../../../common/redux/hooks/useSearchState";
import useLayoutState from "../../../../common/redux/hooks/useLayoutState";
import useCardActions from "../../../../card/hooks/useCardActions";
import {
  SectionBody,
  SectionHeader,
  SectionMain
} from "../../../../common/section";
import useBankImportConsts from "../../hooks/useBankImportConsts";
import BankImportButtons from "./BankImportButtons";

const BankImportMainSection = () => {
  const tableRef = useRef();
  const { t } = useTranslation();
  const {
    handleTableToggle,
    handleSearchToggle,
    handleExpandersToggle
  } = useLayoutActionDispatchers(BANK_IMPORT);
  const { selectedItem } = useDataState(bankImport);
  const { searchQuery } = useSearchState(bankImport);
  const { tabs } = useBankImportConsts();
  const { tableVisible, expandersVisible, searchOpen } = useLayoutState(
    bankImport
  );
  const { handleSearch, handleRowSelect } = useCardActions();

  return (
    <SectionMain toggleSearch={handleSearchToggle}>
      <SectionHeader
        sectionHeading={t("bankFileImport.BankFileImport")}
        getFilters={payload => payload}
        searchQuery={searchQuery}
        tableVisible={tableVisible}
        expandersVisible={expandersVisible}
        expandersEnabled={true}
        tabsEnabled={false}
        onToggleTable={handleTableToggle}
        onToggleExpanders={handleExpandersToggle}
        onToggleSearch={handleSearchToggle}
        onSearch={handleSearch}
        searchOpen={searchOpen}
      />

      <SectionBody
        shortcutsEnabled={false}
        ButtonsComponent={BankImportButtons}
        tableDisabled={true}
        selectedItem={
          !_.isEmpty(selectedItem) ? selectedItem.customer[0].name : ""
        }
        onRowSelect={handleRowSelect}
        tableRef={tableRef}
        expandersEnabled={expandersVisible}
        tabs={tabs}
      />
    </SectionMain>
  );
};

export default BankImportMainSection;
