import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Cookie from "js-cookie";
import jwt from "jwt-decode";
import useAuthActions from "./useAuthActions";
import { useSelector } from "react-redux";

const useUserAuthEffect = () => {
  const d = new Date();
  const authError = useSelector(state => state.auth.error);
  let history = useHistory();
  const userToken = Cookie.get("_session");
  const {
    loadUserData,
    loadInstallationPermissions,
    toggleLoginModalOpen
  } = useAuthActions();
  useEffect(() => {
    if (userToken && jwt(userToken).exp < d.getTime() && !authError) {
      loadUserData(userToken);
      loadInstallationPermissions();
    } else {
      Cookie.remove("_session");
      toggleLoginModalOpen();
      history.push("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken, authError]);
};

export default useUserAuthEffect;
