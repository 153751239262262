import axios from "axios";
import { toast } from "react-toastify";
import { authorizationHeaders } from "../../../../services/queries";
import { extractQueryParameters } from "../../../../services/query";

export const SELECT_FACTORING_INVOICE_ROW = payload => ({
  type: "SELECT_FACTORING_INVOICES_ROW",
  payload: payload
});

export const SUBMIT_FACTORING_INVOICE_QUERY = payload => ({
  type: "SUBMIT_FACTORING_INVOICES_QUERY",
  payload: payload
});

export const LOAD_FACTORING_INVOICE_DATA_START = () => ({
  type: "LOAD_FACTORING_INVOICES_DATA_START"
});

export const LOAD_FACTORING_INVOICE_DATA_SUCCESS = payload => ({
  type: "LOAD_FACTORING_INVOICES_DATA_SUCCESS",
  payload: payload
});

export const LOAD_FACTORING_INVOICE_DATA_ERROR = payload => ({
  type: "LOAD_FACTORING_INVOICES_DATA_ERROR",
  payload: payload
});

export const TOGGLE_FACTORING_INVOICE_SEARCH = () => ({
  type: "TOGGLE_FACTORING_INVOICES_SEARCH"
});

export const SET_FACTORING_INVOICES_TABLE_HEIGHT = payload => ({
  type: "SET_FACTORING_INVOICESS_TABLE_HEIGHT",
  payload: payload
});

export const SET_FACTORING_INVOICES_TABLE_SELECTED_ROWS_STATE = payload => ({
  type: "SET_FACTORING_INVOICES_TABLE_SELECTED_ROWS_STATE",
  payload: payload
});

export const TOGGLE_FACTORING_INVOICES_ROW = payload => ({
  type: "TOGGLE_FACTORING_INVOICES_ROW",
  payload: payload
});

export const TOGGLE_FACTORING_INVOICES_TABLE = () => ({
  type: "TOGGLE_FACTORING_INVOICES_TABLE"
});

export const TOGGLE_FACTORING_INVOICES_EXPANDERS = () => ({
  type: "TOGGLE_FACTORING_INVOICES_EXPANDER_VIEW"
});

//QUERIES

export const getFactoringInvoiceData = payload => {
  return dispatch => {
    dispatch(SUBMIT_FACTORING_INVOICE_QUERY(payload));
    dispatch(LOAD_FACTORING_INVOICE_DATA_START());

    loadFactoringInvoiceData(payload)
      .then(data => {
        dispatch(
          LOAD_FACTORING_INVOICE_DATA_SUCCESS(data.data.data.factoringInvoices)
        );
      })
      .catch(error => {
        toast.error(error.message);
        dispatch(LOAD_FACTORING_INVOICE_DATA_ERROR(error));
      });
  };
};

export const loadFactoringInvoiceData = async payload => {
  const queryData = extractQueryParameters(payload);
  const data = await axios.post(
    "/graphql",
    {
      query: `
      query {
        factoringInvoices(${queryData}) {
          id
          customer {
            name
          }
          factoringDebtor {
            name
          }
          contract{
          contractNo
        customerInvoice{
           invoiceNo
          }
          }
          factoringInvoicePaymentHistory{
          customerInvoiceId
          payment
          paymentTime
          paymentBalance
          invoiceBalance
          }
          invoiceNo
          invoiceDate
          dueDate
          total
          balance
          
          createdOn
          isSummaryInvoice
          
          invoicePaymentHistory {
            paymentTime
            payment
            invoiceBalance
            paymentBalance
          }
        }
      }
  
  `
    },
    authorizationHeaders()
  );
  return data;
};

const getFactoringInvoicesQueryData = ({
  resultCount,
  name,
  id,
  regno,
  isPaid,
  vatNo,
  dateFrom,
  dateTo,
  dueDateFrom,
  dueDateTo,
  generalPhone,
  generalEmail,
  salesAgent
}) => {
  const queryData = `${resultCount ? `count: ${resultCount}` : 100} ${
    name ? `name: "${name}"` : ""
  } ${id ? "id: " + id : ""} ${isPaid ? "is_it_paid: " + isPaid : ""} ${
    regno ? "reg_no: " + regno : ""
  } 
  ${dateFrom ? `date_from: "${dateFrom}"` : ""}
  ${dateTo ? `date_to: "${dateTo}"` : ""}
  ${dueDateFrom ? `due_date_from: "${dueDateFrom}"` : ""}
  ${dueDateTo ? `due_date_to: "${dueDateTo}"` : ""}
  ${vatNo ? "vat_no: " + vatNo : ""} ${
    generalPhone ? `general_phone: "${generalPhone}"` : ""
  } ${generalEmail ? `email: "${generalEmail}"` : ""} ${
    salesAgent ? `sales_agent: "${salesAgent}"` : ""
  }`;
  return queryData;
};
