import React, { forwardRef } from "react";
import { Formik } from "formik";
import {
  BoolSelectInputField,
  DateInputField,
  InputField,
  SelectInputField
} from "../../../../common/form/form";
import useSearchState from "../../../../common/redux/hooks/useSearchState";
import { customers } from "../../../../common/redux/types/selectorTypes";

import AutoCompleteField from "../../../../common/autocomplete/autocompleteField";
import { loadCompanyAutocompleteData } from "../../../../common/autocomplete/graphql/fetchCustomerAutocomplete";

const CustomerVerticalSearchForm = forwardRef(
  ({ t, handleSearch, query }, ref) => {
    const { selectedAutocompleteValue } = useSearchState(customers);

    // const CustomerSearchSchema = Yup.object().shape({
    //   resultCount: Yup.number()
    //     .typeError(t("validation.NoRequired"))
    //     .required(t("validation.Required"))
    // });

    return (
      <Formik
        ref={ref}
        enableReinitialize={true}
        // validationSchema={CustomerSearchSchema}
        initialValues={{
          count: query.count || 1000,
          name: query.name || "",
          id: query.id || "",
          reg_no: query.reg_no || "",
          vat_no: query.vat_no || "",
          general_phone: query.general_phone || "",
          email: query.email || "",
          contact_person: query.contact_person || "",
          sales_agent: query.sales_agent || "",
          customer_manager: query.customer_manager || "",
          without_customer_manager: query.without_customer_manager
            ? query.without_customer_manager
            : "",
          is_active: query.is_active || "true",
          portOneContractsCount: query.portOneContractsCount || "",
          otherContractsCount: query.otherContractsCount || "",
          valid_from: query.valid_from || ""
        }}
        onSubmit={(values, { setSubmitting }) => {
          console.log(values);
          handleSearch(values);
        }}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          validateField,
          validateOnChange,
          errors,
          touched,
          setFieldValue
        }) => (
          <form onSubmit={handleSubmit}>
            <AutoCompleteField
              type="text"
              classes="form__group-stack"
              id="customerName"
              name="name"
              value={values.name}
              handleChange={setFieldValue}
              label={t("customer.customerView.FilterCustomerName")}
              autocomplete="password"
              data-lpignore="true"
              fetchAutcompleteData={loadCompanyAutocompleteData}
            />

            <InputField
              type="text"
              classes="form__group-stack"
              id="customersSearchCustomerId"
              name="id"
              value={values.id}
              onChange={handleChange}
              label={t("customer.customerView.FilterCustomerId")}
            />
            <InputField
              type="text"
              classes="form__group-stack"
              id="customersSearchRegNo"
              name="reg_no"
              value={values.reg_no}
              onChange={handleChange}
              label={t("customer.customerView.FilterRegNo")}
            />
            <InputField
              type="text"
              classes="form__group-stack"
              id="customersSearchVatNo"
              name="vat_no"
              value={values.vat_no}
              onChange={handleChange}
              label={t("customer.customerView.VatNo")}
            />

            <InputField
              type="text"
              classes="form__group-stack"
              id="customersSearchGeneralPhone"
              name="general_phone"
              value={values.general_phone}
              onChange={handleChange}
              label={t("customer.customerView.FilterGeneralPhone")}
            />
            <InputField
              type="text"
              classes="form__group-stack"
              id="customersSearchGeneralEmail"
              name="email"
              value={values.email}
              onChange={handleChange}
              label={t("customer.customerView.FilterGeneralOrInvoiceEmail")}
            />
            <InputField
              type="text"
              classes="form__group-stack"
              id="customerSearchContactPerson"
              name="contact_person"
              value={values.contact_person}
              onChange={handleChange}
              label={t("customer.customerView.FilterContactPerson")}
            />
            <SelectInputField
              classes="form__group-stack"
              options={[{ name: "", value: "" }]}
              id="customersSearchSalesAgent"
              name="sales_agent"
              value={values.sales_agent}
              onChange={handleChange}
              label={t("customer.customerView.SalesAgent")}
            />

            <SelectInputField
              classes="form__group-stack"
              options={[{ name: "", value: "" }]}
              id="customersSearchCustomerManager"
              name="customer_manager"
              value={values.customer_manager}
              onChange={handleChange}
              label={t("customer.customerView.CustomerManager")}
            />

            <BoolSelectInputField
              label={t("customer.customerView.WithoutCustomerManager")}
              classes="form__group-stack"
              id="customersSearchWithoutCustomerManager"
              name="without_customer_manager"
              onChange={handleChange}
              value={values.without_customer_manager}
            />

            <DateInputField
              type="date"
              classes="form__group-stack"
              id="customerSearchValidFrom"
              name="valid_from"
              placement="top"
              value={values.valid_from}
              handleChange={setFieldValue}
              label={t("customer.customerView.FilterCustomerValidFrom")}
            />
            <BoolSelectInputField
              classes="form__group-stack"
              name="is_active"
              id="customerSearchIsActive"
              value={values.is_active}
              onChange={handleChange}
              label={t("customer.customerView.FilterIsActive")}
            />
            <InputField
              type="number"
              classes="form__group-stack"
              id="resultCount"
              name="count"
              value={values.count}
              // error={errors.count}
              onChange={handleChange}
              label={t("customer.customerView.FilterResultCount")}
            />
          </form>
        )}
      </Formik>
    );
  }
);

export default CustomerVerticalSearchForm;
