import axios from "axios";
import { authorizationHeaders } from "../../../../services/queries";
import { extractQueryParameters } from "../../../../services/query";
import { CUSTOMERS } from "../../../common/redux/types/viewTypes";
import {
  LOAD_DATA_ERROR,
  LOAD_DATA_START,
  LOAD_DATA_SUCCESS,
  SUBMIT_QUERY
} from "../../../common/redux/actions/search/searchActions";

export const loadCustomers = payload => {
  return dispatch => {
    dispatch(SUBMIT_QUERY(CUSTOMERS, payload));
    dispatch(LOAD_DATA_START(CUSTOMERS));
    fetchCustomers(payload)
      .then(data =>
        dispatch(LOAD_DATA_SUCCESS(CUSTOMERS, data.data.data.companies))
      )
      .catch(error => dispatch(LOAD_DATA_ERROR(CUSTOMERS, error.message)));
  };
};

export const fetchCustomers = async payload => {
  const queryData = extractQueryParameters(payload);
  try {
    const data = await axios.post(
      "/graphql",
      {
        query: `query{
        companies(${queryData} type: CUSTOMER){
          remainingCreditLimit
  
          id
          name
          debt
          creditLimit
          balance
          customerManagerId
          portOneContractsCount
          otherContractsCount
          customerBankAccounts {
            id
            isPrimary
            bankAccount
            customerId
          }
          customerContactPerson {      
            name
            contactPersonTypeCl
            languageCl
            personalCode
            phone
            email
            additionalInfo
            
            documentNo
            identificationDate
            documentIssueDate
            documentExpireDate
          }
          legalAddress {      
            country {        
              code        
            }
            city {      
              name
            }
            street {
              name
            }
            houseOrApartment
            zipCode
          }
          postalAddress {
            country {        
              code        
            }
            city {
              name
            }
            street {
              name
            }
            houseOrApartment
            zipCode
          }
          boardMemberAddress {      
            country {        
              code        
            }
            city {
              name
            }
            street {
              name
            }
            houseOrApartment
            zipCode
          }
          regNo
          createdOn
          salesAgent{
            id
            name
          }
          customerContactPerson{
          id
             name
      contactPersonTypeCl
      hasSignatureRight
      isPrimary
      languageCl
      personalCode
      phone
      email
      additionalInfo
      documentNo
      documentIssueDate
      identificationDate
      documentExpireDate
      documentFileName
      address{
        country{code}
        city{name}
        street{name}
        houseOrApartment
        zipCode
      
    }
  
          }
  
          customerComments{
            id,
            comment,
            createdOn,
            createdByUserId,
            customerId
            
          }
          customerVatPayer {
            id
            country {
              code
            }
            number
          }
          customerExcisePayer {
            country {
              code
            }
            number
          }
          
          
  
  
          contracts(type: "K"){
            
      contractNo
      
      contractTypeCl
      additionalInfo
      currentStatusDate
          }
      paymentTermInDays,
      penaltyPercent,
      idsPaymentTermInDays,
      automaticPenaltyFrom,
      invoiceEmail,
      isDiscountCl,
      businessAreaCode,
      isSendingOriginalInvoiceAllowedCl,
      invoiceLanguageCl,
      invoiceCurrencyCl,
      isYxInNokCl,
      paymentRef,
      polskaVatRegionCode
          isActiveCl
          generalFax
          generalEmail
          generalPhone
          additionalInfo
          
        }
       }
       
        `
      },
      authorizationHeaders()
    );
    return data;
  } catch (err) {
    throw new Error("Error loading data");
  }
};
