import React from "react";
import useTabState from "../../redux/hooks/useTabState";
import useTabTableActionDispatchers from "../../redux/actions/table/useTabTableActionDispatchers";
import Table from "../../table";
import { FormFlexWrapper } from "../../form/form";
import { Button } from "../../buttons/button";
import { useTranslation } from "react-i18next";

const CommentsTab = ({
  viewSelector,
  tabSelector,
  viewType,
  tabType,
  data
}) => {
  const { t } = useTranslation();
  const { table, selectedItem } = useTabState(viewSelector, tabSelector);
  const { handleTableHeightSet } = useTabTableActionDispatchers(
    viewType,
    tabType
  );
  const CustomerComments = "customer.customerComments.";
  const columns = [
    {
      Header: t(CustomerComments + "HeaderModifiedOn"),
      accessor: "createdOn",
      width: 80
    },
    {
      Header: t(CustomerComments + "HeaderModifiedByUser"),
      accessor: "createdByUserId",
      width: 80
    },
    {
      Header: t(CustomerComments + "HeaderComment"),
      accessor: "comment",
      width: 900
    }
  ];

  return (
    <div style={{ padding: "0.2rem 0rem 0.8rem 0rem" }}>
      <Table
        classes="u-margin-top-small"
        height={table.height}
        setTableHeight={handleTableHeightSet}
        columns={columns}
        data={data.customerComments || []}
        selectedRow={selectedItem}
        onRowSelect={() => {}}
      />
      <FormFlexWrapper classes="u-margin-top-tiny">
        <Button
          classes="btn--footer"
          btnStyle="primary"
          size="medium"
          label="Add"
        />
        <Button
          classes="btn--footer u-margin-left-tiny"
          label="Delete"
          btnStyle="danger"
          size="medium"
        />
      </FormFlexWrapper>
    </div>
  );
};

export default CommentsTab;
