import React from "react";

const View = ({ MainSection, SearchSection }) => {
  return (
    <React.Fragment>
      {MainSection ? <MainSection /> : ""}
        {SearchSection ?  <SearchSection /> : "" }
    </React.Fragment>
  );
};

export default View;
