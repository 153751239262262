import axios from "axios";
import { authorizationHeaders } from "../../../../../services/queries";
import { SUBMIT_CUSTOMER_DEPOSIT_QUERY } from "../customerDepositActions";
import { extractQueryParameters } from "../../../../../services/query";
import {
  LOAD_DATA_ERROR,
  LOAD_DATA_START,
  LOAD_DATA_SUCCESS
} from "../../../../common/redux/actions/search/searchActions";
import { CUSTOMER_DEPOSIT } from "../../../../common/redux/types/viewTypes";

export const customerDepositSearchQuery = async payload => {
  try {
    const queryParameters = extractQueryParameters(payload);
    const data = await axios.post(
      "/graphql",
      {
        query: `query{  
        customerDepositAccounts(${queryParameters}){
          id
          addToInvoice
          customer{name}
          createdOn
          sum
          additionalInfo
        }
      }`
      },
      authorizationHeaders()
    );
    return data;
  } catch (err) {
    throw new Error("Error loading data");
  }
};

export const getCustomerDepoData = payload => {
  return dispatch => {
    dispatch(SUBMIT_CUSTOMER_DEPOSIT_QUERY(payload));
    dispatch(LOAD_DATA_START(CUSTOMER_DEPOSIT));
    customerDepositSearchQuery(payload)
      .then(d =>
        dispatch(
          LOAD_DATA_SUCCESS(
            CUSTOMER_DEPOSIT,
            d.data.data.customerDepositAccounts
          )
        )
      )
      .catch(err => dispatch(LOAD_DATA_ERROR(CUSTOMER_DEPOSIT, err.message)));
  };
};
