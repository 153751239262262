import React, { forwardRef } from "react";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { clearing } from "../../../common/redux/types/selectorTypes";
import useSearchState from "../../../common/redux/hooks/useSearchState";
import { DateInputField, InputField } from "../../../common/form/form";
import AutoCompleteField from "../../../common/autocomplete/autocompleteField";
import { loadCompanyAutocompleteData } from "../../../common/autocomplete/graphql/fetchCustomerAutocomplete";

const ClearingSearchForm = forwardRef(({ submitRef }, ref) => {
  const { selectedAutocompleteValue } = useSearchState(clearing);

  const { t } = useTranslation();

  return (
    <Formik
      ref={ref}
      enableReinitialize={true}
      initialValues={{
        count: 1000,
        customer: selectedAutocompleteValue || "",
        type: "",
        car_reg_no: "",
        card_no: "",
        status: "",
        valid_from: "",
        valid_thru: "",
        is_valid: "",
        customer_country: "",
        sales_agent: "",
        is_active: ""
      }}
      onSubmit={(values, { setSubmitting }) => {}}
    >
      {({
        values,
        handleChange,
        handleSubmit,
        validateField,
        validateOnChange,
        errors,
        touched,
        setFieldValue
      }) => (
        <form onSubmit={handleSubmit}>
          <AutoCompleteField
            type="text"
            classes="form__group-stack"
            id="clearingSearchCustomer"
            name="customer"
            value={values.customer}
            handleChange={e => {
              handleChange(e);
            }}
            label={t("vat.documents.FilterCustomer")}
            autocomplete="password"
            data-lpignore="true"
            fetchAutcompleteData={loadCompanyAutocompleteData}
          />

          <DateInputField
            id="clearingSearchDateFrom"
            name="date_from"
            label={t("clearing.FilterDateFrom")}
            value={values.date_from}
            handleChange={setFieldValue}
            classes="form__group-stack"
          />
          <DateInputField
            id="clearingSearchDateTo"
            name="date_to"
            label={t("clearing.FilterDateTo")}
            value={values.date_to}
            handleChange={setFieldValue}
            classes="form__group-stack"
          />

          <InputField
            id="clearingSearchResultCount"
            name="count"
            type="number"
            label={t("customer.customerView.FilterResultCount")}
            value={values.count}
            options={[]}
            onChange={handleChange}
            classes="form__group-stack"
          />
        </form>
      )}
    </Formik>
  );
});

export default ClearingSearchForm;
