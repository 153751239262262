import axios from "axios";
import { authorizationHeaders } from "../../../../services/queries";
import { toast } from "react-toastify";
import {
  LOAD_DATA_ERROR,
  LOAD_DATA_START,
  LOAD_DATA_SUCCESS,
  SUBMIT_QUERY
} from "../../../common/redux/actions/search/searchActions";
import { FACTORING_DEBTOR_ACCOUNT } from "../../../common/redux/types/viewTypes";
import { extractQueryParameters } from "../../../../services/query";

export const loadFactoringDebtorAccData = payload => {
  return dispatch => {
    dispatch(SUBMIT_QUERY(FACTORING_DEBTOR_ACCOUNT, payload));
    dispatch(LOAD_DATA_START(FACTORING_DEBTOR_ACCOUNT));
    fetchFactoringDebtorAccData()
      .then(d => dispatch(LOAD_DATA_SUCCESS(d)))
      .catch(err => {
        toast.error(err.message);
        dispatch(LOAD_DATA_ERROR(err));
      });
  };
};

export const fetchFactoringDebtorAccData = async payload => {
  const queryData = extractQueryParameters(payload);
  const data = await axios.post(
    "/graphql",
    {
      query: `query {
      customerAccounts(${queryData} type:FACTORING_DEBTOR) {
        id
        entryBalance
        customer{name}
        entryNo
        additionalInfo
        entryTime
        sum
        entrySourceTypeCl
        entrySource
        additionalInfo
        relatedCustomerClearingDocumentItems {
          id
          description
          balance
          sum
          created_on
          customer_account_id
          customer_clearing_document_id
          entry_time
          additional_info
        }
      }
  }`
    },
    authorizationHeaders()
  );
  return data;
};
