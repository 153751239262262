import React from "react";
import {useTranslation} from "react-i18next";
import useDataState from "../../../common/redux/hooks/useDataState";
import {brokerClaims, details,} from "../../../common/redux/types/selectorTypes";
import {BROKER_CLAIMS, DETAILS,} from "../../../common/redux/types/viewTypes";
import BrokerClaimsDetailsForm from "../details/forms/brokerClaimsDetailsForm";

const useBrokerClaimsConsts = () => {
    const { t } = useTranslation();


    const {selectedItem} = useDataState(brokerClaims);


    const brokerClaimsLabels = {
        name: t("customer.customerView.FilterCustomerName"),
        id: t("customer.customerView.FilterCustomerId"),
        woCusManager: t("customer.customerView.WithoutCustomerManager"),
        reg_no: t("customer.customerView.FilterRegNo"),
        vat_no: t("customer.customerView.VatNo"),
        contactPerson: t("customer.customerView.FilterContactPerson"),
        count: t("customer.customerView.FilterResultCount"),
        general_phone: t("customer.customerView.FilterGeneralPhone"),
        email: t("customer.customerView.FilterGeneralOrInvoiceEmail"),
        salesAgent: t("customer.customerView.SalesAgent"),
        customerManager: t("customer.customerView.CustomerManager"),
        withoutCustomerManager: t("customer.customerView.WithoutCustomerManager"),
        is_active: t("customer.customerView.FilterIsActive"),
        valid_from: t("customer.customerView.FilterCustomerValidFrom"),
        portOneContractsCount: t(
            "customer.customerView.SearchResultColumnNumberOfP1Contracts"
        ),
        otherContractsCount: t(
            "customer.customerView.SearchResultColumnNumberOfOtherContracts"
        )
    };

    const brokerClaimsTabs =
        [
            {
                name: "details",
                label: t("customer.customerView.TabHeaderCustomerDetailData"),
                component: (
                    <BrokerClaimsDetailsForm data={selectedItem} t={t}/>
                ),
                viewSelector: brokerClaims,
                tabSelector: details,
                viewType: BROKER_CLAIMS,
                tabType: DETAILS,
            },

        ];


    return { brokerClaimsLabels, brokerClaimsTabs };
};

export default useBrokerClaimsConsts;
