import React from "react";
import { Button } from "../../../../common/buttons/button";
import { useTranslation } from "react-i18next";

const FactoringDebtorAccountButtons = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Button
        btnStyle="green"
        size="small"
        classes="btn--footer"
        disabled={true}
        label={t("customerAccount.BtnAdd")}
      />
      <Button
        btnStyle="danger"
        size="small"
        disabled={true}
        classes="btn--footer"
        label={t("customerAccount.BtnCancel")}
      />
      <Button
        btnStyle="danger"
        size="small"
        disabled={true}
        classes="btn--footer"
        label={t("customerAccount.BtnDelete")}
      />
      <Button
        btnStyle="green"
        size="small"
        disabled={true}
        classes="btn--footer"
        label={t("customerAccount.BtnSave")}
      />
      <Button
        btnStyle="lime"
        size="small"
        disabled={true}
        classes="btn--footer"
        label={t("customerAccount.BtnGenerateBrokerClaimFromCustomerAccount")}
      />
      <Button
        btnStyle="lime"
        size="small"
        disabled={true}
        classes="btn--footer"
        label={t("customerAccount.BtnCSV")}
      />
    </React.Fragment>
  );
};

export default FactoringDebtorAccountButtons;
