import React, { useRef } from "react";
import useCardLayoutState from "../../../card/hooks/layout/useCardLayoutState";
import useLayoutActionDispatchers from "../../../common/redux/actions/layout/hooks/useLayoutActionDispatchers";
import { CLEARING } from "../../../common/redux/types/viewTypes";
import { clearing } from "../../../common/redux/types/selectorTypes";
import useDataState from "../../../common/redux/hooks/useDataState";
import { SearchSection } from "../../../common/section";
import ClearingSearchForm from "./clearingSearchForm";

const ClearingSearchSection = () => {
  const { searchOpen } = useCardLayoutState();
  const { handleSearchToggle } = useLayoutActionDispatchers(CLEARING);
  const { isLoading } = useDataState(clearing);
  const formRef = useRef();
  return (
    <SearchSection
      isOpen={searchOpen}
      formRef={formRef}
      onSearchToggle={handleSearchToggle}
      dataLoading={isLoading}
      searchDisabled={true}
    >
      <ClearingSearchForm ref={formRef} />
    </SearchSection>
  );
};

export default ClearingSearchSection;
