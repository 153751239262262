import React from "react";
import { Button } from "../../../../common/buttons/button";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const FactoringDebtorOverdueInvoicesButtons = () => {
  const { t } = useTranslation();
  const TranslationNS = "factoring.debtor.overdueInvoices.";
  return (
    <React.Fragment>
      <Button
        btnStyle="lime"
        size="small"
        handleClick={() => {}}
        label={t(TranslationNS + "BtnSendEmail")}
        classes="btn--footer"
        disabled={true}
      />
      <Button
        btnStyle="lime"
        size="small"
        label={t(TranslationNS + "BtnOpenPdf")}
        handleClick={() => {
          toast.success("Entity deleted");
        }}
        classes={"btn--footer"}
        disabled={true}
      />
      <Button
        btnStyle="green"
        size="small"
        label={t(TranslationNS + "BtnGenerate")}
        handleClick={() => {}}
        disabled={true}
        classes="btn--footer"
      />

      <Button
        btnStyle="lime"
        size="small"
        label={t(TranslationNS + "BtnSave")}
        handleClick={() => alert("CSV")}
        classes={"btn--footer"}
        disabled={true}
      />
      <Button
        btnStyle="danger"
        size="small"
        label={t(TranslationNS + "BtnDelete")}
        handleClick={() => alert("PRINT ENVELOPE")}
        classes="btn--footer"
        disabled={true}
      />
      <Button
        btnStyle="lime"
        size="small"
        label={t(TranslationNS + "BtnCsv")}
        handleClick={() => alert("PRINT ENVELOPE")}
        classes="btn--footer"
        disabled={true}
      />
    </React.Fragment>
  );
};

export default FactoringDebtorOverdueInvoicesButtons;
