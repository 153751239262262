import React from "react";
import {
  FormBody,
  FormCol,
  InputField,
  MultilineInputField,
  SelectInputField
} from "../../../common/form/form";
import { Formik } from "formik";
import useCustomerDepositState from "../hooks/useCustomerDepositState";

const CustomerDepositDetails = ({ t }) => {
  const { selectedRow } = useCustomerDepositState();

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        customerDepoCustomerName: selectedRow.customer
          ? selectedRow.customer[0].name
          : "",
        customerDepoInvoiceNo: "",
        customerDepoAddToInvoice: selectedRow.addToInvoice || "",
        customerDepoEntryDate: selectedRow.createdOn || "",
        customerDepoSum: selectedRow.sum || "",
        customerDepoAdditionalInfo: selectedRow.additionalInfo || ""
      }}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          alert(JSON.stringify(values, null, 2));
          setSubmitting(false);
        }, 400);
      }}
    >
      {({
        values,
        errors,
        status,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting
      }) => (
        <FormBody flex={"true"}>
          <FormCol>
            <InputField
              classes="form__group"
              label={t("customerDeposit.CustomerName")}
              value={values.customerDepoCustomerName}
              onChange={handleChange}
              id="customerDepoCustomerName"
            />
            <InputField
              classes="form__group"
              label={t("customerDeposit.InvoiceNo")}
              value={""}
              onChange={handleChange}
              id="customerDepoInvoiceNo"
            />
            <SelectInputField
              id="customerDepoAddToInvoice"
              classes="form__group"
              value={values.customerDepoAddToInvoice}
              label={t("customerDeposit.AddToInvoice")}
              onChange={handleChange}
              options={[
                { name: "", value: "" },
                { name: t("common.ReplyYes"), value: "true" },
                { name: t("common.ReplyNo"), value: "false" }
              ]}
            />
          </FormCol>
          <FormCol className="u-margin-left-small">
            <InputField
              id="customerDepoEntryDate"
              classes="form__group"
              value={values.customerDepoEntryDate}
              onChange={handleChange}
              label={t("customerDeposit.EntryDate")}
            />
            <InputField
              classes="form__group"
              label={t("customerDeposit.Amount")}
              value={values.customerDepoSum}
              onChange={handleChange}
              id="customerDepoAmount"
            />

            <MultilineInputField
              label={t("customerDeposit.AdditionalInfo")}
              value={values.customerDepoAdditionalInfo}
              classes="u-border-radius-3px"
              onChange={handleChange}
              id="additionalInfo"
            />
          </FormCol>
        </FormBody>
      )}
    </Formik>
  );
};

export default CustomerDepositDetails;
