import React from "react";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import {
  FormBody,
  FormCol,
  FormFlexItem,
  InputField,
  SelectInputField
} from "../../common/form/form";
import { Button } from "../../common/buttons/button";

const ContractViewFactoringBankAccount = ({ data }) => {
  const { t } = useTranslation();

  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={{}}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            alert(JSON.stringify(values, null, 2));
            setSubmitting(false);
          }, 400);
        }}
      >
        {({
          values,
          errors,
          status,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting
        }) => (
          <FormBody flex={"true"}>
            <FormCol>
              <SelectInputField
                classes="form__group"
                id="accountType"
                options={["", "SWED", "LHV"]}
                value={values.accountType}
                onChange={handleChange}
                label={t("vat.applications.details.BankAccountType")}
              />
              <SelectInputField
                classes="form__group"
                id="bank"
                options={["", "SWED", "LHV"]}
                value={values.bank}
                onChange={handleChange}
                label={t("vat.applications.details.Bank")}
              />
              <FormFlexItem flexBasis={"25%"}>
                <Button
                  btnStyle="primary"
                  size="medium"
                  label={t("Save account type")}
                />
              </FormFlexItem>
            </FormCol>
            <FormCol className="u-margin-left-small">
              <InputField
                type="text"
                id="swift"
                classes="form__group"
                value={values.swift}
                onChange={handleChange}
                label="SWIFT"
              />
              <InputField
                type="text"
                id="iban"
                classes="form__group"
                value={values.swift}
                onChange={handleChange}
                label="IBAN"
              />
            </FormCol>
          </FormBody>
        )}
      </Formik>
    </div>
  );
};

export default ContractViewFactoringBankAccount;
