import {
  LOAD_DATA_ERROR,
  LOAD_DATA_START,
  LOAD_DATA_SUCCESS,
  SUBMIT_QUERY
} from "../redux/actions/search/searchActions";

export const loadData = (payload, viewType, dataSelector, fetchCallback) => {
  return dispatch => {
    dispatch(SUBMIT_QUERY(viewType, payload));
    dispatch(LOAD_DATA_START(viewType));
    fetchCallback(payload)
      .then(data =>
        dispatch(LOAD_DATA_SUCCESS(viewType, data.data.data[dataSelector]))
      )
      .catch(error => dispatch(LOAD_DATA_ERROR(viewType, error.message)));
  };
};
