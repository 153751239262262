import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { numberWithSpaces } from "../../../services/numberFunctions";
import { Loading } from "../../common/loading";
import Table from "../../common/table";
import { TableResultData } from "../../common/tableFooter";
import { useTranslation } from "react-i18next";
import useDataState from "../../common/redux/hooks/useDataState";
import { customerInvoices } from "../../common/redux/types/selectorTypes";
import useTableLayoutActionDispatchers from "../../common/redux/actions/layout/table/hooks/useTableLayoutActionDispatchers";
import { CUSTOMER_INVOICES } from "../../common/redux/types/viewTypes";
import useTableState from "../../common/redux/hooks/useTableState";
import useTableActionDispatchers from "../../common/redux/actions/table/hooks/useTableActionDispatchers";

const CustomerInvoiceTable = ({ tableRef }) => {
  const countries = useSelector(
    state => state.layout.classifications.countries
  );

  const { items, isLoading, selectedItem } = useDataState(customerInvoices);
  const { height } = useTableState(customerInvoices);
  const { t } = useTranslation();

  const { handleRowSelect } = useTableActionDispatchers(CUSTOMER_INVOICES);
  const { handleTableHeightSet } = useTableLayoutActionDispatchers(
    CUSTOMER_INVOICES
  );

  const CustomerInvoiceTableTrans = "customerInvoice.";
  const columns = useMemo(
    () => [
      {
        id: "company[0]",
        Header: t(CustomerInvoiceTableTrans + "CustomerName"),
        accessor: d => d.company[0].name,
        minWidth: 200
      },

      {
        Header: t(CustomerInvoiceTableTrans + "InvoiceNo"),
        accessor: "invoiceNo" // String-based value accessors!
      },
      {
        Header: t(CustomerInvoiceTableTrans + "InvoiceDate"),
        accessor: "invoiceDate",
        width: 100,
        Cell: ({ cell: { value } }) => (
          <div className="table__align-center">{value}</div>
        )
      },
      {
        id: "reseller",
        Header: t(CustomerInvoiceTableTrans + "ResellerName"),
        width: 100,
        accessor: d => {
          if (d.reseller[0]) {
            return d.reseller[0].name;
          } else {
            return "";
          }
        }
      },

      {
        id: "netto",
        Header: t(CustomerInvoiceTableTrans + "NetAmountStr"),
        accessor: d => Number(d.netto),
        sortType: "basic",
        Cell: ({ cell: { value } }) => (
          <div className="table__align-right">{numberWithSpaces(value)}</div>
        ),
        cellAlign: "right",
        width: 105
      },
      {
        id: "vat",
        Header: t(CustomerInvoiceTableTrans + "VatAmountStr"),
        accessor: d => d.vat,
        sortType: "basic",
        Cell: ({ cell: { value } }) => (
          <div className="table__align-right">{numberWithSpaces(value)}</div>
        ),
        cellAlign: "right",
        width: 100
      },
      {
        id: "totalInEur",
        Header: t(CustomerInvoiceTableTrans + "TotalAmountEurStr"),
        accessor: d => d.totalInEur,
        sortType: "basic",
        Cell: ({ cell: { value } }) => (
          <div className="table__align-right">{numberWithSpaces(value)}</div>
        ),
        width: 100,
        cellAlign: "right"
      },

      {
        id: "balance",
        Header: t(CustomerInvoiceTableTrans + "BalanceStr"),
        accessor: d => numberWithSpaces(d.balance ? d.balance : 0),
        width: 80,
        Cell: ({ cell: { value } }) => (
          <div className="table__align-right">{value}</div>
        ),
        cellAlign: "right"
      },
      {
        id: "issuedCountryId",
        Header: t(CustomerInvoiceTableTrans + "IssuedCountryName"),
        width: 100,
        accessor: d => {
          const country = countries.filter(c => {
            return c.id === d.issuedCountryId;
          });
          if (country && country.length) {
            return t(`countries.${country[0].code}`);
          } else {
            return "";
          }
        }
      },
      {
        Header: t(CustomerInvoiceTableTrans + "DueDate"),
        accessor: "dueDate",
        Cell: ({ cell: { value } }) => (
          <div className="table__align-center">{value}</div>
        ),
        width: 100
      },
      {
        Header: t(CustomerInvoiceTableTrans + "ExchangeRate"),
        accessor: "exchangeRateToEur",
        width: 80
      },
      {
        id: "totalInSecondCurrency",
        Header: t(CustomerInvoiceTableTrans + "SumInSecondCurrency"),
        accessor: d => d.totalInSecondCurrency || "",
        Cell: ({ cell: { value } }) => (
          <div className="table__align-right">{value}</div>
        ),
        width: 100
      },
      {
        id: "currency",
        Header: t(CustomerInvoiceTableTrans + "Currency"),
        accessor: d => {
          switch (d.currencyCl) {
            case "CURRENCY.EUR": {
              return "EUR";
            }
            default: {
              return "";
            }
          }
        },
        width: 70
      }
    ],
    [countries, t]
  );

  return (
    <React.Fragment>
      <Table
        columns={columns}
        height={height}
        setTableHeight={handleTableHeightSet}
        data={items}
        isLoading={isLoading}
        loadingComponent={Loading}
        onRowSelect={handleRowSelect}
        selectedRowsState={[]}
        selectedRow={selectedItem}
        tableRef={tableRef}
      />
      <TableResultData
        count={items.length}
        selectedRows={[]}
        onRowSelect={handleRowSelect}
        selectedRow={selectedItem}
        onRemoveItem={[]}
      />
    </React.Fragment>
  );
};

export default CustomerInvoiceTable;
