import {useCallback} from "react";
import {useDispatch} from "react-redux";
import {
  TOGGLE_TRANSACTIONS_EXPANDER_VIEW,
  TOGGLE_TRANSACTIONS_SEARCH,
  TOGGLE_TRANSACTIONS_TABLE
} from "../../actions/transactionsActions";

const useTransactionsLayoutActions = () => {
  const dispatch = useDispatch();

  const handleTableToggle = useCallback(() => {
    dispatch(TOGGLE_TRANSACTIONS_TABLE());
  }, [dispatch]);

  const handleExpandersToggle = useCallback(() => {
    dispatch(TOGGLE_TRANSACTIONS_EXPANDER_VIEW());
  }, [dispatch]);
  const handleSearchToggle = useCallback(() => {
    dispatch(TOGGLE_TRANSACTIONS_SEARCH());
  }, [dispatch]);

  return { handleTableToggle, handleExpandersToggle, handleSearchToggle };
};

export default useTransactionsLayoutActions;
