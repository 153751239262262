import {useMemo} from "react";
import {useTranslation} from "react-i18next";
import useContractState from "../../hooks/useContractState";

const useContractSearchDropdowns = () => {
  const { t } = useTranslation();
  const { countries, contractStatuses, contractTypes } = useContractState();

  const getCountriesData = useMemo(
    () => [
      { name: "", value: "" },
      ...countries.map(item => {
        return Object.assign(
          {},
          {
            name: item.code ? t(`countries.${item.code}`) : "",
            value: item.code
          }
        );
      })
    ],
    [t, countries]
  );

  const getContractStatusesData = useMemo(
    () => [
      { name: "", value: "" },
      ...contractStatuses.map(item =>
        Object.assign(
          {},
          {
            name: item.name
              ? t(`classifications.CONTRACT_STATUS.${item.name.split(".")[1]}`)
              : "",
            value: item.value
          }
        )
      )
    ],
    [t, contractStatuses]
  );

  const getContractTypesData = useMemo(
    () => [
      { name: "", value: "" },
      ...contractTypes.map(item =>
        Object.assign(
          {},
          {
            name: item.name
              ? t(`classifications.CONTRACT_TYPE.${item.name.split(".")[1]}`)
              : "",
            value: item.value
          }
        )
      )
    ],
    [t, contractTypes]
  );

  return { getCountriesData, getContractTypesData, getContractStatusesData };
};

export default useContractSearchDropdowns;
