import React, { forwardRef } from "react";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import useSearchState from "../../../../common/redux/hooks/useSearchState";
import { brokerClaims } from "../../../../common/redux/types/selectorTypes";
import {
  BoolSelectInputField,
  DateInputField,
  InputField,
  SelectInputField
} from "../../../../common/form/form";
import useAppClassifications from "../../../../layout/hooks/useAppClassifications";
import { loadCompanyAutocompleteData } from "../../../../common/autocomplete/graphql/fetchCustomerAutocomplete";
import AutoCompleteField from "../../../../common/autocomplete/autocompleteField";

const BrokerClaimsSearchForm = forwardRef(
  ({ submitRef, handleSearch, brokerClaimTypes }, ref) => {
    const dispatch = useDispatch();
    const { selectedAutocompleteValue } = useSearchState(brokerClaims);
    const { broker_claim_types } = useAppClassifications();
    const claimTypes = broker_claim_types || [];

    const { t } = useTranslation();

    return (
      <Formik
        ref={ref}
        enableReinitialize={true}
        initialValues={{
          count: 1000,
          customer: "",
          claim_type: "",
          invoice_no: ""
        }}
        onSubmit={(values, { setSubmitting }) => {
          handleSearch(values);
        }}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          validateField,
          validateOnChange,
          errors,
          touched,
          setFieldValue
        }) => (
          <form onSubmit={handleSubmit}>
            <AutoCompleteField
              type="text"
              classes="form__group"
              id="brokerClaimsSearchCustomer"
              name="customer"
              value={values.customer}
              handleChange={setFieldValue}
              label={t("vat.documents.FilterCustomer")}
              autocomplete="password"
              data-lpignore="true"
              fetchAutcompleteData={loadCompanyAutocompleteData}
            />
            <BoolSelectInputField
              id="brokerClaimsSearchAddToInvoice"
              name="add_to_invoice"
              label={t("broker.claims.AddToInvoice")}
              value={values.add_to_invoice}
              onChange={handleChange}
              classes="form__group-stack"
            />
            <SelectInputField
              id="brokerClaimsSearchClaimType"
              name="claim_type"
              label={t("broker.claims.BrokerClaimType")}
              value={values.claim_type}
              options={[
                { name: "", value: "" },
                ...claimTypes.map(item => ({
                  name: t(`classifications.${item.name}`),
                  value: item.value
                }))
              ]}
              onChange={handleChange}
              classes="form__group-stack"
            />
            <InputField
              id="brokerClaimsSearchInvoiceNo"
              name="invoice_no"
              label={t("broker.claims.FilterInvoiceNo")}
              value={values.invoice_no}
              onChange={handleChange}
              autocomplete={"nope"}
              classes="form__group-stack"
            />

            <DateInputField
              id="brokerClaimsSearchClaimsFrom"
              name="claims_from"
              label={t("broker.claims.FilterBrokerClaimsFrom")}
              value={values.claims_from}
              classes="form__group-stack"
              handleChange={setFieldValue}
            />

            <DateInputField
              id="brokerClaimsSearchClaimsTo"
              name="claims_to"
              label={t("broker.claims.FilterBrokerClaimsTo")}
              value={values.claims_to}
              handleChange={setFieldValue}
              classes="form__group-stack"
            />
            <DateInputField
              id="brokerClaimsSearchClaimNo"
              name="claim_no"
              label={t("broker.claims.FilterClaimNo")}
              value={values.claim_no}
              handleChange={setFieldValue}
              classes="form__group-stack"
            />
            <DateInputField
              id="brokerClaimsSearchCreatedFrom"
              name="created_from"
              label={t("broker.claims.FilterCreatedFrom")}
              value={values.created_from}
              onChange={handleChange}
              classes="form__group-stack"
            />

            <InputField
              id="brokerClaimsSearchCount"
              name="count"
              type="number"
              label={t("customer.customerView.FilterResultCount")}
              value={values.count}
              onChange={handleChange}
              classes="form__group-stack"
            />
          </form>
        )}
      </Formik>
    );
  }
);

export default BrokerClaimsSearchForm;
