import { useSelector } from "react-redux";

const useNavigationState = () => {
  const sectionsGroupOpen = useSelector(
    state => state.layout.navbar.menutoggle.sections
  );
  const hiddenGroupOpen = useSelector(
    state => state.layout.navbar.menutoggle.hidden
  );
  const selectedMainItem = useSelector(
    state => state.layout.selectedNavMainItem
  );

  const adminGroupOpen = useSelector(
    state => state.layout.navbar.menutoggle.admin
  );

  return {
    sectionsGroupOpen,
    hiddenGroupOpen,
    selectedMainItem,
    adminGroupOpen
  };
};

export default useNavigationState;
