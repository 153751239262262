import React from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import useTabState from "../../../common/redux/hooks/useTabState";
import {
  factoringInvoices,
  paymentHistory
} from "../../../common/redux/types/selectorTypes";
import useTabTableActionDispatchers from "../../../common/redux/actions/table/useTabTableActionDispatchers";
import {
  FACTORING_INVOICES,
  PAYMENT_HISTORY
} from "../../../common/redux/types/viewTypes";
import Table from "../../../common/table";
import { TableResultData } from "../../../common/tableFooter";
import useDataState from "../../../common/redux/hooks/useDataState";

const FactoringInvoicesPaymentHistoryTable = () => {
  const { t } = useTranslation();

  const { table, selectedItem } = useTabState(
    factoringInvoices,
    paymentHistory
  );
  const {
    handleTableHeightSet,
    handleRowSelect
  } = useTabTableActionDispatchers(FACTORING_INVOICES, PAYMENT_HISTORY);
  const { selectedItem: data } = useDataState(factoringInvoices);

  const FactoringInvoicesTableTrans = "factoring.invoices.";
  const columns = [
    {
      id: "paymentTime",
      Header: t("factoring.invoices.InvoiceDate"),
      accessor: d => d.paymentTime || ""
    },
    {
      id: "payment",
      Header: t("factoring.invoices.Payment"),
      accessor: d => d.payment || "",
      Cell: ({ cell: { value } }) => (
        <div className="table__align-right">{value}</div>
      )
    },
    {
      id: "balance",
      Header: t("factoring.invoices.Balance"),
      accessor: d => (d.invoiceBalance ? d.invoiceBalance : 0),
      Cell: ({ cell: { value } }) => (
        <div className="table__align-right">{value}</div>
      )
    },
    {
      id: "paymentBalance",
      Header: t("factoring.invoices.PaymentBalance"),
      accessor: d => (d.paymentBalance ? d.paymentBalance : 0),
      Cell: ({ cell: { value } }) => (
        <div className="table__align-right">{value}</div>
      )
    }
  ];
  console.log(data, "DATA");
  return (
    <React.Fragment>
      <Table
        columns={columns}
        data={
          !_.isEmpty(data)
            ? data.factoringInvoicePaymentHistory.map((item, idx) => {
                return { ...item, id: idx };
              })
            : []
        }
        height={table.height}
        setTableHeight={handleTableHeightSet}
        onRowSelect={handleRowSelect}
        selectedRow={selectedItem}
        selectedRowsState={[]}
      />
      <TableResultData
        count={0}
        enabled={true}
        onRowSelect={handleRowSelect}
        selectedRow={selectedItem}
      />
    </React.Fragment>
  );
};

export default FactoringInvoicesPaymentHistoryTable;
