import React, { useRef } from "react";
import {
  SectionBody,
  SectionHeader,
  SectionMain
} from "../../../../common/section";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import useFactoringInvoicesState from "../../hooks/useFactoringInvoicesState";
import useFactoringInvoicesConsts from "../../hooks/useFactoringInvoicesConsts";
import FactoringInvoicesTable from "../../factoringInvoicesTable";
import FactoringInvoicesButtons from "./factoringInvoicesButtons";
import useFactoringInvoicesUtils from "../../hooks/useFactoringInvoicesUtils";
import useLayoutActionDispatchers from "../../../../common/redux/actions/layout/hooks/useLayoutActionDispatchers";
import { FACTORING_INVOICES } from "../../../../common/redux/types/viewTypes";
import useLayoutState from "../../../../common/redux/hooks/useLayoutState";
import { factoringInvoices } from "../../../../common/redux/types/selectorTypes";
import { loadData } from "../../../../common/graphql/loadData";
import { loadFactoringInvoiceData } from "../../graphql/factoringInvoicesDataQueries";

const FactoringInvoicesMainSection = () => {
  const { t } = useTranslation();
  const tableRef = useRef();
  const {
    handleSearchToggle,
    handleTableToggle,
    handleExpandersToggle
  } = useLayoutActionDispatchers(FACTORING_INVOICES);
  const { tableVisible, expandersVisible, searchOpen } = useLayoutState(
    factoringInvoices
  );
  const { searchQuery, selectedRow } = useFactoringInvoicesState();
  const { filterLabels } = useFactoringInvoicesConsts();
  const { tabs } = useFactoringInvoicesConsts();
  const { getFilters } = useFactoringInvoicesUtils();
  return (
    <SectionMain toggleSearch={handleSearchToggle}>
      <SectionHeader
        sectionHeading={t("factoring.invoices.FactoringInvoice")}
        getFilters={getFilters}
        searchQuery={searchQuery}
        filterLabels={filterLabels}
        tableVisible={tableVisible}
        expandersVisible={expandersVisible}
        tableEnabled={true}
        expandersEnabled={true}
        tabsEnabled={false}
        onToggleTable={handleTableToggle}
        onToggleExpanders={handleExpandersToggle}
        onToggleSearch={handleSearchToggle}
        onSearch={payload =>
          loadData(
            payload,
            FACTORING_INVOICES,
            factoringInvoices,
            loadFactoringInvoiceData
          )
        }
        searchOpen={searchOpen}
      />
      <SectionBody
        TableComponent={FactoringInvoicesTable}
        ButtonsComponent={FactoringInvoicesButtons}
        shortcutsEnabled={false}
        selectedRow={selectedRow}
        tableVisible={tableVisible}
        selectedItem={
          !_.isEmpty(selectedRow) ? selectedRow.customer[0].name : ""
        }
        tableRef={tableRef}
        expandersEnabled={expandersVisible}
        tabs={tabs}
      />
    </SectionMain>
  );
};

export default FactoringInvoicesMainSection;
