import {useSelector} from "react-redux";

const useAppClassifications = () => {
  const customerInvoicesStatusHistoryTypes = useSelector(
    state => state.layout.classifications.customer_invoice_status_types
  );
  const countries = useSelector(
    state => state.layout.classifications.countries
  );

  const entrySourceTypes = useSelector(state => state.layout.classifications.entry_source_types);

  const cardTypes = useSelector(state => state.layout.classifications.card_types);
  const cardStatuses = useSelector(state => state.layout.classifications.card_status);
  const broker_claim_types = useSelector(state => state.layout.classifications.broker_claim_type);

  return { customerInvoicesStatusHistoryTypes, countries, cardTypes, cardStatuses, entrySourceTypes, broker_claim_types };
};

export default useAppClassifications;
