import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";
import CustomerDepositDetails from "../details/customerDepositDetails";
import {customerDeposit, details} from "../../../common/redux/types/selectorTypes";
import {CUSTOMER_DEPOSIT, DETAILS} from "../../../common/redux/types/viewTypes";

const useCustomerDepositConsts = () => {
  const { t } = useTranslation();
  const filterLabels = {
    customer: t("customer.customerView.FilterCustomerName"),
    id: t("customer.customerView.FilterCustomerId"),
    date_from: t("customerDeposit.DateFrom"),
    date_to: t("customerDeposit.DateTo"),
    add_to_invoice: t("customerDeposit.AddToInvoice"),
    invoice_no: t("customerDeposit.InvoiceNo"),
    customer_country: t("customerDeposit.FilterCustomerCountry"),
    count: t("customer.customerView.FilterResultCount")
  };

  const tabs = useMemo(
    () => [
      {
        label: t("customerDeposit.CustomerDepositAccountDetailData"),
        component: <CustomerDepositDetails data={[]} t={t} />,
        viewSelector: customerDeposit,
        tabSelector: details,
        viewType: CUSTOMER_DEPOSIT,
        tabType: DETAILS
      }
    ],
    [t]
  );

  return { filterLabels, tabs };
};

export default useCustomerDepositConsts;
