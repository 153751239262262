import React, { useCallback } from "react";
import Table from "../../common/table";
import useTabTableActionDispatchers from "../../common/redux/actions/table/useTabTableActionDispatchers";
import {
  CONTRACT,
  FACTORING_DEBTORS
} from "../../common/redux/types/viewTypes";
import useDataState from "../../common/redux/hooks/useDataState";
import {
  contract,
  factoringDebtors
} from "../../common/redux/types/selectorTypes";
import useTabState from "../../common/redux/hooks/useTabState";

const ContractFactoringDebtorsTable = ({ t }) => {
  const { selectedItem } = useTabState(contract, factoringDebtors);
  const { selectedItem: data } = useDataState(contract);
  const getColumns = useCallback(() => {
    const columns = [
      {
        id: "factoringDebtor",
        Header: t("factoring.debtor.Header"),
        accessor: d => (d.company ? d.company[0].name : ""),
        flexGrow: 1,
        width: 500
      }
    ];
    return columns;
  }, [t]);

  const { handleRowSelect } = useTabTableActionDispatchers(
    CONTRACT,
    FACTORING_DEBTORS
  );

  return (
    <Table
      data={data ? data.factoringContractDebtors : []}
      columns={getColumns()}
      height={200}
      selectedRow={selectedItem}
      onRowSelect={handleRowSelect}
    />
  );
};

export default ContractFactoringDebtorsTable;
