import React, { useState } from "react";
import { Formik } from "formik";
import {
  CheckboxInputField,
  DateInputField,
  FormBody,
  FormFlexWrapper,
  InputField,
  Label
} from "../../../../common/form/form";

const CustomerInvoiceGenerateInvoiceModalForm = ({ data, t }) => {
  const [invoiceModules, setInvoiceModules] = useState("rest");
  const handleRadioChange = e => {
    const value = e.target.value;
    setInvoiceModules(invoiceModules => value);
  };
  const CustomerInvoiceTransNs = "customerInvoice.";
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{}}
      onSubmit={(values, { setSubmitting }) => {
        console.log("SUBMIT");
      }}
    >
      {({
        values,
        errors,
        status,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        isSubmitting
      }) => (
        <FormBody>
          <FormFlexWrapper direction="column" classes="form__separator">
            {/*<CustomerAutocompleteInputField*/}
            {/*  type="text"*/}
            {/*  classes="form__group-stack"*/}
            {/*  id="customerInvoiceModalGenerateInvoiceCustomer"*/}
            {/*  name="customer"*/}
            {/*  value={values.customer}*/}
            {/*  handleChange={e => {*/}
            {/*    handleChange(e);*/}
            {/*  }}*/}
            {/*  label={t("vat.documents.FilterCustomer")}*/}
            {/*  autocomplete="nope"*/}
            {/*  data-lpignore="true"*/}
            {/*  actionType={CUSTOMER_INVOICES}*/}
            {/*  selectorType={customerInvoices}*/}
            {/*/>*/}
            <DateInputField
              id="customerInvoiceModalGenerateInvoiceDate"
              name="invoice_date"
              classes="form__group"
              value={values.invoice_date}
              label={t("customerInvoice.InvoiceDate")}
              handleChange={setFieldValue}
            />
          </FormFlexWrapper>
          <FormFlexWrapper classes="form__separator" direction="column">
            <h3 className="heading__tertiary">Lisa</h3>
            <DateInputField
              id="customerInvoiceModalGenerateInvoiceLaterDateToInvoice"
              name="invoice_later_date"
              classes="form__group"
              value={values.invoice_later_date}
              label={t("customerInvoice.LaterDateToInvoice")}
              handleChange={setFieldValue}
            />
            <DateInputField
              id="customerInvoiceModalGenerateInvoicePaymentDate"
              name="invoice_payment_date"
              classes="form__group"
              value={values.invoice_payment_date}
              label={t("customerInvoice.PaymentDate")}
              handleChange={setFieldValue}
            />
            <InputField
              id="customerInvoiceModalGenerateInvoiceDueDateExtraDays"
              name="dueDateExtraDays"
              label={t("customerInvoice.DueDateExtraDays")}
              value={values.dueDateExtraDays}
              onChange={handleChange}
              classes="form__group"
            />
            <InputField
              id="customerInvoiceModalGenerateInvoiceInvoiceNo"
              name="invoiceNo"
              label={t("customerInvoice.InvoiceNo")}
              value={values.invoiceNo}
              classes="form__group"
            />
            <CheckboxInputField
              id="customerInvoiceModalGenerateInvoiceAddNews"
              label={t("customerInvoice.AddNews")}
              classes={"form__group"}
            />
            <CheckboxInputField
              id="customerInvoiceModalGenerateInvoiceIsFactoringAndService"
              label={t("customerInvoice.IsFactoringAndServiceBill")}
              classes={"form__group"}
            />
          </FormFlexWrapper>

          <FormFlexWrapper classes="form__separator">
            <div className="form__group">
              <Label htmlFor="customerInvoiceModalGenerateInvoiceOnlyDeclaredInvoices">
                Ainult deklareeritavad arved
              </Label>
              <input
                type="radio"
                onChange={handleRadioChange}
                name="invoiceTypeCheck"
                value="declared"
                checked={invoiceModules === "declared"}
                className="u-margin-top-tiny"
                id="customerInvoiceModalGenerateInvoiceOnlyDeclaredInvoices"
              />
            </div>
            <div className="form__group">
              <Label htmlFor="customerInvoiceModalGenerateInvoiceRestOfInvoices">
                Ülejäänud arved
              </Label>
              <input
                type="radio"
                name="invoiceTypeCheck"
                onChange={handleRadioChange}
                value="rest"
                checked={invoiceModules === "rest"}
                className="u-margin-top-tiny"
                id="customerInvoiceModalGenerateInvoiceRestOfInvoices"
              />
            </div>
          </FormFlexWrapper>

          <h3 className="heading__tertiary u-margin-bottom-tiny">
            Arvele minevad moodulid
          </h3>
          {invoiceModules === "rest" ? (
            <FormFlexWrapper direction="column">
              <FormFlexWrapper>
                <CheckboxInputField
                  id="customerInvoiceModalGenerateInvoiceIsFactoringAndService"
                  label={t(
                    "customerInvoice.InvoiceFactoringAndRelatedServices"
                  )}
                  classes={"form__group"}
                />
                <CheckboxInputField
                  id="customerInvoiceModalGenerateInvoiceAllServiceFees"
                  label={t("customerInvoice.AllServiceFees")}
                  classes={"form__group"}
                />
              </FormFlexWrapper>
              <FormFlexWrapper>
                <CheckboxInputField
                  id="customerInvoiceModalGenerateInvoiceBrokerClaims"
                  label={t("customerInvoice.BrokerClaims")}
                  classes={"form__group"}
                />
                <CheckboxInputField
                  id="customerInvoiceModalGenerateInvoiceOverdueInvoices"
                  label={t("customerInvoice.OverdueInvoices")}
                  classes={"form__group"}
                />
              </FormFlexWrapper>
            </FormFlexWrapper>
          ) : (
            <FormFlexWrapper direction="column">
              <CheckboxInputField
                id="customerInvoiceModalGenerateInvoiceIsFactoringAndService"
                label={t("customerInvoice.InvoiceFactoringAndRelatedServices")}
                classes={"form__group"}
              />
              <CheckboxInputField
                id="customerInvoiceModalGenerateInvoiceAllServiceFees"
                label={t("customerInvoice.AllServiceFees")}
                classes={"form__group"}
              />
            </FormFlexWrapper>
          )}
        </FormBody>
      )}
    </Formik>
  );
};

export default CustomerInvoiceGenerateInvoiceModalForm;
