import React, { useRef } from "react";
import {
  SectionBody,
  SectionHeader,
  SectionMain
} from "../../../../common/section";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import useLayoutActionDispatchers from "../../../../common/redux/actions/layout/hooks/useLayoutActionDispatchers";
import useFactoringDebtorAccountUtils from "../../hooks/useFactoringDebtorAccountUtils";
import useFactoringDebtorAccountConsts from "../../hooks/useFactoringDebtorAccountConsts";
import useLayoutState from "../../../../common/redux/hooks/useLayoutState";
import FactoringDebtorAccountTable from "../../factoringDebtorAccountTable";
import FactoringDebtorAccountButtons from "./factoringDebtorAccountButtons";
import { FACTORING_DEBTOR_ACCOUNT } from "../../../../common/redux/types/viewTypes";
import useDataState from "../../../../common/redux/hooks/useDataState";
import { factoringDebtorAccount } from "../../../../common/redux/types/selectorTypes";
import useSearchState from "../../../../common/redux/hooks/useSearchState";

const FactoringDebtorAccountMainSection = () => {
  const { t } = useTranslation();
  const tableRef = useRef();
  const {
    handleSearchToggle,
    handleExpandersToggle,
    handleTableToggle
  } = useLayoutActionDispatchers(FACTORING_DEBTOR_ACCOUNT);
  const { tableVisible, expandersVisible, searchOpen } = useLayoutState(
    "factoringDebtorAccount"
  );
  const { searchQuery } = useSearchState(factoringDebtorAccount);
  const { selectedItem } = useDataState(factoringDebtorAccount);
  const { getFactoringDebtorAccountFilters } = useFactoringDebtorAccountUtils();
  const { filterLabels, tabs } = useFactoringDebtorAccountConsts();

  return (
    <SectionMain toggleSearch={handleSearchToggle}>
      <SectionHeader
        sectionHeading={t("customerAccount.FactoringDebtorAccountHeader")}
        getFilters={getFactoringDebtorAccountFilters}
        searchQuery={searchQuery}
        filterLabels={filterLabels}
        tableVisible={tableVisible}
        expandersVisible={expandersVisible}
        tableEnabled={true}
        expandersEnabled={true}
        tabsEnabled={false}
        onToggleTable={handleTableToggle}
        onToggleExpanders={handleExpandersToggle}
        onToggleSearch={handleSearchToggle}
        searchOpen={searchOpen}
      />

      <SectionBody
        TableComponent={FactoringDebtorAccountTable}
        ButtonsComponent={FactoringDebtorAccountButtons}
        shortcutsEnabled={false}
        selectedRow={selectedItem}
        tableVisible={tableVisible}
        selectedItem={
          !_.isEmpty(selectedItem) ? selectedItem.customer[0].name : ""
        }
        tableRef={tableRef}
        expandersEnabled={expandersVisible}
        tabs={tabs}
      />
    </SectionMain>
  );
};

export default FactoringDebtorAccountMainSection;
