import React, { useCallback, useMemo } from "react";
import { Loading } from "../../common/loading";
import Table from "../../common/table";
import { useTranslation } from "react-i18next";
import { TableResultData } from "../../common/tableFooter";
import useTableLayoutActionDispatchers from "../../common/redux/actions/layout/table/hooks/useTableLayoutActionDispatchers";
import { FACTORING_DEBTOR_OVERDUE_INVOICES } from "../../common/redux/types/viewTypes";
import useTableActionDispatchers from "../../common/redux/actions/table/hooks/useTableActionDispatchers";
import useDataState from "../../common/redux/hooks/useDataState";
import { factoringDebtorOverdueInvoices } from "../../common/redux/types/selectorTypes";
import useDataActionsDispatchers from "../../common/redux/actions/data/hooks/useDataActionsDispatchers";
import useTableState from "../../common/redux/hooks/useTableState";

const FactoringDebtorOverdueInvoicesTable = ({
  removeItem,
  onRowSelect,
  onToggleRow,
  tableRef,
  tableVisible
}) => {
  const { t } = useTranslation();
  const { height } = useTableState(factoringDebtorOverdueInvoices);
  const { items, selectedItem, selectedRows, isLoading } = useDataState(
    factoringDebtorOverdueInvoices
  );

  const { handleTableHeightSet } = useTableLayoutActionDispatchers(
    FACTORING_DEBTOR_OVERDUE_INVOICES
  );
  const { handleRowSelect } = useTableActionDispatchers(
    FACTORING_DEBTOR_OVERDUE_INVOICES
  );
  const { selectedRowsState } = useTableState(factoringDebtorOverdueInvoices);
  const onSetSelectedRowsState = useCallback(payload => {}, []);

  const { handleSetSelectedRowsState } = useDataActionsDispatchers(
    FACTORING_DEBTOR_OVERDUE_INVOICES
  );

  const TranslationNS = "factoring.debtor.overdueInvoices.";

  const columns = useMemo(
    () => [
      {
        Header: t(TranslationNS + "FilterCustomer"),
        width: 100,
        accessor: d => d.customerName || ""
      },

      {
        Header: t(TranslationNS + "FilterInvoiceNo"),
        accessor: "invoiceNo"
      },
      {
        id: "invoiceDate",
        Header: t(TranslationNS + "PenaltyInvoiceDate"),
        width: 85,
        accessor: d => d.invoiceDate || ""
      },
      {
        id: "dueDate",
        Header: t(TranslationNS + "DueDate"),
        accessor: d => d.dueDate || "",
        width: 80
      },

      {
        Header: t(TranslationNS + "TotalPenalties"),
        width: 80,
        accessor: "portOneContractsCount"
      },
      {
        id: "otherContractsCount",
        Header: t(TranslationNS + "FilterIssuedForInvoiceNo"),
        width: 80
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, onSetSelectedRowsState]
  );

  return (
    <React.Fragment>
      <Table
        columns={columns}
        data={items}
        height={height}
        setTableHeight={handleTableHeightSet}
        setUserTableHeight={handleTableHeightSet}
        isLoading={isLoading}
        loadingComponent={Loading}
        onRowSelect={handleRowSelect}
        selectedRow={selectedItem}
        selectedRows={selectedRows}
        selectedRowsState={selectedRowsState}
        setSelectedRowsState={handleSetSelectedRowsState}
        onToggleRow={onToggleRow}
        tableRef={tableRef}
      />
      <TableResultData
        count={items.length}
        enabled={false}
        selectedRows={selectedRows}
        onRowSelect={onRowSelect}
        selectedRow={selectedItem}
        onRemoveItem={removeItem}
        tableRef={tableRef}
      />
    </React.Fragment>
  );
};

export default FactoringDebtorOverdueInvoicesTable;
