import {authorizationHeaders} from "../../../../services/queries";
import axios from "axios";
import {
  LOAD_COMPANY_AUTOCOMPLETE_ERROR,
  LOAD_COMPANY_AUTOCOMPLETE_START,
  LOAD_COMPANY_AUTOCOMPLETE_SUCCESS
} from "../../redux/actions/search/searchActions";

export const loadCompanyAutocompleteData = async payload => {
  const data = await axios.post(
    "/graphql",
    {
      query: `
        query {
          companies(name:"${payload}" type:CUSTOMER) {
            name
          }
         }
      `
    },
    authorizationHeaders()
  );
  return data.data.data.companies;
};

export const companyAutocompleteQuery = (name, payload) => {
  return dispatch => {
    dispatch(LOAD_COMPANY_AUTOCOMPLETE_START(name));
    loadCompanyAutocompleteData(payload)
      .then(data => dispatch(LOAD_COMPANY_AUTOCOMPLETE_SUCCESS(name, data)))
      .catch(error => dispatch(LOAD_COMPANY_AUTOCOMPLETE_ERROR(name, error)));
  };
};
