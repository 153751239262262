import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { contractQuery } from "../graphql/contractActions";

const useContractActions = () => {
  const dispatch = useDispatch();

  const handleSearch = useCallback(
    payload => {
      dispatch(contractQuery(payload));
    },
    [dispatch]
  );

  return {
    handleSearch
  };
};

export default useContractActions;
