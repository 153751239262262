import React from "react";
import _ from "lodash";
import { Button } from "../../../../common/buttons/button";
import useFactoringInvoicesState from "../../hooks/useFactoringInvoicesState";
import { useTranslation } from "react-i18next";

const FactoringInvoicesButtons = () => {
  const { t } = useTranslation();
  const { selectedRow } = useFactoringInvoicesState();
  return (
    <React.Fragment>
      <Button
        btnStyle="primary"
        size="small"
        handleClick={() => {
          alert("ADD");
        }}
        disabled={true}
        label={t("customer.customerView.BtnAddCustomer")}
        classes="btn--footer"
      />
      <Button
        btnStyle="danger"
        size="small"
        label={t("customer.customerView.BtnDeleteCustomer")}
        handleClick={() => {
          alert("DELETE");
        }}
        disabled={true}
        classes={"btn--footer"}
      />
      <Button
        btnStyle="green"
        size="small"
        label={t("customer.customerView.BtnSaveCustomer")}
        handleClick={() => alert("SAVE")}
        disabled={true}
        classes="btn--footer"
      />

      <Button
        btnStyle="lime"
        size="small"
        label={t("customer.customerView.BtnCustomersToCsv")}
        handleClick={() => alert("CSV")}
        disabled={true}
        classes={"btn--footer"}
      />
      <Button
        btnStyle="lime"
        size="small"
        label={t("customer.customerView.BtnSendToPrinter")}
        handleClick={() => alert("PRINT ENVELOPE")}
        disabled={true}
        classes="btn--footer"
      />
    </React.Fragment>
  );
};

export default FactoringInvoicesButtons;
