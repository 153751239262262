/* eslint-disable no-unused-vars */
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import {
  useFlexLayout,
  useResizeColumns,
  useRowSelect,
  useSortBy,
  useTable
} from "react-table";
import { Resizable } from "re-resizable";
import { FixedSizeList } from "react-window";
import { PageLoading } from "./loading";
import SimpleBar from "simplebar-react";
import { cellProps, IndeterminateCheckbox } from "./tableComponents";

const Table = ({
  columns,
  data,
  onRowSelect,
  selectedRow,
  height,
  isLoading,
  setTableHeight,
  setSelectedRowsState,
  selectedRowsState
}) => {
  const tableRef = useRef();
  const [tableSize, setTableSize] = useState({
    width: "100%",
    height: "auto"
  });
  const { t } = useTranslation();
  const simplebarScrollRef = useRef();
  const headerRef = useRef();
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    totalColumnsWidth,
    selectedFlatRows,
    state: { selectedRowPaths }
  } = useTable(
    {
      columns,
      data
    },
    useResizeColumns,
    useFlexLayout,
    useSortBy,
    useRowSelect,
    hooks => {
      hooks.flatColumns.push(columns => [
        {
          id: "selection",
          disableResizing: true,
          minWidth: 30,
          width: 30,
          maxWidth: 30,
          flexGrow: 0,
          headerAlign: "center",
          cellAlign: "center",
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          )
        },
        ...columns
      ]);
      hooks.useInstanceBeforeDimensions.push(({ headerGroups }) => {
        const selectionGroupHeader = headerGroups[0].headers[0];
        selectionGroupHeader.canResize = false;
      });
    }
  );

  useEffect(() => {
    if (selectedFlatRows.length && selectedRowsState !== selectedFlatRows) {
      // setSelectedRowsState(selectedFlatRows);
    }
  }, [selectedFlatRows]);

  useEffect(() => {
    if (
      rows.length &&
      !_.isEmpty(selectedRow) &&
      tableRef &&
      simplebarScrollRef
    ) {
      simplebarScrollRef.current.scrollTop = selectedRow.scrollY;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const RenderRow = React.useCallback(
    ({ index, style }) => {
      const row = rows[index];
      prepareRow(row);
      return (
        <div
          style={{
            ...style,
            minWidth: totalColumnsWidth,
            backgroundColor: row.original.color || "",
            color: row.original.color ? "white" : ""
          }}
          className="rt-tr-group"
          onClick={() =>
            onRowSelect(
              Object.assign(
                { ...row.original },
                {
                  index: row.index,
                  scrollY: simplebarScrollRef.current.scrollTop
                }
              )
            )
          }
          id={row.original.id === selectedRow.id ? "selected" : ""}
        >
          <div className="rt-tr table__row">
            {row.cells.map(cell => {
              return (
                <div {...cell.getCellProps(cellProps)} className="rt-td">
                  <div className="table__cell">{cell.render("Cell")}</div>
                </div>
              );
            })}
          </div>
        </div>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [prepareRow, rows, totalColumnsWidth, selectedRow]
  );

  const handleScrollY = useCallback(() => {
    headerRef.current.scrollLeft = simplebarScrollRef.current.scrollLeft;
    tableRef.current.scrollTo(simplebarScrollRef.current.scrollTop);
  }, [tableRef]);

  const SimplebarYScrollWrapper = useMemo(
    () =>
      forwardRef(({ style, onScroll, children }, ref) => {
        return (
          <SimpleBar
            forceVisible="y"
            autoHide={true}
            style={{
              ...style,
              width: "100%",
              height: "100%",
              overflowX: "auto",
              zIndex: "100000",
              scrollbarWidth: "none"
            }}
            onScroll={handleScrollY}
            scrollableNodeProps={{ ref: simplebarScrollRef }}
          >
            <div ref={ref} style={{ width: style.width }}>
              {children}
            </div>
          </SimpleBar>
        );
      }),
    [handleScrollY]
  );

  const innerElementType = useMemo(
    () => forwardRef((props, ref) => <div ref={ref} {...props} />),
    []
  );

  return (
    <div className="table__wrapper" id="ReactTable">
      <div className="table__placeholders">
        <span
          className="table__placeholders-box"
          style={{
            display: !rows.length && !isLoading ? "inline-block" : "none"
          }}
        >
          {t("layout.NoData")}
        </span>
        <span
          className="table__placeholders-box table__placeholders-loading"
          style={{
            display: isLoading ? "inline-block" : "none",
            border: "none"
          }}
        >
          <PageLoading />
        </span>
      </div>
      <Resizable
        size={{ width: tableSize.width, height: height + 2 }}
        enable={{ top: false, right: false, left: false, bottom: true }}
        onResizeStop={(e, direction, ref, d) => {
          setTableHeight(height + d.height);
        }}
      >
        <div
          className="ReactTable"
          style={{
            filter: isLoading ? "blur(5px)" : "",
            transition: "all .2s ease"
          }}
        >
          <div
            {...getTableProps()}
            className="rt-table"
            style={{
              height: height
            }}
          >
            <div
              ref={headerRef}
              onScroll={() => handleScrollY()}
              className="rt-thead -header"
              style={{
                display: "flex",
                flexGrow: 1,
                width: "100%",
                overflow: "auto",
                scrollbarWidth: "none"
              }}
            >
              {headerGroups.map(headerGroup => (
                <div
                  {...headerGroup.getHeaderGroupProps()}
                  style={{ minWidth: totalColumnsWidth }}
                  className="rt-tr"
                >
                  {headerGroup.headers.map(column => (
                    <div
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className="rt-th rt-resizable-header"
                    >
                      <div className="rt-resizable-header-content">
                        {column.render("Header")}
                        <span>
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <div
                                style={{
                                  display: "inline-block",
                                  marginLeft: "0.2rem"
                                }}
                              >
                                &#8595;
                              </div>
                            ) : (
                              <div
                                style={{
                                  display: "inline-block",
                                  marginLeft: "0.2rem"
                                }}
                              >
                                &#8593;
                              </div>
                            )
                          ) : (
                            ""
                          )}
                        </span>
                      </div>
                      {column.canResize && (
                        <div
                          {...column.getResizerProps()}
                          onClick={e => e.stopPropagation()}
                          className="rt-resizer"
                        />
                      )}
                    </div>
                  ))}
                </div>
              ))}
            </div>

            <div
              {...getTableBodyProps()}
              className="rt-tbody"
              style={{ overflowX: "hidden" }}
            >
              <FixedSizeList
                height={height - 25}
                itemCount={rows.length}
                itemSize={33}
                width={totalColumnsWidth}
                ref={tableRef}
                innerElementType={innerElementType}
                outerElementType={SimplebarYScrollWrapper}
              >
                {RenderRow}
              </FixedSizeList>
            </div>
          </div>
        </div>
      </Resizable>
    </div>
  );
};

export default Table;
