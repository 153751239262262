export const useCustomerAccountFilters = (payload, t) => {
  const filters = {};
  Object.assign(filters, payload);

  if (payload.customer_country) {
    Object.assign(filters, {
      customer_country: t(`countriesIdx.${payload.customer_country}`)
    });
  }
  if (payload.issued_country) {
    Object.assign(filters, {
      issued_country: t(`countries.${payload.issued_country}`)
    });
  }

  return filters;
};
