import axios from "axios";
import { extractQueryParameters } from "../../../../../services/query";
import { authorizationHeaders } from "../../../../../services/queries";
import { toast } from "react-toastify";
import {
  LOAD_VAT_APPLICATIONS_DATA_ERROR,
  LOAD_VAT_APPLICATIONS_DATA_START,
  LOAD_VAT_APPLICATIONS_DATA_SUCCESS,
  SUBMIT_VAT_APPLICATIONS_QUERY
} from "./vatApplicationsQueryActions";

export const vatApplicationsSearchQuery = async payload => {
  try {
    const queryParameters = extractQueryParameters(payload);
    console.log(queryParameters, "QUERY PARAMS");
    const data = await axios.post(
      "/graphql",
      {
        query: `query {
            vatApplications(${queryParameters}) {
                id
                isActiveCl
                bankAccountTypeCl
                commonFeeInPercent
                commonFeeExternalInPercent
                isAutomaticClearingAllowed
                iban
                isSentCl
                 customer{name}
                applicationNo
              applicationDate
              statusCl
              bankId
              periodStart
              periodEnd
              country{code}
              serviceProvider{name}
              applicationPayoutSum{payout_sum payout_sum_eur}
              applicationUnconfirmedIncome{income_in_doc_currency_sum income_in_eur_sum}
              applicationIncome{income_in_doc_currency_sum income_in_eur_sum}
              lastIncomeDate
              vatApplicationCurrency
              additionalInfo
            }
           }`
      },
      authorizationHeaders()
    );
    console.log(data, "DATA");

    return data.data.data.vatApplications;
  } catch (err) {
    toast.error(err.message);
    throw new Error("Error occured during loading of VAT applications");
  }
};

export const getVatApplicationsData = payload => {
  return dispatch => {
    dispatch(SUBMIT_VAT_APPLICATIONS_QUERY(payload));
    dispatch(LOAD_VAT_APPLICATIONS_DATA_START());
    vatApplicationsSearchQuery(payload)
      .then(d => dispatch(LOAD_VAT_APPLICATIONS_DATA_SUCCESS(d)))
      .catch(err => dispatch(LOAD_VAT_APPLICATIONS_DATA_ERROR(err)));
  };
};
