import React, { forwardRef } from "react";
import { Formik } from "formik";
import {
  BoolSelectInputField,
  DateInputField,
  InputField,
  SelectInputField
} from "../../../../common/form/form";
import { useTranslation } from "react-i18next";
import useCustomerDepositActions from "../../hooks/useCustomerDepositActions";
import { customerDeposit } from "../../../../common/redux/types/selectorTypes";
import useSearchState from "../../../../common/redux/hooks/useSearchState";
import AutoCompleteField from "../../../../common/autocomplete/autocompleteField";
import { loadCompanyAutocompleteData } from "../../../../common/autocomplete/graphql/fetchCustomerAutocomplete";

const CustomerDepositVerticalSearchFrom = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const { selectedAutocompleteValue } = useSearchState(customerDeposit);

  const { handleSearch } = useCustomerDepositActions();

  return (
    <Formik
      ref={ref}
      enableReinitialize={true}
      initialValues={{
        count: 1000,
        customer: selectedAutocompleteValue || "",
        date_from: "",
        date_to: "",
        add_to_invoice: "",
        invoice_no: "",
        customer_country: "",
        salesAgent: ""
      }}
      onSubmit={(values, { setSubmitting }) => {
        handleSearch(values);
      }}
    >
      {({
        values,
        handleChange,
        handleSubmit,
        validateField,
        validateOnChange,
        errors,
        setFieldValue,
        touched
      }) => (
        <form onSubmit={handleSubmit}>
          <AutoCompleteField
            type="text"
            classes="form__group-stack"
            id="customerDepositSearchCustomer"
            name="customer"
            value={values.customer}
            handleChange={e => {
              setFieldValue(e);
            }}
            label={t("customerAccount.details.Client")}
            data-lpignore="true"
            autocomplete="nope"
            fetchAutcompleteData={loadCompanyAutocompleteData}
          />
          <DateInputField
            type="date"
            handleChange={setFieldValue}
            id="customerDepoSearchDateFrom"
            name="date_from"
            classes="form__group-stack"
            value={values.date_from}
            label={t("customerDeposit.DateFrom")}
          />
          <DateInputField
            type="date"
            handleChange={setFieldValue}
            id="customerDepoSearchDateTo"
            name="date_to"
            value={values.date_to}
            classes="form__group-stack"
            label={t("customerDeposit.DateTo")}
          />
          <BoolSelectInputField
            id="customerDepoSearchAddToInvoice"
            name="add_to_invoice"
            label={t("customerDeposit.BtnAddToInvoice")}
            classes="form__group-stack"
            value={values.addToInvoice}
            onChange={handleChange}
          />
          <InputField
            type="text"
            id="customerDepoSearchInvoiceNo"
            name="invoice_no"
            label={t("customerDeposit.InvoiceNo")}
            classes="form__group-stack"
            onChange={handleChange}
            value={values.invoiceNo}
          />
          <SelectInputField
            id="customerDepoSearchCustomerCountry"
            name="customer_country"
            label={t("customerDeposit.FilterCustomerCountry")}
            classes="form__group-stack"
            onChange={handleChange}
            value={values.customerCountry}
            options={[
              { name: "", value: "" },
              { name: t("common.ReplyYes"), value: "true" },
              { name: t("common.ReplyNo"), value: "false" }
            ]}
          />
          <SelectInputField
            id="customerDepoSearchSalesagent"
            name="salesAgent"
            label={t("customerDeposit.SalesAgent")}
            classes="form__group-stack"
            value={values.salesAgent}
            onChange={handleChange}
            options={[
              { name: "", value: "" },
              { name: t("common.ReplyYes"), value: "BOOLEAN.YES" },
              { name: t("common.ReplyNo"), value: "BOOLEAN.NO" }
            ]}
          />
          <InputField
            type="text"
            name="count"
            id="customerDepoSearchResultCount"
            label={t("customerDeposit.ResultsCount")}
            classes="form__group-stack"
            onChange={handleChange}
            value={values.count}
          />
        </form>
      )}
    </Formik>
  );
});

export default CustomerDepositVerticalSearchFrom;
