import { useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  TOGGLE_PARTNER_EXPANDER_VIEW,
  TOGGLE_PARTNER_SEARCH,
  TOGGLE_PARTNER_TABLE
} from "../../actions/partnerActions";

const usePartnerLayoutActions = () => {
  const dispatch = useDispatch();
  const handleTableToggle = useCallback(() => {
    dispatch(TOGGLE_PARTNER_TABLE());
  }, [dispatch]);

  const handleSearchToggle = useCallback(() => {
    dispatch(TOGGLE_PARTNER_SEARCH());
  }, [dispatch]);

  const handleExpandersToggle = useCallback(() => {
    dispatch(TOGGLE_PARTNER_EXPANDER_VIEW());
  }, [dispatch]);

  return { handleTableToggle, handleSearchToggle, handleExpandersToggle };
};

export default usePartnerLayoutActions;
