import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import FactoringDebtorDetailsForm from "../details/forms/factoringDebtorDetailsForm";
import FactoringDebtorCommentsTable from "../comments/factoringDebtorCommentsTable";
import FactoringDebtorContactPersonsForm from "../contact_persons/factoringDebtorContactPersonsForm";
import FactoringDebtorAddressesForm from "../addresses/factoringDebtorAddressesForm";
import FactoringDebtorClientInvoiceSettingsTable from "../client_invoice_settings/factoringDebtorClientInvoiceSettingsTable";
import FactoringDebtorCreditLimitHistoryTable from "../credit_limit_history/factoringDebtorCreditLimitHistoryTable";
import {
  addresses,
  clientAndInvoiceSettings,
  comments,
  contactInformation,
  creditLimitHistory,
  details,
  factoringDebtor
} from "../../../common/redux/types/selectorTypes";
import {
  ADDRESSES,
  CLIENT_AND_INVOICE_SETTINGS,
  COMMENTS,
  CONTACT_INFORMATION,
  CREDIT_LIMIT_HISTORY,
  DETAILS,
  FACTORING_DEBTOR
} from "../../../common/redux/types/viewTypes";
import useDataState from "../../../common/redux/hooks/useDataState";
import CommentsTab from "../../../common/tabComponents/comments/commentsTab";

const useFactoringDebtorConsts = () => {
  const { t } = useTranslation();
  const { selectedItem } = useDataState(factoringDebtor);

  const filterLabels = {
    name: t("customer.customerView.FilterCustomerName"),
    id: t("customer.customerView.FilterCustomerId"),
    woCusManager: t("customer.customerView.WithoutCustomerManager"),
    regNo: t("customer.customerView.FilterRegNo"),
    vatNo: t("customer.customerView.VatNo"),
    contactPerson: t("customer.customerView.FilterContactPerson"),
    resultCount: t("customer.customerView.FilterResultCount"),
    generalPhone: t("customer.customerView.FilterGeneralPhone"),
    generalEmail: t("customer.customerView.FilterGeneralOrInvoiceEmail"),
    salesAgent: t("customer.customerView.SalesAgent"),
    customerManager: t("customer.customerView.CustomerManager"),
    withoutCustomerManager: t("customer.customerView.WithoutCustomerManager"),
    isActive: t("customer.customerView.FilterIsActive"),
    validFrom: t("customer.customerView.FilterCustomerValidFrom"),
    portOneContractsCount: t(
      "customer.customerView.SearchResultColumnNumberOfP1Contracts"
    ),
    otherContractsCount: t(
      "customer.customerView.SearchResultColumnNumberOfOtherContracts"
    )
  };

  const tabs = useMemo(
    () => [
      {
        label: t("factoring.debtor.FactoringDebtorDetails"),
        component: <FactoringDebtorDetailsForm t={t} data={selectedItem} />,
        viewSelector: factoringDebtor,
        viewType: FACTORING_DEBTOR,
        tabSelector: details,
        tabType: DETAILS
      },
      {
        label: t("factoring.debtor.FactoringDebtorComments"),
        component: (
          <CommentsTab
            data={selectedItem}
            tabSelector={comments}
            viewSelector={factoringDebtor}
            viewType={FACTORING_DEBTOR}
            tabType={COMMENTS}
          />
        ),
        viewSelector: factoringDebtor,
        viewType: FACTORING_DEBTOR,
        tabSelector: comments,
        tabType: COMMENTS
      },
      {
        label: t("factoring.debtor.FactoringDebtorContactPersons"),
        component: <FactoringDebtorContactPersonsForm />,
        viewSelector: factoringDebtor,
        viewType: FACTORING_DEBTOR,
        tabSelector: contactInformation,
        tabType: CONTACT_INFORMATION
      },
      {
        label: t("factoring.debtor.FactoringDebtorAddresses"),
        component: <FactoringDebtorAddressesForm />,
        viewSelector: factoringDebtor,
        viewType: FACTORING_DEBTOR,
        tabSelector: addresses,
        tabType: ADDRESSES
      },
      {
        label: t("factoring.debtor.FactoringDebtorCustomerAndInvoiceSettings"),
        component: <FactoringDebtorClientInvoiceSettingsTable />,
        viewSelector: factoringDebtor,
        viewType: FACTORING_DEBTOR,
        tabSelector: clientAndInvoiceSettings,
        tabType: CLIENT_AND_INVOICE_SETTINGS
      },
      {
        label: t("factoring.debtor.FactoringDebtorCreditLimitHistory"),
        component: <FactoringDebtorCreditLimitHistoryTable />,
        viewSelector: factoringDebtor,
        viewType: FACTORING_DEBTOR,
        tabSelector: creditLimitHistory,
        tabType: CREDIT_LIMIT_HISTORY
      }
    ],
    [t, selectedItem]
  );

  return { filterLabels, tabs };
};

export default useFactoringDebtorConsts;
