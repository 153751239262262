import React, { forwardRef } from "react";
import { Formik } from "formik";
import {
  DateInputField,
  InputField,
  SelectInputField
} from "../../../common/form/form";
import useServiceState from "../../hooks/useServiceState";
import { loadCompanyAutocompleteData } from "../../../common/autocomplete/graphql/fetchCustomerAutocomplete";
import AutoCompleteField from "../../../common/autocomplete/autocompleteField";

const ServiceSearchVerticalForm = forwardRef(
  ({ t, query, handleSearch }, ref) => {
    const { countriesDropdownData, serviceTypeNames } = useServiceState();

    const serviceTypeNamesData = serviceTypeNames.map(item => {
      return Object.assign({}, { name: item.name, value: item.serviceTypeId });
    });

    return (
      <Formik
        ref={ref}
        enableReinitialize={true}
        initialValues={{
          count: query.count || 1000,
          customer: query.customer || "",
          add_to_invoice: query.add_to_invoice ? query.add_to_invoice : "",
          service_type: query.service_type || "",
          invoice_no: query.invoice_no || "",
          services_from: query.services_from || "",
          services_to: query.services_to || "",
          is_private: query.is_private ? query.is_private : "",
          created_from: query.created_from || "",
          country: query.country || "",
          partner: query.partner || "",
          without_customer_invoice: query.without_customer_invoice
            ? query.withoutCustomerInvoice
            : "",
          customer_country: query.customer_country || "",
          sales_agent: query.sales_agent || ""
        }}
        onSubmit={(values, { setSubmitting }) => {
          handleSearch(values);
        }}
      >
        {({
          values,
          errors,
          status,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue
        }) => (
          <form onSubmit={handleSubmit}>
            <AutoCompleteField
              type="text"
              classes="form__group"
              id="serviceSearchCustomer"
              name="customer"
              value={values.customer}
              handleChange={setFieldValue}
              label={t("customer.customerView.FilterCustomerName")}
              autocomplete="password"
              data-lpignore="true"
              fetchAutcompleteData={loadCompanyAutocompleteData}
            />
            <SelectInputField
              classes="form__group-stack"
              options={[
                { name: "", value: "" },
                { name: t("common.ReplyYes"), value: "true" },
                { name: t("common.ReplyNo"), value: "false" }
              ]}
              id="serviceSearchAddToInvoice"
              name="add_to_invoice"
              label={t("service.FilterAddToInvoice")}
              value={values.add_to_invoice}
              onChange={handleChange}
            />
            <SelectInputField
              classes="form__group-stack"
              options={[{ name: "", value: "" }, ...serviceTypeNamesData]}
              id="serviceSearchServiceType"
              name="service_type"
              label={t("service.FilterServiceType")}
              value={values.service_type}
              onChange={handleChange}
            />
            <InputField
              type="text"
              id="serviceSearchInvoiceNo"
              name="invoice_no"
              onChange={handleChange}
              value={values.invoice_no}
              classes="form__group-stack"
              label={t("service.FilterInvoiceNo")}
            />
            <DateInputField
              type="date"
              id="serviceSearchServicesFrom"
              name="services_from"
              value={values.services_from}
              handleChange={setFieldValue}
              classes="form__group-stack"
              label={t("service.FilterServicesFrom")}
            />
            <DateInputField
              type="date"
              id="serviceSearchServicesTo"
              name="services_to"
              value={values.services_to}
              handleChange={setFieldValue}
              classes="form__group-stack"
              label={t("service.FilterServicesTo")}
            />
            <SelectInputField
              classes="form__group-stack"
              options={[
                { name: "", value: "" },
                { name: t("BOOLEAN.YES"), value: "BOOLEAN.YES" },
                { name: t("BOOLEAN.NO"), value: "BOOLEAN.NO" }
              ]}
              id="serviceSearchIsPrivate"
              name="is_private"
              label={t("service.FilterIsPrivate")}
              value={values.is_private}
              onChange={handleChange}
            />
            <DateInputField
              type="date"
              id="serviceSearchCreatedFrom"
              name="created_from"
              value={values.created_from}
              handleChange={setFieldValue}
              classes="form__group-stack"
              label={t("service.FilterCreatedFrom")}
            />
            <SelectInputField
              classes="form__group-stack"
              options={[
                { name: "", value: "" },
                ...countriesDropdownData.map(item => {
                  return Object.assign(
                    {},
                    {
                      name: t(`countries.${item.code}`),
                      value: item.code
                    }
                  );
                })
              ]}
              id="serviceSearchCountry"
              name="country"
              label={t("service.FilterCountry")}
              value={values.country}
              onChange={handleChange}
            />
            <SelectInputField
              classes="form__group-stack"
              options={[
                { name: "", value: "" },
                { name: "Port One", value: "p1" }
              ]}
              id="serviceSearchPartner"
              label={t("service.FilterPartner")}
              value={values.partner}
              onChange={handleChange}
            />
            <SelectInputField
              classes="form__group-stack"
              label={t("service.FilterWithoutCustomerInvoice")}
              id="serviceSearchWithoutCustomerInvoice"
              options={[
                { name: "", value: "" },
                { name: t("common.ReplyYes"), value: "true" },
                { name: t("common.ReplyNo"), value: "false" }
              ]}
              onChange={handleChange}
              value={values.without_customer_invoice}
            />
            <SelectInputField
              classes="form__group-stack"
              options={
                countriesDropdownData.length
                  ? [
                      { name: "", value: "" },
                      ...countriesDropdownData.map(item => {
                        return Object.assign(
                          {},
                          {
                            name: t(`countries.${item.code}`),
                            value: item.code
                          }
                        );
                      })
                    ]
                  : [{ name: "", value: "" }]
              }
              id="serviceSearchCustomerCountry"
              name="customer_country"
              label={t("service.FilterCustomerCountry")}
              value={values.customer_country}
              onChange={handleChange}
            />
            <SelectInputField
              classes="form__group-stack"
              options={[{ name: "", value: "" }]}
              id="serviceSearchSalesAgent"
              name="sales_agent"
              label={t("service.SalesAgent")}
              value={values.sales_agent}
              onChange={handleChange}
            />
            <InputField
              type="text"
              id="serviceSearchResultCount"
              name="count"
              value={values.count}
              onChange={handleChange}
              classes="form__group-stack"
              label={t("service.FilterRowCount")}
            />
          </form>
        )}
      </Formik>
    );
  }
);
export default ServiceSearchVerticalForm;
