import React from "react";
import {
  BoolSelectInputField,
  FormBody,
  FormCol,
  FormFlexWrapper,
  FormGroupBlockMultiline,
  InputField,
  SelectInputField
} from "../../../../common/form/form";
import { Formik } from "formik";
import { useSelector } from "react-redux";

const BrokerClaimsDetailsForm = ({ data, t }) => {
  const brokerClaimTypes =
    useSelector(state => state.layout.classifications.broker_claim_type) || [];

  console.log(data.customerInvoice ? data.customerInvoice[0].invoiceNo : "");
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        customer: data.customer ? data.customer[0].name : "",
        sum: data.sum || "",
        brokerClaimTypeCl: data.brokerClaimTypeCl || "",
        claimNo: data.claimNo || "",
        claimDate: data.claimDate || "",
        addToInvoice: data.addToInvoice,
        invoiceNo: data.customerInvoice
          ? data.customerInvoice[0].invoiceNo
          : "",
        additionalInfo: data.additionalInfo || ""
      }}
      onSubmit={(values, { setSubmitting, resetForm }) => {}}
    >
      {({
        values,
        errors,
        state,
        status,
        touched,
        handleBlur,
        handleChange,
        setFieldValue,
        handleSubmit,
        isSubmitting,
        resetForm
      }) => (
        <FormBody>
          <FormFlexWrapper>
            <FormCol>
              <InputField
                type="text"
                id="brokerClaimsDetailsCustomer"
                name="customer"
                classes="form__group"
                label={t("broker.claims.Customer")}
                value={values.customer}
                onChange={handleChange}
              />
              <InputField
                type="text"
                id="brokerClaimsDetailsSum"
                name="sum"
                classes="form__group"
                label={t("broker.claims.Sum")}
                value={values.sum}
                onChange={handleChange}
              />
            </FormCol>
            <FormCol className="u-margin-left-medium">
              <SelectInputField
                type="text"
                id="brokerClaimsDetailsClaimType"
                name="brokerClaimTypeCl"
                options={
                  brokerClaimTypes.length
                    ? [
                        { name: "", value: "" },
                        ...brokerClaimTypes.map(item => {
                          return Object.assign(
                            {},
                            {
                              name: t(`classifications.${item.name}`),
                              value: item.value
                            }
                          );
                        })
                      ]
                    : [{ name: "", value: "" }]
                }
                classes="form__group"
                label={t("broker.claims.FilterBrokerClaimType")}
                value={values.brokerClaimTypeCl}
                onChange={handleChange}
              />
              <InputField
                id="brokerClaimsDetailsClaimNumber"
                name="claimNo"
                classes="form__group"
                label={t("broker.claims.FilterClaimNo")}
                value={values.claimNo}
                onChange={handleChange}
              />
            </FormCol>
            <FormCol className="u-margin-left-medium">
              <InputField
                type="date"
                id="brokerClaimsDetailsClaimDate"
                name="claimDate"
                classes="form__group"
                label={t("broker.claims.BrokerClaimDate")}
                value={values.claimDate}
                onChange={handleChange}
              />

              <InputField
                id="brokerClaimsDetailsInvoiceNo"
                name="invoiceNo"
                classes="form__group"
                label={t("broker.claims.FilterInvoiceNo")}
                value={values.invoiceNo}
                onChange={handleChange}
              />
            </FormCol>
            <BoolSelectInputField
              label={t("broker.claims.AddToInvoice")}
              id="brokerClaimsDetailsAddToInvoice"
              name="addToInvoice"
              classes="form__group u-margin-left-small"
              value={values.addToInvoice}
              onChange={handleChange}
            />
          </FormFlexWrapper>
          <FormGroupBlockMultiline>
            <label>{t("broker.claims.AdditionalInfo")}</label>
            <textarea
              type="text"
              onChange={handleChange}
              name="additionalInfo"
              value={values.additionalInfo}
            />
          </FormGroupBlockMultiline>
        </FormBody>
      )}
    </Formik>
  );
};
export default BrokerClaimsDetailsForm;
