import React, { useRef } from "react";
import { useDispatch } from "react-redux";
import { SearchSection } from "../../../../common/section";
import { useTranslation } from "react-i18next";
import useLayoutState from "../../../../common/redux/hooks/useLayoutState";
import { brokerClaims } from "../../../../common/redux/types/selectorTypes";
import useSearchState from "../../../../common/redux/hooks/useSearchState";
import useLayoutActionDispatchers from "../../../../common/redux/actions/layout/hooks/useLayoutActionDispatchers";
import { BROKER_CLAIMS } from "../../../../common/redux/types/viewTypes";
import useDataState from "../../../../common/redux/hooks/useDataState";
import BrokerClaimsSearchForm from "../../search/forms/brokerClaimsSearchForm";
import { loadData } from "../../../../common/graphql/loadData";
import { fetchBrokerClaimsData } from "../../graphql/brokerClaimsDataQueries";

const BrokerClaimsSearchSection = () => {
  const { t } = useTranslation();
  const { searchQuery } = useSearchState(brokerClaims);
  const { searchOpen } = useLayoutState(brokerClaims);
  const { isLoading } = useDataState(brokerClaims);
  const { handleSearchToggle } = useLayoutActionDispatchers(BROKER_CLAIMS);
  const dispatch = useDispatch();
  let brokerClaimsClassifications;

  const formRef = useRef();
  return (
    <SearchSection
      isOpen={searchOpen}
      formRef={formRef}
      onSearchToggle={handleSearchToggle}
      dataLoading={isLoading}
    >
      <BrokerClaimsSearchForm
        ref={formRef}
        brokerClaimTypes={brokerClaimsClassifications}
        handleSearch={payload =>
          dispatch(
            loadData(
              payload,
              BROKER_CLAIMS,
              brokerClaims,
              fetchBrokerClaimsData
            )
          )
        }
      />
    </SearchSection>
  );
};

export default BrokerClaimsSearchSection;
