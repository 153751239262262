import React, { useRef } from "react";
import _ from "lodash";
import {
  SectionBody,
  SectionHeader,
  SectionMain
} from "../../../common/section";
import useServiceLayoutActions from "../../hooks/layout/useServiceLayoutActions";
import { useTranslation } from "react-i18next";
import useServiceConsts from "../../hooks/useServiceConsts";
import useServiceLayoutState from "../../hooks/layout/useServiceLayoutState";
import ServiceTable from "../../serviceTable";
import ServiceButtons from "./serviceButtons";
import useServiceUtils from "../../hooks/useServiceUtils";
import useSearchState from "../../../common/redux/hooks/useSearchState";
import { service } from "../../../common/redux/types/selectorTypes";
import useDataState from "../../../common/redux/hooks/useDataState";
import { loadData } from "../../../common/graphql/loadData";
import { SERVICE } from "../../../common/redux/types/viewTypes";
import { serviceQuery } from "../../graphql/serviceDataQueries";

const ServiceMainSection = () => {
  const { t } = useTranslation();
  const tableRef = useRef();
  const {
    handleSearchToggle,
    handleTableToggle,
    handleExpandersToggle
  } = useServiceLayoutActions();
  const { filterLabels, tabs } = useServiceConsts();
  const {
    tableVisible,
    expandersVisible,
    searchOpen
  } = useServiceLayoutState();
  const { searchQuery } = useSearchState(service);
  const { selectedItem } = useDataState(service);

  const { getServiceFilters } = useServiceUtils();

  return (
    <SectionMain toggleSearch={handleSearchToggle}>
      <SectionHeader
        sectionHeading={t("service.Service")}
        getFilters={getServiceFilters}
        searchQuery={searchQuery}
        filterLabels={filterLabels}
        tableVisible={tableVisible}
        expandersVisible={expandersVisible}
        tableEnabled={true}
        expandersEnabled={true}
        tabsEnabled={false}
        onToggleTable={handleTableToggle}
        onToggleExpanders={handleExpandersToggle}
        onToggleSearch={handleSearchToggle}
        onSearch={payload => loadData(payload, SERVICE, service, serviceQuery)}
        searchOpen={searchOpen}
      />
      <SectionBody
        TableComponent={ServiceTable}
        ButtonsComponent={ServiceButtons}
        shortcutsEnabled={false}
        selectedRow={selectedItem}
        selectedItem={
          !_.isEmpty(selectedItem) ? selectedItem.customer[0].name : ""
        }
        tableVisible={tableVisible}
        tableRef={tableRef}
        expandersEnabled={expandersVisible}
        tabs={tabs}
      />
    </SectionMain>
  );
};

export default ServiceMainSection;
