import React, { useRef } from "react";
import {
  SectionBody,
  SectionHeader,
  SectionMain
} from "../../../common/section";
import useDiscountLayoutActions from "../../hooks/layout/useDiscountLayoutActions";
import useDiscountLayoutState from "../../hooks/layout/useDiscountLayoutState";
import useDiscountConsts from "../../hooks/useDiscountConsts";
import useDiscountActions from "../../hooks/useDiscountActions";
import DiscountTable from "../../discountTable";
import DiscountButtons from "./discountButtons";
import { useTranslation } from "react-i18next";
import { discount } from "../../../common/redux/types/selectorTypes";
import useDataState from "../../../common/redux/hooks/useDataState";
import useSearchState from "../../../common/redux/hooks/useSearchState";

const DiscountMainSection = () => {
  const { t } = useTranslation();
  const tableRef = useRef();
  const {
    handleSearchToggle,
    handleTableToggle,
    handleExpandersToggle
  } = useDiscountLayoutActions();
  const {
    tableVisible,
    expandersVisible,
    searchOpen
  } = useDiscountLayoutState();
  const { selectedItem } = useDataState(discount);
  const { searchQuery } = useSearchState(discount);
  const { filterLabels, tabs } = useDiscountConsts();
  const { handleSearch, handleRowSelect } = useDiscountActions();

  return (
    <SectionMain toggleSearch={handleSearchToggle}>
      <SectionHeader
        sectionHeading={t("discount.CustomerDiscount")}
        getFilters={() => {}}
        searchQuery={searchQuery}
        filterLabels={filterLabels}
        tableVisible={tableVisible}
        expandersVisible={expandersVisible}
        tableEnabled={true}
        expandersEnabled={true}
        tabsEnabled={false}
        onToggleTable={handleTableToggle}
        onToggleExpanders={handleExpandersToggle}
        onToggleSearch={handleSearchToggle}
        onSearch={handleSearch}
        searchOpen={searchOpen}
      />

      <SectionBody
        TableComponent={DiscountTable}
        ButtonsComponent={DiscountButtons}
        shortcutsEnabled={false}
        selectedRow={selectedItem}
        tableVisible={tableVisible}
        onRowSelect={handleRowSelect}
        tableRef={tableRef}
        selectedItem={
          selectedItem.customer ? selectedItem.customer[0].name : ""
        }
        expandersEnabled={expandersVisible}
        tabs={tabs}
      />
    </SectionMain>
  );
};

export default DiscountMainSection;
