import React from "react";
import Modal from "../../../../common/modal/Modal";
import ClearingAddModalTable from "./clearingAddModalTable";
import { Button } from "../../../../common/buttons/button";
import { FormFlexWrapper } from "../../../../common/form/form";

const ClearingAddModal = ({ hide, selectedItem }) => {
  return (
    <Modal>
      <h4 className="heading__quaternary u-margin-bottom-tiny">
        Vali kliendi PLACEHOLDER kanded, mida kokku siduda
      </h4>
      <ClearingAddModalTable />
      <FormFlexWrapper
        style={{ justifyContent: "space-between", alignItems: "center" }}
        classes="u-margin-top-small"
      >
        <p style={{ fontSize: "1.3rem" }}>
          Valitud ridade jääkide summa xxx.xx
        </p>
        <div style={{ display: "flex" }}>
          <Button
            btnStyle="primary"
            size="medium"
            classes="btn--footer"
            disabled={true}
            label="Seo kanded"
          />
          <Button
            btnStyle="danger"
            size="medium"
            classes="btn--footer u-margin-left-tiny"
            label="Tagasi"
            handleClick={hide}
          />
        </div>
      </FormFlexWrapper>
    </Modal>
  );
};

export default ClearingAddModal;
