import { LANGCHANGE, NAV_ITEM_TOGGLE } from "../actions/layoutActions";

const initialState = {
  navbar: {
    selectedNavItem: [],
    menutoggle: {
      customer: false,
      broker: false,
      factoring: false,
      vat: false,
      sections: true,
      admin: true,
      hidden: false
    },
    lang: {
      current: ""
    }
  },
  navItemsState: [],
  user: {
    name: "Raido Lillemets",
    img: {}
  },
  sizes: {
    section: null
  },
  classifications: {
    error: {},
    countries: [],
    entry_source_types: [],
    customer_invoice_status_types: [],
    contract_status_types: [],
    contract_types: [],
    card_types: [],
    card_status: [],
    languages: []
  },
  installationPermissions: [],

  classificationsLoading: true,
  installation: [],
  isLoading: false,
  databaseUp: false
};

const LayoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case NAV_ITEM_TOGGLE:
      return {
        ...state,
        navbar: {
          ...state.navbar,
          menutoggle: {
            ...state.navbar.menutoggle,
            [action.payload]: !state.navbar.menutoggle[action.payload]
          }
        }
      };
    case LANGCHANGE: {
      return {
        ...state,
        lang: {
          ...state.lang,
          current: action.payload
        }
      };
    }
    case "RESIZE": {
      return {
        ...state,
        sizes: {
          ...state.sizes,
          section: action.payload
        }
      };
    }

    case "SELECT_NAV_ITEM": {
      return {
        ...state,
        selectedNavItem: action.payload
      };
    }
    case "SELECT_NAVMAIN_ITEM": {
      return {
        ...state,
        selectedNavMainItem: action.payload
      };
    }

    case "LOAD_TRANSACTION_ENTRY_SOURCE_TYPE_CLASSIFICATIONS_SUCCESS": {
      return {
        ...state,
        classifications: {
          ...state.classifications,
          isLoading: false,
          entry_source_types: action.payload
        }
      };
    }

    case "LOAD_TRANSACTION_ENTRY_SOURCE_TYPE_CLASSIFICATIONS_START": {
      return {
        ...state,
        classifications: {
          ...state.classifications,
          isLoading: true
        }
      };
    }

    case "LOAD_TRANSACTION_ENTRY_SOURCE_TYPE_CLASSIFICATIONS_ERROR": {
      return {
        ...state,
        classifications: {
          ...state.classifications,
          isLoading: false,
          error: action.payload
        }
      };
    }

    case "LOAD_CUSTOMER_INVOICE_STATUS_CLASSIFICATIONS_SUCCESS": {
      return {
        ...state,
        classifications: {
          ...state.classifications,
          isLoading: false,
          customer_invoice_status_types: action.payload
        }
      };
    }

    case "LOAD_CUSTOMER_INVOICE_STATUS_CLASSIFICATIONS_ERROR": {
      return {
        ...state,
        classifications: {
          ...state.classifications,
          isLoading: false,
          error: action.payload
        }
      };
    }

    case "LOAD_COUNTRIES_LIST_START": {
      return {
        ...state,
        classifications: {
          ...state.classifications,
          isLoading: true
        }
      };
    }
    case "LOAD_COUNTRIES_LIST_SUCCESS": {
      return {
        ...state,
        classifications: {
          ...state.classifications,
          isLoading: false,
          countries: action.payload
        }
      };
    }

    case "LOAD_COUNTRIES_LIST_ERROR": {
      return {
        ...state,
        classifications: {
          ...state.classifications,
          error: action.payload,
          countries: action.payload
        }
      };
    }

    case "LOAD_CLASSIFICATIONS_START": {
      return {
        ...state,
        classificationsLoading: true
      };
    }

    case "LOAD_CLASSIFICATIONS_SUCCESS": {
      return {
        ...state,
        classifications: action.payload,
        classificationsLoading: false
      };
    }

    case "LOAD_CLASSIFICATIONS_ERROR": {
      return {
        ...state,
        classificationsLoading: false
      };
    }

    case "LOAD_INSTALLATION_DATA_START": {
      return {
        ...state,
        isLoading: true
      };
    }

    case "SET_INSTALLATION_PERMISSIONS": {
      return {
        ...state,
        installationPermissions: action.payload
      };
    }

    case "LOAD_INSTALLATION_DATA_SUCCESS": {
      return {
        ...state,
        installation: action.payload
      };
    }

    case "TOGGLE_NAV_GROUP_SECTIONS": {
      return {
        ...state,
        navbar: {
          ...state.navbar,
          menutoggle: {
            ...state.navbar.menutoggle,
            sections: !state.navbar.menutoggle.sections
          }
        }
      };
    }

    case "TOGGLE_NAV_GROUP_HIDDEN": {
      return {
        ...state,
        navbar: {
          ...state.navbar,
          menutoggle: {
            ...state.navbar.menutoggle,
            hidden: !state.navbar.menutoggle.hidden
          }
        }
      };
    }

    case "TOGGLE_NAV_GROUP_ADMIN": {
      return {
        ...state,
        navbar: {
          ...state.navbar,
          menutoggle: {
            ...state.navbar.menutoggle,
            admin: !state.navbar.menutoggle.admin
          }
        }
      };
    }

    case "SET_NAV_ITEMS_STATE": {
      return {
        ...state,
        navbar: {
          ...state.navbar,
          navItemsState: action.payload
        }
      };
    }
    case "DATABASE_CONNECTION_OK": {
      return {
        ...state,
        databaseUp: true
      };
    }

    case "DATABASE_CONNECTION_FAIL": {
      return {
        ...state,
        databaseUp: false
      };
    }

    default:
      return state;
  }
};

export default LayoutReducer;
