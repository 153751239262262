import {authorizationHeaders} from "../../../../services/queries";
import axios from "axios";

export const loadStreetAutocompleteData = async payload => {
    const data = await axios.post(
        "/graphql",
        {
            query: `
        query {
  streets(name:"%${payload}"){
    id
    name
  }
}
      `
        },
        authorizationHeaders()
    );
    console.log(data);
    return data.data.data.streets;
};



