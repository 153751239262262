const createSearchReducer = (name = "") => {
  const initialState = {
    isOpen: true,
    lastHeight: "",
    query: {},

    dropdowns: {}
  };
  return function(state = initialState, action) {
    switch (action.type) {
      case `SUBMIT_${name}_QUERY`: {
        return {
          ...state,
          query: action.payload
        };
      }

      case `SET_${name}_DROPDOWN_VALUES`:
        return {
          ...state,
          dropdowns: { ...state.dropdowns, ...action.payload }
        };

      default:
        return state;
    }
  };
};

export default createSearchReducer;
