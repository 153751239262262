import React, { useMemo } from "react";
import Table from "../common/table";
import { TableResultData } from "../common/tableFooter";
import { useTranslation } from "react-i18next";
import useTableActionDispatchers from "../common/redux/actions/table/hooks/useTableActionDispatchers";
import { card } from "../common/redux/types/selectorTypes";
import useTableState from "../common/redux/hooks/useTableState";
import useDataState from "../common/redux/hooks/useDataState";
import { CARD } from "../common/redux/types/viewTypes";
import useTableLayoutActionDispatchers from "../common/redux/actions/layout/table/hooks/useTableLayoutActionDispatchers";

const ClearingTable = () => {
  const { t } = useTranslation();
  const { items, selectedItem, isLoading } = useDataState(card);
  const { handleRowSelect } = useTableActionDispatchers(CARD);
  const { handleTableHeightSet } = useTableLayoutActionDispatchers(CARD);
  const { height } = useTableState(card);
  const columns = useMemo(
    () => [
      {
        id: "clearingDocNo",
        Header: t("clearing.ClearingDocumentNo"),
        accessor: d => (d.clearing_doc_no ? t(d.clearing_doc_no) : ""),
        width: 80
      },
      {
        id: "date",
        Header: t("clearing.Date"),
        accessor: d => (d.date ? d.date : "")
      },
      {
        id: "balance",
        Header: t("clearing.Balance"),
        accessor: d => d.balance
      },
      {
        id: "info",
        Header: t("clearing.Info"),
        accessor: d => d.info
      }
    ],
    [t]
  );

  return (
    <React.Fragment>
      <Table
        classes="u-margin-bottom-small"
        data={items || []}
        isLoading={isLoading}
        selectedRow={selectedItem}
        onRowSelect={handleRowSelect}
        setTableHeight={handleTableHeightSet}
        height={height}
        columns={columns}
      />
      <TableResultData count={items.length} />
    </React.Fragment>
  );
};

export default ClearingTable;
