import { useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  TOGGLE_DISCOUNT_EXPANDER_VIEW,
  TOGGLE_DISCOUNT_SEARCH,
  TOGGLE_DISCOUNT_TABLE
} from "../../actions/discountActions";

const useDiscountLayoutActions = () => {
  const dispatch = useDispatch();

  const handleTableToggle = useCallback(() => {
    dispatch(TOGGLE_DISCOUNT_TABLE());
  }, [dispatch]);

  const handleExpandersToggle = useCallback(() => {
    dispatch(TOGGLE_DISCOUNT_EXPANDER_VIEW());
  }, [dispatch]);

  const handleSearchToggle = useCallback(() => {
    dispatch(TOGGLE_DISCOUNT_SEARCH());
  }, [dispatch]);

  return { handleTableToggle, handleExpandersToggle, handleSearchToggle };
};

export default useDiscountLayoutActions;
