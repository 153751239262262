import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import "react-slidedown/lib/slidedown.css";
import {
  NavBrand,
  NavigationBody,
  NavItemGroup,
  NavItemGroupItems,
  NavItemList,
  NavPlaceholders
} from "./navigationComponents";
import useAppLayout from "../hooks/useAppLayout";
import useUserData from "../../auth/hooks/useUserData";
import useNavigationActions from "../hooks/navigation/useNavigationActions";
import useNavigationUtils from "../hooks/navigation/useNavigationUtils";
import useNavigationState from "../hooks/navigation/useNavigationState";
import useAuthState from "../../auth/hooks/useAuthState";

const Navigation = props => {
  const { t } = useTranslation();
  const { appLayoutConfig } = useAppLayout();
  const {
    userAuthenticated,
    permissionsLoaded,
    assignedPermissions
  } = useAuthState();
  const { userObj } = useUserData();
  const { getMainItemPath } = useNavigationUtils();
  const {
    toggleSectionsGroup,
    toggleHiddenGroup,
    toggleAdminGroup
  } = useNavigationActions();
  const {
    sectionsGroupOpen,
    hiddenGroupOpen,
    selectedMainItem,
    adminGroupOpen
  } = useNavigationState();

  const pathname = props.location.pathname;

  useEffect(() => {
    getMainItemPath(pathname);
  }, [pathname, getMainItemPath]);

  const [collapsed, setCollapsed] = useState(false);

  const toggleCollapse = () => {
    setCollapsed(c => !c);
  };

  // getMainItemPath(pathname);
  return (
    <NavigationBody collapsed={collapsed}>
      <NavBrand user={userObj} img={"/assets/icons/user.svg"} />
      <NavItemList navCollapsed={collapsed}>
        {appLayoutConfig.length ? (
          <React.Fragment>
            <NavItemGroup
              isOpen={sectionsGroupOpen}
              heading={t("navigation.SectionsGroup")}
              toggleOpen={toggleSectionsGroup}
            >
              <NavItemGroupItems
                data={appLayoutConfig}
                isHidden={false}
                section="view"
                collapsed={collapsed}
                selectedMainItem={selectedMainItem}
                t={t}
              />
            </NavItemGroup>
            <NavItemGroup
              isOpen={adminGroupOpen}
              heading={"ADMIN"}
              classes="u-margin-top-small"
              toggleOpen={toggleAdminGroup}
            >
              <NavItemGroupItems
                data={appLayoutConfig}
                isHidden={false}
                section="admin"
                collapsed={collapsed}
                selectedMainItem={selectedMainItem}
                t={t}
              />
            </NavItemGroup>
            <NavItemGroup
              heading={t("navigation.HiddenGroup")}
              isOpen={hiddenGroupOpen}
              classes="u-margin-top-small"
              toggleOpen={toggleHiddenGroup}
            >
              <NavItemGroupItems
                data={appLayoutConfig}
                isHidden={true}
                section={"view"}
                collapsed={collapsed}
                selectedMainItem={selectedMainItem}
                t={t}
              />
            </NavItemGroup>
          </React.Fragment>
        ) : (
          <NavPlaceholders
            userAuth={userAuthenticated}
            permissionsLoaded={permissionsLoaded}
            data={assignedPermissions}
          />
        )}
      </NavItemList>
      <div
        className="btn btn--nav-toggle u-margin-bottom-small"
        style={{ marginTop: "auto" }}
        onClick={() => toggleCollapse()}
      >
        {collapsed
          ? t("layout.NavigationExpand") + " >>"
          : t("layout.NavigationCollapse") + " <<"}
      </div>
    </NavigationBody>
  );
};

export default withRouter(Navigation);
