import React from "react";

export const checkPermissions = (items, permissions) => {
  if (permissions === undefined || permissions.length === 0) {
    return [];
  } else {
    return [
      ...items.filter(item => {
        return permissions.find(x => x.key === item.permission || item.permission === "exempt");
      })
    ];
  }
};


export const checkPermissionsContractTab = (items, permissions) => {
  if (permissions === undefined || permissions.length === 0) {
    return [
      ...items.filter(item => {
        return item.permission === "exempt"
      })
    ];
  } else {
    return [
      ...items.filter(item => {
        return permissions.find(x => x.expanderKey === item.permission || item.permission === "exempt");
      })
    ];
  }
};
