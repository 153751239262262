import React from "react";
import { Button } from "../../../../common/buttons/button";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { ToggleContent } from "../../../../common/modal/ToggleContent";
import Modal from "../../../../common/modal/Modal";

const BrokerClaimsButtons = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div className="u-margin-right-small" style={{ display: "flex" }}>
        <ToggleContent
          toggle={show => (
            <Button
              btnStyle="primary"
              size="small"
              handleClick={show}
              label={t("customer.customerView.BtnAddCustomer")}
              classes="btn--footer"
              disabled={true}
            />
          )}
          content={hide => (
            <Modal>
              <div
                style={{
                  position: "absolute",
                  width: "20rem",
                  height: "20rem",
                  borderRadius: "3px",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  backgroundColor: "red",
                  color: "white"
                }}
              >
                COLOR
                <button onClick={hide}>Close</button>
              </div>
            </Modal>
          )}
        />
        <Button
          btnStyle="danger"
          size="small"
          label={t("customer.customerView.BtnDeleteCustomer")}
          handleClick={() => {
            toast.success("Entity deleted");
          }}
          classes={"btn--footer"}
          disabled={true}
        />
        <Button
          btnStyle="green"
          size="small"
          label={t("customer.customerView.BtnSaveCustomer")}
          handleClick={() => {}}
          disabled={true}
          classes="btn--footer"
        />
      </div>

      <Button
        btnStyle="lime"
        size="small"
        label={t("customer.customerView.BtnCustomersToCsv")}
        handleClick={() => alert("CSV")}
        classes={"btn--footer"}
        disabled={true}
      />
      <Button
        btnStyle="lime"
        size="small"
        label={t("customer.customerView.BtnSendToPrinter")}
        handleClick={() => alert("PRINT ENVELOPE")}
        classes="btn--footer"
        disabled={true}
      />
    </React.Fragment>
  );
};

export default BrokerClaimsButtons;
