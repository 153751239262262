import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import customerView from "../../customer/customers/customerView";
import customerAccountView from "../../customer/account/customerAccountView";
import customerInvoiceView from "../../customer/invoice/customerInvoiceView";
import CustomerDepositView from "../../customer/deposit/customerDepositView";
import ContractView from "../../contract/contractView";
import ServiceView from "../../service/serviceView";
import FactoringInvoicesView from "../../factoring/invoices/factoringInvoicesView";
import FactoringDebtorView from "../../factoring/debtor/factoringDebtorView";
import FactoringDebtorAccountView from "../../factoring/debtor_account/factoringDebtorAccountView";
import vatDocumentsView from "../../vat/documents/vatDocumentsView";
import VatApplicationsSection from "../../vat/applications/vatApplicationsSection";
import CardView from "../../card/cardView";
import BrokerInvoicesSection from "../../broker/invoices/brokerInvoicesSection";
import PartnerView from "../../partner/partnerView";
import SalespointSection from "../../salespoint/salespointSection";
import transactionsView from "../../transactions/transactionsView";
import DiscountView from "../../discount/discountView";
import ReportSection from "../../report/reportSection";
import useUserData from "../../auth/hooks/useUserData";
import PreferencesSection from "../../preferences/preferencesSection";
import FactoringDebtorOverdueInvoicesView from "../../factoring/debtor_overdue_invoices/factoringDebtorOverdueInvoicesView";
import TasksView from "../../tasks/tasksView";
import BrokerClaimsView from "../../broker/claims/brokerClaimsView";
import BankImportView from "../../import/bank/BankImportView";
import ClearingView from "../../clearing/clearingView";

const useAppLayout = () => {
  const { t } = useTranslation();
  const { userPermissions } = useUserData();
  const appCheckedLayout = useCallback(() => {
    const layoutAll = [
      {
        name: "customers",
        translation: t("navigation.Customers"),
        iconSrc: "/assets/icons/customer_new.svg",
        link: "/customers",
        index: 0,
        hidden: false,
        permission: "View.Customer",
        section: "view",
        mainComponent: customerView
      },
      {
        name: "customeraccount",
        translation: t("navigation.CustomerAccount"),
        iconSrc: "/assets/icons/customer_account.svg",
        link: "/customeraccount",
        index: 1,
        hidden: false,
        permission: "View.CustomerAccount",
        section: "view",
        mainComponent: customerAccountView
      },
      {
        name: "customerinvoice",
        translation: t("navigation.CustomerInvoice"),
        iconSrc: "/assets/icons/inbox.svg",
        link: "/customerinvoice",
        index: 2,
        hidden: false,
        permission: "View.CustomerInvoice",
        section: "view",
        mainComponent: customerInvoiceView
      },
      {
        name: "customerdeposit",
        translation: t("navigation.CustomerDeposit"),
        iconSrc: "/assets/icons/customer_new.svg",
        link: "/customerdeposit",
        index: 3,
        hidden: false,
        permission: "View.CustomerDeposit",
        section: "view",
        mainComponent: CustomerDepositView
      },

      {
        name: "contract",
        translation: t("navigation.contract"),
        iconSrc: "/assets/icons/contract.svg",
        link: "/contract",
        index: 4,
        hidden: false,
        permission: "View.Contract",
        section: "view",
        mainComponent: ContractView
      },
      {
        name: "service",
        translation: t("navigation.service"),
        iconSrc: "/assets/icons/service_new.svg",
        link: "/service",
        index: 5,
        hidden: false,
        permission: "View.Service",
        section: "view",
        mainComponent: ServiceView
      },

      {
        name: "factoringinvoices",
        translation: t("navigation.FactoringInvoices"),
        iconSrc: "/assets/icons/briefcase.svg",
        link: "/factoringinvoices",
        index: 6,
        hidden: false,
        permission: "View.FactoringInvoice",
        section: "view",
        mainComponent: FactoringInvoicesView
      },
      {
        name: "factoringdebtor",
        translation: t("navigation.FactoringDebtor"),
        iconSrc: "/assets/icons/briefcase.svg",
        link: "/factoringdebtor",
        index: 7,
        hidden: false,
        permission: "View.FactoringDebtor",
        section: "view",
        mainComponent: FactoringDebtorView
      },
      {
        name: "factoringdebtoraccount",
        translation: t("navigation.FactoringDebtorAccount"),
        iconSrc: "/assets/icons/briefcase.svg",
        link: "/factoringdebtoraccount",
        index: 8,
        hidden: false,
        permission: "View.FactoringDebtorAccount",
        section: "view",
        mainComponent: FactoringDebtorAccountView
      },
      {
        name: "vatdocuments",
        translation: t("navigation.vatDocuments"),
        iconSrc: "/assets/icons/tax.svg",
        link: "/vatdocuments",
        index: 9,
        hidden: false,
        permission: "View.VatDocument",
        section: "view",
        mainComponent: vatDocumentsView
      },
      {
        name: "vatapplications",
        translation: t("navigation.vatApplications"),
        iconSrc: "/assets/icons/tax.svg",
        link: "/vatapplications",
        index: 10,
        hidden: false,
        permission: "View.VatApplication",
        section: "view",
        mainComponent: VatApplicationsSection
      },
      {
        name: "card",
        translation: t("navigation.card"),
        iconSrc: "/assets/icons/credit-card.svg",
        link: "/card",
        index: 11,
        hidden: false,
        permission: "View.Card",
        section: "view",
        mainComponent: CardView
      },
      {
        name: "brokerInvoices",
        translation: t("navigation.brokerInvoices"),
        iconSrc: "/assets/icons/receipt.svg",
        link: "/brokerinvoices",
        index: 12,
        hidden: false,
        permission: "View.BrokerInvoice",
        section: "view",
        mainComponent: BrokerInvoicesSection
      },
      {
        name: "brokerclaims",
        translation: t("navigation.brokerClaims"),
        iconSrc: "/assets/icons/claim.svg",
        link: "/brokerclaims",
        index: 13,
        hidden: false,
        permission: "exempt",
        section: "view",
        mainComponent: BrokerClaimsView
      },
      {
        name: "partner",
        translation: t("navigation.partner"),
        iconSrc: "/assets/icons/partner.svg",
        link: "/partner",
        index: 14,
        hidden: false,
        permission: "View.Partner",
        section: "view",
        mainComponent: PartnerView
      },
      {
        name: "salespoint",
        translation: t("navigation.salespoint"),
        iconSrc: "/assets/icons/salespoint.svg",
        link: "/salespoint",
        index: 15,
        hidden: false,
        permission: "View.SalesPoint",
        section: "view",
        mainComponent: SalespointSection
      },
      {
        name: "transactions",
        translation: t("navigation.transactions"),
        iconSrc: "/assets/icons/briefcase.svg",
        link: "/transactions",
        index: 16,
        hidden: false,
        permission: "View.Transaction",
        section: "view",
        mainComponent: transactionsView
      },
      {
        name: "discount",
        translation: t("navigation.discount"),
        iconSrc: "/assets/icons/discount.svg",
        link: "/discount",
        index: 17,
        hidden: false,
        permission: "View.Discount",
        section: "view",
        mainComponent: DiscountView
      },
      {
        name: "report",
        translation: t("navigation.report"),
        iconSrc: "/assets/icons/statistics.svg",
        link: "/report",
        index: 18,
        hidden: true,
        permission: "View.Report",
        section: "view",
        mainComponent: ReportSection
      },
      {
        name: "preferences",
        translation: t("navigation.preferences"),
        iconSrc: "/assets/icons/gear.svg",
        link: "/preferences",
        index: 19,
        permission: "exempt",
        section: "view",
        hidden: false,
        mainComponent: PreferencesSection
      },
      {
        name: "factoringDebtorOverdueInvoices",
        translation: t("factoring.debtor.overdueInvoices.NavHeader"),
        iconSrc: "/assets/icons/briefcase.svg",
        link: "/factoringDebtorOverdueInvoices",
        index: 20,
        permission: "exempt",
        section: "admin",
        hidden: false,
        mainComponent: FactoringDebtorOverdueInvoicesView
      },
      {
        name: "tasks",
        translation: t("tasks.Header"),
        iconSrc: "/assets/icons/list.svg",
        link: "/tasks",
        index: 21,
        permission: "exempt",
        section: "admin",
        hidden: false,
        mainComponent: TasksView
      },
      {
        name: "bankFileImport",
        translation: t("bankFileImport.BankFileImport"),
        iconSrc: "/assets/icons/import.svg",
        link: "/bankFileImport",
        index: 22,
        permission: "exempt",
        section: "admin",
        hidden: false,
        mainComponent: BankImportView
      },
      {
        name: "clearing",
        translation: t("clearing.Header"),
        iconSrc: "/assets/icons/link.svg",
        link: "/clearing",
        index: 23,
        permission: "exempt",
        section: "admin",
        hidden: false,
        mainComponent: ClearingView
      }
    ];

    if (userPermissions === undefined) {
      return [];
    } else {
      return [
        ...layoutAll.filter(item => {
          return userPermissions.find(
            x => x.key === item.permission || item.permission === "exempt"
          );
        })
      ];
    }
  }, [t, userPermissions]);

  const appLayoutConfig = useMemo(() => appCheckedLayout(), [appCheckedLayout]);

  return { appLayoutConfig };
};

export default useAppLayout;
