import React from "react";
import {
  BoolSelectInputField,
  FormCol,
  FormFlexWrapper,
  InputField,
  SelectInputField
} from "../../../../common/form/form";
import { useSelector } from "react-redux";
import { Formik } from "formik";
import { Button } from "../../../../common/buttons/button";

const CustomerContactInformationForm = ({ t, data }) => {
  const CustomerContactInfoTrans = "customer.customerContactData.";

  const languages = useSelector(
    state => state.layout.classifications.languages
  );

  const boardMemberAddress = data.address ? data.address[0] : "";

  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={{
          name: data.name || "",
          documentNo: data.documentNo || "",
          documentIssueDate: data.documentIssueDate || "",
          documentExpireDate: data.documentExpireDate || "",
          identificationDate: data.identificationDate || "",
          language: data.languageCl || "",
          contactPersonType: data.contactPersonTypeCl || "",
          personalCode: data.personalCode || "",
          phone: data.phone || "",
          email: data.email || "",
          hasSignatureRight: data.hasSignatureRight || "",
          additionalInfo: data.additionalInfo || "",
          boardMemberAddressCountry: boardMemberAddress
            ? data.address[0].country[0].code
            : "",
          boardMemberAddressCity: boardMemberAddress
            ? data.address[0].city
            : "",
          boardMemberAddressStreet: boardMemberAddress
            ? data.address[0].street
            : "",
          boardMemberAddressHouseOrApartment: boardMemberAddress
            ? data.address[0].houseOrApartment
            : "",
          boardMemberAddressZipCode: boardMemberAddress
            ? data.address[0].zipCode
            : ""
        }}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            alert(JSON.stringify(values, null, 2));
            setSubmitting(false);
          }, 400);
        }}
      >
        {({ values, handleChange }) => (
          <form>
            <FormFlexWrapper direction="column">
              <FormCol>
                <div className="form--flex form--space-between">
                  <h3 className="u-margin-bottom-tiny">
                    {t(CustomerContactInfoTrans + "HeaderInformation")}
                  </h3>
                  <div className="btn btn--primary btn--small">
                    Make primary
                  </div>
                </div>
                <InputField
                  type="text"
                  label={t(CustomerContactInfoTrans + "PersonName")}
                  classes="form__group-stack"
                  id="name"
                  value={values.name}
                  onChange={e => {
                    handleChange(e);
                  }}
                />
                <FormFlexWrapper>
                  <SelectInputField
                    name="contactPersonType"
                    classes="form__group-stack"
                    label={t(CustomerContactInfoTrans + "Position")}
                    value={values.contactPersonType}
                    options={[
                      { name: "", value: "" },
                      {
                        name: t(CustomerContactInfoTrans + "HeaderBoardMember"),
                        value: "CONTACT_PERSON_TYPE.BOARD_MEMBER"
                      },
                      {
                        name: t(
                          CustomerContactInfoTrans + "HeaderContactPerson"
                        ),
                        value: "CONTACT_PERSON_TYPE.CONTACT_PERSON"
                      },
                      {
                        name: t(CustomerContactInfoTrans + "HeaderAccountant"),
                        value: "CONTACT_PERSON_TYPE.ACCOUNTANT"
                      }
                    ]}
                    onChange={e => {
                      handleChange(e);
                    }}
                  />
                  <SelectInputField
                    name="language"
                    classes="form__group-stack u-margin-left-tiny"
                    label={t(CustomerContactInfoTrans + "PersonLanguage")}
                    value={values.language}
                    options={[
                      { name: "", value: "" },
                      ...languages.map(item => {
                        return Object.assign(
                          {},
                          {
                            name: t(
                              `classifications.LANGUAGE.${
                                item.name.split(".")[1]
                              }`
                            ),
                            value: item.value
                          }
                        );
                      })
                    ]}
                    onChange={e => {
                      handleChange(e);
                    }}
                  />
                </FormFlexWrapper>
                <FormFlexWrapper>
                  <InputField
                    id="personal_code"
                    style={{ flexGrow: "1" }}
                    label={t(CustomerContactInfoTrans + "PersonPersonalCode")}
                    type="text"
                    classes="form__group-stack"
                    name="personalCode"
                    value={values.personalCode}
                    onChange={e => {
                      handleChange(e);
                    }}
                  />
                  <BoolSelectInputField
                    id="customerContactInfoSignatureRight"
                    name="hasSignatureRight"
                    style={{ width: "20%" }}
                    value={values.hasSignatureRight}
                    label={t(CustomerContactInfoTrans + "PersonSignatory")}
                    classes="form__group-stack u-margin-left-tiny"
                    onChange={handleChange}
                  />
                </FormFlexWrapper>
                <FormFlexWrapper>
                  <InputField
                    id="phone"
                    label={t(CustomerContactInfoTrans + "PersonPhone")}
                    type="text"
                    classes="form__group-stack"
                    name="phone"
                    value={values.phone}
                    onChange={e => {
                      handleChange(e);
                    }}
                  />
                  <InputField
                    id="email"
                    label={t(CustomerContactInfoTrans + "PersonEmail")}
                    type="text"
                    classes="form__group-stack u-margin-left-tiny"
                    name="email"
                    value={values.email}
                    onChange={e => {
                      handleChange(e);
                    }}
                  />
                </FormFlexWrapper>
                <div className="form__block form__group-stack form__block--multiline">
                  <label>
                    {t(CustomerContactInfoTrans + "PersonAdditionalInfo")}
                  </label>
                  <textarea
                    type="text"
                    name="additionalInfo"
                    value={values.additionalInfo}
                    onChange={e => {
                      handleChange(e);
                    }}
                  />
                </div>
              </FormCol>

              <h3 className="u-margin-bottom-tiny u-margin-top-small">
                {t(CustomerContactInfoTrans + "DocumentNo")}
              </h3>
              <div className="form--flex">
                <FormCol>
                  <FormFlexWrapper>
                    <InputField
                      id="document_no"
                      label={t(CustomerContactInfoTrans + "DocumentNo")}
                      type="text"
                      classes="form__group-stack"
                      name="documentNo"
                      value={values.documentNo}
                      onChange={e => {
                        handleChange(e);
                      }}
                    />
                    <InputField
                      id="ident_date"
                      label={t(CustomerContactInfoTrans + "IdentificationDate")}
                      type="date"
                      classes="form__group-stack u-margin-left-tiny"
                      name="identificationDate"
                      value={values.identificationDate}
                      onChange={e => {
                        handleChange(e);
                      }}
                    />
                  </FormFlexWrapper>
                  <FormFlexWrapper>
                    <InputField
                      id="ident_date"
                      label={t(CustomerContactInfoTrans + "IssueDate")}
                      type="date"
                      classes="form__group-stack"
                      name="issueDate"
                      value={values.documentIssueDate}
                      onChange={e => {
                        handleChange(e);
                      }}
                    />
                    <InputField
                      id="ident_date"
                      label={t(CustomerContactInfoTrans + "ExpireDate")}
                      type="date"
                      classes="form__group-stack u-margin-left-tiny"
                      name="expireDate"
                      value={values.documentExpireDate}
                      onChange={e => {
                        handleChange(e);
                      }}
                    />
                  </FormFlexWrapper>
                  {/*<div className="form--flex form--flex-bottom">*/}
                  <FormFlexWrapper>
                    <InputField
                      id="ident_date"
                      label={t(CustomerContactInfoTrans + "Location")}
                      type="text"
                      classes="form__group-stack"
                      name="ident_location"
                      value={""}
                      onChange={e => {
                        handleChange(e);
                      }}
                    />

                    <Button
                      btnStyle="primary"
                      size="small"
                      classes="btn-subSection-header u-margin-left-small"
                      style={{ marginTop: "auto" }}
                      label={t(CustomerContactInfoTrans + "Open")}
                    />
                    <Button
                      btnStyle="primary"
                      size="small"
                      classes="btn-subSection-header u-margin-left-tiny"
                      style={{ marginTop: "auto" }}
                      label={t(CustomerContactInfoTrans + "Add")}
                    />
                  </FormFlexWrapper>
                </FormCol>
              </div>
            </FormFlexWrapper>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default CustomerContactInformationForm;
