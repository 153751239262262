import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import useDataState from "../../common/redux/hooks/useDataState";
import { clearing } from "../../common/redux/types/selectorTypes";
import { CLEARING } from "../../common/redux/types/viewTypes";
import useTableActionDispatchers from "../../common/redux/actions/table/hooks/useTableActionDispatchers";
import useTableLayoutActionDispatchers from "../../common/redux/actions/layout/table/hooks/useTableLayoutActionDispatchers";
import useTableState from "../../common/redux/hooks/useTableState";
import Table from "../../common/table";
import { TableResultData } from "../../common/tableFooter";

const ClearingDetailsTable = () => {
  const { t } = useTranslation();
  const { items, selectedItem, isLoading } = useDataState(clearing);
  const { handleRowSelect } = useTableActionDispatchers(CLEARING);
  const { handleTableHeightSet } = useTableLayoutActionDispatchers(CLEARING);
  const { height } = useTableState(clearing);
  const columns = useMemo(
    () => [
      {
        id: "clearingDocNo",
        Header: t("clearing.ClearingDocumentNo"),
        accessor: d => (d.clearing_doc_no ? t(d.clearing_doc_no) : ""),
        width: 80
      },
      {
        id: "info",
        Header: t("clearing.Info"),
        accessor: d => (d.info ? d.info : "")
      },
      {
        id: "time",
        Header: t("clearing.details.Time"),
        accessor: d => d.time
      },
      {
        id: "amount",
        Header: t("clearing.details.Amount"),
        accessor: d => d.amount || ""
      },
      {
        id: "entryOriginalAmount",
        Header: t("clearing.details.EntryOriginalAmount"),
        accessor: d => d.entryOriginalAmount || ""
      },
      {
        id: "entryDescription",
        Header: t("clearing.details.EntryDescription"),
        accessor: d => d.entryDescription
      }
    ],
    [t]
  );

  return (
    <React.Fragment>
      <Table
        classes="u-margin-bottom-small"
        data={items || []}
        isLoading={isLoading}
        selectedRow={selectedItem}
        onRowSelect={handleRowSelect}
        setTableHeight={handleTableHeightSet}
        height={height}
        columns={columns}
      />
      <TableResultData count={items.length} />
    </React.Fragment>
  );
};

export default ClearingDetailsTable;
