import { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { SET_CUSTOMER_TABS_STATE } from "../../account/actions/customerAccountActions";
import { getCustomerInvoiceQuery } from "../../invoice/graphql/customerInvoicesDataQueries";
import { loadCustomerAccountData } from "../../account/graphql/customerAccountDataQueries";
import { loadCustomers } from "../graphql/customersDataQueries";

const useCustomersActions = () => {
  const dispatch = useDispatch();

  const getCustomerAccountShortcutData = (itemName, dispatch, location) => {
    dispatch(loadCustomerAccountData({ client: itemName }));
  };

  const getCustomerInvoicesShortcutData = (itemName, dispatch) => {
    dispatch(getCustomerInvoiceQuery({ customer: itemName }));
  };

  const setSelectedRowsState = useCallback(payload => {}, []);

  const onSetCustomersTabsState = useMemo(
    () => payload => {
      dispatch(SET_CUSTOMER_TABS_STATE(payload));
    },
    [dispatch]
  );

  const onCustomersSearch = payload => {
    dispatch(loadCustomers(payload));
  };

  return {
    getCustomerAccountShortcutData,
    getCustomerInvoicesShortcutData,
    setSelectedRowsState,
    onCustomersSearch,
    onSetCustomersTabsState
  };
};

export default useCustomersActions;
