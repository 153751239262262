import { useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  SELECT_NAVMAIN_ITEM,
  TOGGLE_NAV_GROUP_ADMIN,
  TOGGLE_NAV_GROUP_HIDDEN,
  TOGGLE_NAV_GROUP_SECTIONS
} from "../../actions/layoutActions";

const useNavigationActions = () => {
  const dispatch = useDispatch();
  const toggleHiddenGroup = useCallback(
    payload => {
      dispatch(TOGGLE_NAV_GROUP_HIDDEN());
    },
    [dispatch]
  );

  const toggleSectionsGroup = useCallback(() => {
    dispatch(TOGGLE_NAV_GROUP_SECTIONS());
  }, [dispatch]);

  const toggleAdminGroup = useCallback(() => {
    dispatch(TOGGLE_NAV_GROUP_ADMIN());
  }, [dispatch]);

  const onNavMainItemSelect = useCallback(
    payload => {
      dispatch(SELECT_NAVMAIN_ITEM(payload));
    },
    [dispatch]
  );

  return {
    toggleHiddenGroup,
    toggleSectionsGroup,
    onNavMainItemSelect,
    toggleAdminGroup
  };
};

export default useNavigationActions;
