import React, { useRef } from "react";
import _ from "lodash";
import {
  SectionBody,
  SectionHeader,
  SectionMain
} from "../../../../common/section";
import { useTranslation } from "react-i18next";
import useLayoutState from "../../../../common/redux/hooks/useLayoutState";
import useLayoutActionDispatchers from "../../../../common/redux/actions/layout/hooks/useLayoutActionDispatchers";
import useTableActionDispatchers from "../../../../common/redux/actions/table/hooks/useTableActionDispatchers";
import useSearchState from "../../../../common/redux/hooks/useSearchState";
import { brokerClaims } from "../../../../common/redux/types/selectorTypes";
import { BROKER_CLAIMS } from "../../../../common/redux/types/viewTypes";
import useDataState from "../../../../common/redux/hooks/useDataState";
import useBrokerClaimsConsts from "../../hooks/useBrokerClaimsConsts";
import BrokerClaimsTable from "../../brokerClaimsTable";
import BrokerClaimsButtons from "./brokerClaimsButtons";

const BrokerClaimsMainsSection = () => {
  const { t } = useTranslation();
  const tableRef = useRef();
  const formRef = useRef({});

  const { handleRowSelect, handleRowToggle } = useTableActionDispatchers(
    BROKER_CLAIMS
  );

  const {
    handleExpandersToggle,
    handleTableToggle,
    handleSearchToggle
  } = useLayoutActionDispatchers(BROKER_CLAIMS);

  const { tableVisible, expandersVisible, searchOpen } = useLayoutState(
    brokerClaims
  );

  const { searchQuery } = useSearchState(brokerClaims);
  const { selectedItem } = useDataState(brokerClaims);

  const { brokerClaimsTabs, brokerClaimsLabels } = useBrokerClaimsConsts();

  return (
    <SectionMain toggleSearch={handleSearchToggle}>
      <SectionHeader
        sectionHeading={t("broker.claims.Header")}
        getFilters={payload => {
          return payload;
        }}
        searchQuery={searchQuery}
        filterLabels={brokerClaimsLabels}
        tableVisible={tableVisible}
        expandersVisible={expandersVisible}
        tableEnabled={true}
        expandersEnabled={true}
        tabsEnabled={false}
        onToggleTable={handleTableToggle}
        onToggleExpanders={handleExpandersToggle}
        onToggleTabs={() => {}}
        onToggleSearch={handleSearchToggle}
        onSearch={() => {}}
        searchOpen={searchOpen}
      />

      <SectionBody
        TableComponent={BrokerClaimsTable}
        ButtonsComponent={BrokerClaimsButtons}
        selectedItem={
          !_.isEmpty(selectedItem) ? selectedItem.customer[0].name : ""
        }
        tableVisible={tableVisible}
        onRowSelect={handleRowSelect}
        removeItem={() => {}}
        onToggleRow={handleRowToggle}
        tableRef={tableRef}
        expandersEnabled={expandersVisible}
        tabs={brokerClaimsTabs}
      />
    </SectionMain>
  );
};

export default BrokerClaimsMainsSection;
