import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import FactoringDebtorAccDetailsForm from "../details/factoringDebtorAccDetailsForm";
import FactoringDebtorAccClearingTable from "../clearing/factoringDebtorAccClearingTable";
import {
  clearing,
  details,
  factoringDebtorAccount
} from "../../../common/redux/types/selectorTypes";
import {
  CLEARING,
  DETAILS,
  FACTORING_DEBTOR_ACCOUNT
} from "../../../common/redux/types/viewTypes";

const useFactoringDebtorAccountConsts = () => {
  const { t } = useTranslation();
  const filterLabels = useMemo(
    () => ({
      name: t("customer.customerView.FilterCustomerName"),
      id: t("customer.customerView.FilterCustomerId"),
      woCusManager: t("customer.customerView.WithoutCustomerManager"),
      regNo: t("customer.customerView.FilterRegNo"),
      vatNo: t("customer.customerView.VatNo"),
      contactPerson: t("customer.customerView.FilterContactPerson"),
      count: t("customer.customerView.FilterResultCount"),
      generalPhone: t("customer.customerView.FilterGeneralPhone"),
      generalEmail: t("customer.customerView.FilterGeneralOrInvoiceEmail"),
      salesAgent: t("customer.customerView.SalesAgent"),
      customerManager: t("customer.customerView.CustomerManager"),
      withoutCustomerManager: t("customer.customerView.WithoutCustomerManager"),
      isActive: t("customer.customerView.FilterIsActive"),
      validFrom: t("customer.customerView.FilterCustomerValidFrom"),
      portOneContractsCount: t(
        "customer.customerView.SearchResultColumnNumberOfP1Contracts"
      ),
      otherContractsCount: t(
        "customer.customerView.SearchResultColumnNumberOfOtherContracts"
      )
    }),
    [t]
  );

  const tabs = useMemo(
    () => [
      {
        label: t("customerAccount.FactoringDebtorAccountDetails"),
        component: <FactoringDebtorAccDetailsForm t={t} />,
        viewSelector: factoringDebtorAccount,
        viewType: FACTORING_DEBTOR_ACCOUNT,
        tabSelector: details,
        tabType: DETAILS
      },
      {
        label: t("customerAccount.FactoringDebtorAccountClearing"),
        component: <FactoringDebtorAccClearingTable />,
        viewSelector: factoringDebtorAccount,
        viewType: FACTORING_DEBTOR_ACCOUNT,
        tabSelector: clearing,
        tabType: CLEARING
      }
    ],
    [t]
  );

  return { filterLabels, tabs };
};

export default useFactoringDebtorAccountConsts;
