import React from "react";
import { useTranslation } from "react-i18next";
import useTabState from "../../../common/redux/hooks/useTabState";
import {
  factoringDebtorOverdueInvoices,
  paymentHistory
} from "../../../common/redux/types/selectorTypes";
import useTabTableActionDispatchers from "../../../common/redux/actions/table/useTabTableActionDispatchers";
import {
  FACTORING_INVOICES,
  PAYMENT_HISTORY
} from "../../../common/redux/types/viewTypes";
import Table from "../../../common/table";
import { TableResultData } from "../../../common/tableFooter";

const FactoringDebtorOverdueInvoicesPaymentHistoryTable = props => {
  const { t } = useTranslation();
  // const {selectedItem} = useDataState(factoringDebtorOverdueInvoices);
  const { table, selectedItem, data } = useTabState(
    factoringDebtorOverdueInvoices,
    paymentHistory
  );
  const {
    handleTableHeightSet,
    handleRowSelect
  } = useTabTableActionDispatchers(FACTORING_INVOICES, PAYMENT_HISTORY);

  const FactoringInvoicesTableTrans = "factoring.invoices.";
  const columns = [
    {
      id: "date",
      Header: t("factoring.invoices.InvoiceDate"),
      accessor: d => d.invoiceDate
    },
    {
      id: "payment",
      Header: t("factoring.invoices.Payment"),
      accessor: d => d.payment
    },
    {
      id: "balance",
      Header: t("factoring.invoices.Balance"),
      accessor: d => d.balance
    },
    {
      id: "paymentBalance",
      Header: t("factoring.invoices.PaymentBalance"),
      accessor: d => d.paymentBalance
    }
  ];
  console.log(data, "DATA");
  return (
    <React.Fragment>
      <Table
        columns={columns}
        data={[]}
        height={table.height}
        setTableHeight={handleTableHeightSet}
        onRowSelect={handleRowSelect}
        selectedRow={selectedItem}
        selectedRowsState={[]}
      />
      <TableResultData
        count={0}
        enabled={true}
        onRowSelect={handleRowSelect}
        selectedRow={selectedItem}
      />
    </React.Fragment>
  );
};

export default FactoringDebtorOverdueInvoicesPaymentHistoryTable;
