import React, {useMemo} from "react";
import PartnerSectionDetailsForm from "../details/partnerSectionDetailsForm";
import {useTranslation} from "react-i18next";
import PartnerSectionAddressesForm from "../addresses/partnerSectionAddressesForm";
import PartnerSectionInvoiceSettingsForm from "../partnerInvoiceSettings/partnerSectionInvoiceSettingsForm";
import PartnerSectionContractDataForm from "../contractData/partnerSectionContractDataForm";
import PartnerSectionProductsAndPriceConditionsForm
  from "../productsAndPriceConditions/partnerSectionProductsAndPriceConditionsForm";
import {
  addresses,
  contractData,
  details,
  partner,
  partnerAndInvoiceSettings,
  productPriceConditions
} from "../../common/redux/types/selectorTypes";
import {
  ADDRESSES,
  CONTRACT_DATA,
  DETAILS,
  PARTNER,
  PARTNER_AND_INVOICE_SETTINGS,
  PRODUCT_PRICE_CONDITIONS
} from "../../common/redux/types/viewTypes";

const usePartnerConsts = () => {
  const { t } = useTranslation();

  const filterLabels = {
    name: t("customer.customerView.FilterCustomerName"),
    id: t("customer.customerView.FilterCustomerId"),
    woCusManager: t("customer.customerView.WithoutCustomerManager"),
    regNo: t("customer.customerView.FilterRegNo"),
    vatNo: t("customer.customerView.VatNo"),
    contactPerson: t("customer.customerView.FilterContactPerson"),
    resultCount: t("customer.customerView.FilterResultCount"),
    generalPhone: t("customer.customerView.FilterGeneralPhone"),
    generalEmail: t("customer.customerView.FilterGeneralOrInvoiceEmail"),
    salesAgent: t("customer.customerView.SalesAgent"),
    customerManager: t("customer.customerView.CustomerManager"),
    withoutCustomerManager: t("customer.customerView.WithoutCustomerManager"),
    isActive: t("customer.customerView.FilterIsActive"),
    validFrom: t("customer.customerView.FilterCustomerValidFrom"),
    portOneContractsCount: t(
      "customer.customerView.SearchResultColumnNumberOfP1Contracts"
    ),
    otherContractsCount: t(
      "customer.customerView.SearchResultColumnNumberOfOtherContracts"
    )
  };

  const tabs = useMemo(() => {
    const data = [
      {
        label: t("partner.PartnerDetailData"),
        component: <PartnerSectionDetailsForm t={t} />,
        viewSelector: partner,
        viewType: PARTNER,
        tabSelector: details,
        tabType: DETAILS
      },
      {
        label: t("partner.PartnerAddresses"),
        component: <PartnerSectionAddressesForm t={t} />,
        viewSelector: partner,
        viewType: PARTNER,
        tabSelector: addresses,
        tabType: ADDRESSES
      },
      {
        label: t("partner.PartnerPartnerInvoiceSettings"),
        component: <PartnerSectionInvoiceSettingsForm t={t} />,
        viewSelector: partner,
        viewType: PARTNER,
        tabSelector: partnerAndInvoiceSettings,
        tabType: PARTNER_AND_INVOICE_SETTINGS
      },
      {
        label: t("partner.PartnerContractData"),
        component: <PartnerSectionContractDataForm t={t} />,
        viewSelector: partner,
        viewType: PARTNER,
        tabSelector: contractData,
        tabType: CONTRACT_DATA
      },
      {
        label: t("partner.PartnerProductsAndConditions"),
        component: <PartnerSectionProductsAndPriceConditionsForm t={t} />,
        viewSelector: partner,
        viewType: PARTNER,
        tabSelector: productPriceConditions,
        tabType: PRODUCT_PRICE_CONDITIONS

      }
    ];
    return data;
  }, [t]);

  return { tabs, filterLabels };
};

export default usePartnerConsts;
