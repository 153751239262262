import { useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  TOGGLE_SERVICE_EXPANDERS,
  TOGGLE_SERVICE_SEARCH,
  TOGGLE_SERVICE_TABLE
} from "../../graphql/serviceDataQueries";

const useServiceLayoutActions = () => {
  const dispatch = useDispatch();

  const handleTableToggle = useCallback(() => {
    dispatch(TOGGLE_SERVICE_TABLE());
  }, [dispatch]);

  const handleSearchToggle = useCallback(() => {
    dispatch(TOGGLE_SERVICE_SEARCH());
  }, [dispatch]);

  const handleExpandersToggle = useCallback(() => {
    dispatch(TOGGLE_SERVICE_EXPANDERS());
  }, [dispatch]);

  return { handleTableToggle, handleSearchToggle, handleExpandersToggle };
};

export default useServiceLayoutActions;
