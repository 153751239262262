import React from "react";
import { useTranslation } from "react-i18next";
import Table from "../../../../common/table";

const ClearingAddModalTable = () => {
  const { t } = useTranslation();
  const columns = [
    {
      id: "clearingDocDate",
      Header: t("clearing.EntryDate"),
      accessor: d => d.entryDate
    },
    {
      id: "description",
      Header: t("clearing.Description"),
      accessor: d => d.description
    },
    {
      id: "originalAmount",
      Header: t("clearing.OriginalAmount"),
      accessor: d => d.originalAmount
    },
    {
      id: "remainingBalance",
      Header: t("clearing.RemainingBalance"),
      accessor: d => d.remainingBalance
    },
    {
      id: "paymentDueDate",
      Header: t("clearing.PaymentDueDate"),
      accessor: d => d.paymentDueDate
    },
    {
      id: "entryDescription",
      Header: t("clearing.EntryDescription"),
      accessor: d => d.entryDescription
    }
  ];

  return (
    <React.Fragment>
      <Table
        classes="u-margin-bottom-small"
        data={[]}
        selectedRow={{}}
        onRowSelect={() => {}}
        setTableHeight={() => {}}
        height={120}
        columns={columns}
      />
    </React.Fragment>
  );
};

export default ClearingAddModalTable;
