import React, { forwardRef } from "react";
import { Formik } from "formik";
import {
  BoolSelectInputField,
  InputField,
  SelectInputField
} from "../../../common/form/form";
import { useTranslation } from "react-i18next";

const PartnerVerticalSearchForm = forwardRef(({ handleSearch, query }, ref) => {
  const { t } = useTranslation();
  return (
    <div className="section__search-form">
      <Formik
        ref={ref}
        enableReinitialize={true}
        initialValues={{}}
        onSubmit={(values, { setSubmitting }) => {
          alert("SUBMIT");
        }}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          validateField,
          validateOnChange,
          errors,
          touched,
          setFieldValue
        }) => (
          <form onSubmit={handleSubmit}>
            <InputField
              id="partnerSearchPartnerNo"
              name="partner_no"
              classes="form__group-stack"
              label={t("partner.PartnerNo")}
              value={""}
              onChange={handleChange}
            />
            <SelectInputField
              id="partnerSearchPartnerName"
              name="partner_name"
              classes="form__group-stack"
              options={[{ name: "", value: "" }]}
              label={t("partner.PartnerName")}
              value={""}
              onChange={handleChange}
            />
            <InputField
              id="partnerSearchRegNo"
              name="reg_no"
              classes="form__group-stack"
              label={t("partner.PartnerFilterRegNo")}
              value={""}
              onChange={handleChange}
            />
            <SelectInputField
              id="partnerSearchCountry"
              name="country"
              classes="form__group-stack"
              options={[{ name: "", value: "" }]}
              label={t("partner.PartnerFilterCountry")}
              value={""}
              onChange={handleChange}
            />
            <InputField
              id="partnerSearchVatPayerNo"
              name="vatPayerNo"
              classes="form__group-stack"
              label={t("partner.VATPayerNo")}
              value={""}
              onChange={handleChange}
            />
            <BoolSelectInputField
              id="partnerSearchIsActive"
              name="is_active"
              classes="form__group-stack"
              options={[{ name: "", value: "" }]}
              label={t("partner.IsActive")}
              value={""}
              onChange={handleChange}
            />
            <InputField
              id="partnerSearchResultCount"
              name="count"
              classes="form__group-stack"
              label={t("partner.RowCount")}
              value={""}
              onChange={handleChange}
            />
          </form>
        )}
      </Formik>
    </div>
  );
});

export default PartnerVerticalSearchForm;
