import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getContractDropdownData } from "../graphql/contractActions";
import useAuthState from "../../auth/hooks/useAuthState";

const useContractDropdownEffect = () => {
  const dispatch = useDispatch();
  const { userAuthenticated } = useAuthState();
  useEffect(() => {
    if (userAuthenticated) {
      dispatch(getContractDropdownData());
    }
  }, [dispatch, userAuthenticated]);
};

export default useContractDropdownEffect;
