export const LOAD_DATA_START = name => ({
  type: `LOAD_${name}_DATA_START`
});

export const LOAD_DATA_SUCCESS = (name, payload) => ({
  type: `LOAD_${name}_DATA_SUCCESS`,
  payload: payload
});

export const LOAD_DATA_ERROR = (name, payload) => ({
  type: `LOAD_${name}_DATA_ERROR`,
  payload: payload
});

export const SUBMIT_QUERY = (name, payload) => ({
  type: `SUBMIT_${name}_QUERY`,
  payload: payload
});

export const LOAD_COMPANY_AUTOCOMPLETE_START = name => ({
  type: `LOAD_${name}_COMPANY_AUTOCOMPLETE_START`
});

export const LOAD_COMPANY_AUTOCOMPLETE_SUCCESS = (name, payload) => ({
  type: `LOAD_${name}_COMPANY_AUTOCOMPLETE_SUCCESS`,
  payload: payload
});
export const LOAD_COMPANY_AUTOCOMPLETE_ERROR = (name, payload) => ({
  type: `LOAD_${name}_COMPANY_AUTOCOMPLETE_ERROR`,
  payload: payload
});

export const CLEAR_COMPANY_AUTOCOMPLETE_DATA = name => ({
  type: `CLEAR_${name}_COMPANY_AUTOCOMPLETE_DATA`
});

export const CLEAR_COMPANY_AUTOCOMPLETE_SELECTED_VALUE = name => ({
  type: `CLEAR_${name}_COMPANY_AUTOCOMPLETE_SELECTED_VALUE`
});

export const SELECT_COMPANY_AUTOCOMPLETE_VALUE = (name, payload) => ({
  type: `SELECT_${name}_COMPANY_AUTOCOMPLETE_VALUE`,
  payload: payload
});

export const LOAD_CITY_AUTOCOMPLETE_START = (viewType) => ({
  type: `LOAD_${viewType}_CITY_AUTOCOMPLETE_START`
});

export const LOAD_CITY_AUTOCOMPLETE_SUCCESS = (viewType, payload) => ({
  type: `LOAD_${viewType}_CITY_AUTOCOMPLETE_SUCCESS`,
  payload: payload
});

export const LOAD_CITY_AUTOCOMPLETE_ERROR = (viewType, payload) => ({
  type: `LOAD_${viewType}_CITY_AUTOCOMPLETE_ERROR`,
  payload: payload
});


export const CLEAR_CITY_AUTOCOMPLETE_DATA = name => ({
  type: `CLEAR_${name}_CITY_AUTOCOMPLETE_DATA`
});

export const CLEAR_CITY_AUTOCOMPLETE_SELECTED_VALUE = name => ({
  type: `CLEAR_${name}_CITY_AUTOCOMPLETE_SELECTED_VALUE`
});

export const SELECT_CITY_AUTOCOMPLETE_VALUE = (name, payload) => ({
  type: `SELECT_${name}_CITY_AUTOCOMPLETE_VALUE`,
  payload: payload
});

export const LOAD_STREET_AUTOCOMPLETE_START = (viewType) => ({
  type: `LOAD_${viewType}_STREET_AUTOCOMPLETE_START`
});

export const LOAD_STREET_AUTOCOMPLETE_SUCCESS = (viewType, payload) => ({
  type: `LOAD_${viewType}_STREET_AUTOCOMPLETE_SUCCESS`,
  payload: payload
});

export const LOAD_STREET_AUTOCOMPLETE_ERROR = (viewType, payload) => ({
  type: `LOAD_${viewType}_STREET_AUTOCOMPLETE_ERROR`,
  payload: payload
});

