import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { SectionBody, SectionHeader, SectionMain } from "../../section";
import CustomerTable from "../../../customer/customers/customerTable";
import CustomerButtons from "../../../customer/customers/layout/main/customerButtons";
import CustomerDetailsForm from "../../../customer/customers/details/forms/customerDetailsForm";
import {
  addresses,
  clientNoEuTaxOffice,
  comments,
  contactInformation,
  contractOverview,
  customers,
  details,
  invoiceSettings,
  vatInfo
} from "../../redux/types/selectorTypes";
import {
  ADDRESSES,
  CLIENT_NO_EU,
  COMMENTS,
  CONTACT_INFORMATION,
  CONTRACT_OVERVIEW,
  CUSTOMERS,
  DETAILS,
  INVOICE_SETTINGS,
  VAT_INFO
} from "../../redux/types/viewTypes";
import CustomerFormAddresses from "../../../customer/customers/addresses/forms/customerFormAddresses";
import CustomerCommentsTable from "../../../customer/customers/comments/customerCommentsTable";
import CustomerContactInformation from "../../../customer/customers/contact_information/customerContactInformation";
import ClientNoEUform from "../../../customer/customers/clientNoEU/forms/clientNoEUform";
import CustomerContractOverviewTable from "../../../customer/customers/contractOverview/customerContractOverviewTable";
import CustomersInvoiceSettingsForm from "../../../customer/customers/invoice_settings/forms/customersInvoiceSettingsForm";
import CustomerVatInfoTable from "../../../customer/customers/misc/customerVatInfoTable";

const CustomerViewPlaceholderMainSection = () => {
  const { t } = useTranslation();
  const tableRef = useRef();
  const customerTabs = [
    {
      name: "details",
      label: t("customer.customerView.TabHeaderCustomerDetailData"),
      component: (
        <CustomerDetailsForm onChange={v => console.log(v)} data={{}} />
      ),
      viewSelector: customers,
      tabSelector: details,
      viewType: CUSTOMERS,
      tabType: DETAILS,
      permission: "exempt"
    },
    {
      name: "addresses",
      label: t("customer.customerView.TabHeaderCustomerAddressesData"),
      component: <CustomerFormAddresses data={{}} t={t} />,
      viewSelector: customers,
      tabSelector: addresses,
      viewType: CUSTOMERS,
      tabType: ADDRESSES,
      permission: "View.Customer.Addresses"
    },
    {
      name: "comments",
      label: t("customer.customerView.TabHeaderCustomerCommentData"),
      component: <CustomerCommentsTable data={{}} t={t} />,
      viewSelector: customers,
      tabSelector: comments,
      viewType: CUSTOMERS,
      tabType: COMMENTS,
      permission: "View.Customer.Comments"
    },
    {
      name: "contactInfo",
      label: t("customer.customerView.TabHeaderCustomerContactData"),
      component: <CustomerContactInformation data={{}} />,
      viewSelector: customers,
      tabSelector: contactInformation,
      viewType: CUSTOMERS,
      tabType: CONTACT_INFORMATION,
      permission: "View.Customer.ContactInformation"
    },
    {
      name: "clientNoEu",
      label: t("customer.clientNoEU.Header"),
      component: <ClientNoEUform t={t} />,
      viewSelector: customers,
      tabSelector: clientNoEuTaxOffice,
      viewType: CUSTOMERS,
      tabType: CLIENT_NO_EU,
      permission: "View.Customer.EuTaxOfficeNumber"
    },
    {
      name: "contractOverview",
      label: t("customer.contractOverview.Header"),
      component: <CustomerContractOverviewTable />,
      viewSelector: customers,
      tabSelector: contractOverview,
      viewType: CUSTOMERS,
      tabType: CONTRACT_OVERVIEW,
      permission: "View.Customer.ContractOverview"
    },
    {
      name: "invoiceSettings",
      label: t("customer.customerView.TabHeaderCustomerInvoiceSettings"),
      component: <CustomersInvoiceSettingsForm t={t} selectedRow={{}} />,
      viewSelector: customers,
      tabSelector: invoiceSettings,
      viewType: CUSTOMERS,
      tabType: INVOICE_SETTINGS,
      permission: "View.Customer.InvoiceSettings"
    },
    {
      name: "vatInfo",
      label: t("customer.customerVatData.HeaderVatInformation"),
      component: <CustomerVatInfoTable t={t} />,
      viewSelector: customers,
      tabSelector: vatInfo,
      viewType: CUSTOMERS,
      tabType: VAT_INFO,
      permission: "View.Customer.VatInformation"
    }
  ];

  return (
    <SectionMain toggleSearch={() => {}}>
      <SectionHeader
        sectionHeading={t("customer.customerView.Header")}
        getFilters={payload => {
          return payload;
        }}
        searchQuery={{}}
        filterLabels={[]}
        tableVisible={true}
        expandersVisible={false}
        tableEnabled={false}
        expandersEnabled={false}
        tabsEnabled={false}
        onToggleTable={() => {}}
        onToggleExpanders={() => {}}
        onToggleTabs={() => {}}
        onToggleSearch={() => {}}
        onSearch={() => {}}
        searchOpen={true}
      />

      <SectionBody
        TableComponent={CustomerTable}
        ButtonsComponent={CustomerButtons}
        shortcutsEnabled={false}
        selectedRow={{}}
        tableVisible={true}
        onRowSelect={() => {}}
        removeItem={() => {}}
        onToggleRow={() => {}}
        tableRef={tableRef}
        shortcuts={[]}
        expandersEnabled={false}
        tabs={customerTabs}
      />
    </SectionMain>
  );
};

export default CustomerViewPlaceholderMainSection;
