import React from "react";
import View from "../common/view";
import ClearingMainSection from "./layout/main/clearingMainSection";
import ClearingSearchSection from "./layout/search/clearingSearchSection";

const ClearingView = () => {
  return (
    <View
      MainSection={ClearingMainSection}
      SearchSection={ClearingSearchSection}
    />
  );
};

export default ClearingView;
