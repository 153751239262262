import React from "react";
import _ from "lodash";
import { Button } from "../../../../common/buttons/button";
import useCustomerInvoiceState from "../../hooks/useCustomerInvoiceState";
import { useTranslation } from "react-i18next";
import { ToggleContent } from "../../../../common/modal/ToggleContent";
import CustomerInvoiceGenerateInvoiceModal from "../modals/customerInvoiceGenerateInvoiceModal";

const CustomerInvoiceButtons = () => {
  const { selectedRow } = useCustomerInvoiceState();
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Button
        btnStyle="lime"
        size="small"
        classes="btn--footer"
        disabled={true}
        label={t("customerInvoice.BtnMergePDF")}
      />
      <Button
        btnStyle="lime"
        size="small"
        disabled={true}
        classes="btn--footer"
        label={t("customerInvoice.BtnSend")}
      />
      <ToggleContent
        toggle={show => (
          <Button
            btnStyle="lime"
            size="small"
            disabled={false}
            handleClick={show}
            classes="btn--footer"
            label={t("customerInvoice.BtnGenerateInvoice")}
          />
        )}
        content={hide => <CustomerInvoiceGenerateInvoiceModal hide={hide} />}
      />
      <Button
        btnStyle="lime"
        size="small"
        disabled={true}
        classes="btn--footer"
        label={t("customerInvoice.BtnToExcel")}
      />
      <Button
        btnStyle="lime"
        size="small"
        disabled={true}
        classes="btn--footer"
        label={t("customerInvoice.BtnToCSV")}
      />
      <Button
        btnStyle="green"
        size="small"
        disabled={true}
        classes="btn--footer"
        label={t("customerInvoice.BtnSave")}
      />
    </React.Fragment>
  );
};

export default CustomerInvoiceButtons;
