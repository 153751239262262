import React, { forwardRef } from "react";
import {
  BoolSelectInputField,
  DateInputField,
  InputField,
  SelectInputField
} from "../../../common/form/form";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";

const DiscountVerticalSearchForm = forwardRef(
  ({ handleSearch, query }, ref) => {
    const { t } = useTranslation();
    return (
      <Formik
        ref={ref}
        enableReinitialize={true}
        initialValues={{
          count: 1000
        }}
        onSubmit={(values, { setSubmitting }) => {
          console.log(values);
          handleSearch(values);
        }}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          validateField,
          validateOnChange,
          errors,
          touched,
          setFieldValue
        }) => (
          <form onSubmit={handleSubmit}>
            <InputField
              id="discountSearchCustomer"
              name="customer"
              label={t("discount.FilterCustomer")}
              onChange={handleChange}
              classes="form__group-stack"
            />
            <BoolSelectInputField
              id="discountSearchAddToInvoice"
              name="add_to_invoice"
              label={t("discount.AddToInvoice")}
              onChange={handleChange}
              value={""}
              classes="form__group-stack"
            />
            <InputField
              id="discountSearchBasedOnInvoiceNo"
              name="based_on_invoice_no"
              label={t("discount.FilterBasedOnInvoiceNo")}
              onChange={handleChange}
              value={""}
              classes="form__group-stack"
            />
            <InputField
              id="discountSearchIssuedForInvoiceNo"
              name="issued_for_invoice_no"
              label={t("discount.FilterIssuedForInvoiceNo")}
              onChange={handleChange}
              value={""}
              classes="form__group-stack"
            />
            <SelectInputField
              id="discountSearchServiceProvider"
              name="service_provider"
              label={t("discount.FilterServiceProvider")}
              options={[]}
              value={""}
              classes="form__group-stack"
              onChange={handleChange}
            />
            <SelectInputField
              id="discountSearchCountry"
              name="country"
              label={t("discount.FilterCountry")}
              options={[]}
              value={""}
              classes="form__group-stack"
              onChange={handleChange}
            />
            <SelectInputField
              id="discountSearchSalesPoint"
              name="salesPoint"
              label={t("discount.FilterSalesPoint")}
              options={[]}
              value={""}
              classes="form__group-stack"
              onChange={handleChange}
            />
            <DateInputField
              id="discountSearchCreatedFrom"
              name="created_from"
              label={t("discount.FilterCreatedFrom")}
              onChange={setFieldValue}
              value={""}
              classes="form__group-stack"
            />
            <DateInputField
              id="discountSearchCreatedTo"
              name="created_to"
              label={t("discount.FilterCreatedTo")}
              onChange={setFieldValue}
              value={""}
              classes="form__group-stack"
            />
            <DateInputField
              id="discountSearchTransactionTimeFrom"
              name="transaction_time_from"
              label={t("discount.TransactionTimeFrom")}
              onChange={setFieldValue}
              value={""}
              classes="form__group-stack"
            />
            <DateInputField
              id="discountSearchTransactionTimeTo"
              name="transaction_time_to"
              label={t("discount.TransactionTimeTo")}
              onChange={setFieldValue}
              value={""}
              classes="form__group-stack"
            />
            <BoolSelectInputField
              id="discountSearchIsActive"
              name="is_active"
              label={t("discount.FilterIsActive")}
              onChange={handleChange}
              value={""}
              classes="form__group-stack"
            />
            <InputField
              type="number"
              id="discountSearchResultCount"
              name="count"
              label={t("discount.ResultsNo")}
              onChange={handleChange}
              value={values.count}
              classes="form__group-stack"
            />
          </form>
        )}
      </Formik>
    );
  }
);

export default DiscountVerticalSearchForm;
