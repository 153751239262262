import React, { forwardRef } from "react";
import { Formik } from "formik";
import {
  BoolSelectInputField,
  DateInputField,
  InputField,
  SelectInputField
} from "../../../common/form/form";
import useContractSearchDropdowns from "../hooks/useContractSearchDropdowns";
import { loadCompanyAutocompleteData } from "../../../common/autocomplete/graphql/fetchCustomerAutocomplete";
import AutoCompleteField from "../../../common/autocomplete/autocompleteField";

const ContractVerticalSearchForm = forwardRef(
  ({ handleSearch, t, query, resellers, serviceProviders }, ref) => {
    const {
      getContractStatusesData,
      getContractTypesData,
      getCountriesData
    } = useContractSearchDropdowns();

    return (
      <Formik
        ref={ref}
        enableReinitialize={true}
        initialValues={{
          count: query.count || 1000,
          customer: query.customer || "",
          customer_no: query.customer_no || "",
          contract_type: query.contract_type || "",
          contract_no: query.contract_no || "",
          contract_status: query.contract_status || "",
          expires_from: query.expires_from || "",
          expires_until: query.expires_until || "",
          reseller: query.reseller || "",
          service_provider: query.service_provider || "",
          customer_country: query.customer_country || "",
          sales_agent: query.sales_agent || "",
          is_active: query.isActive || "true"
        }}
        onSubmit={(values, { setSubmitting }) => {
          handleSearch(values);
        }}
      >
        {({
          values,
          errors,
          status,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue
        }) => (
          <form className="flex__direction--column" onSubmit={handleSubmit}>
            <AutoCompleteField
              type="text"
              classes="form__group"
              id="contractSearchCustomer"
              name="customer"
              value={values.customer}
              handleChange={setFieldValue}
              label={t("contract.FilterContractCustomer")}
              autocomplete="password"
              data-lpignore="true"
              fetchAutcompleteData={loadCompanyAutocompleteData}
            />

            <InputField
              type="text"
              classes="form__group-stack"
              id="contractSearchCustomerNo"
              name="customer_no"
              value={values.customer_no}
              onChange={handleChange}
              label={t("contract.FilterCustomerNo")}
            />
            <SelectInputField
              type="text"
              classes="form__group-stack"
              id="contractSearchContractType"
              name="contract_type"
              options={getContractTypesData}
              value={values.contract_type}
              onChange={handleChange}
              label={t("contract.FilterContractType")}
            />
            <InputField
              type="text"
              classes="form__group-stack"
              id="contractSearchContractNo"
              name="contract_no"
              value={values.contract_no}
              onChange={handleChange}
              label={t("contract.FilterContractNo")}
            />
            <SelectInputField
              type="text"
              classes="form__group-stack"
              id="contract_status"
              options={getContractStatusesData}
              value={values.contract_status}
              onChange={handleChange}
              label={t("contract.FilterContractStatus")}
            />

            <DateInputField
              type="date"
              classes="form__group-stack"
              id="contractSearchExpiresFrom"
              name="expires_from"
              value={values.expires_from}
              handleChange={setFieldValue}
              label={t("contract.FilterContractExpiresFrom")}
            />
            <DateInputField
              type="date"
              classes="form__group-stack"
              id="contractSearchExpiresUntil"
              name="expires_until"
              value={values.expires_until}
              handleChange={setFieldValue}
              label={t("contract.FilterContractExpiresUntil")}
            />
            <SelectInputField
              classes="form__group-stack"
              options={[{ name: "", value: "" }, ...resellers]}
              id="contractSearchReseller"
              name="reseller"
              value={values.reseller}
              onChange={handleChange}
              label={t("contract.FilterContractReseller")}
            />

            <SelectInputField
              classes="form__group-stack"
              options={[{ name: "", value: "" }, ...serviceProviders]}
              id="contractServiceProvider"
              value={values.contractServiceProvider}
              onChange={handleChange}
              label={t("contract.FilterContractServiceProvider")}
            />
            <SelectInputField
              classes="form__group-stack"
              id="customer_country"
              options={getCountriesData}
              value={values.customer_country}
              onChange={handleChange}
              label={t("contract.FilterCustomerCountry")}
            />
            <SelectInputField
              classes="form__group-stack"
              options={["", "yes", "no"]}
              id="contractSearchSalesAgent"
              name="sales_agent"
              value={values.sales_agent}
              onChange={handleChange}
              label={t("contract.FilterSalesAgent")}
            />
            <BoolSelectInputField
              classes="form__group-stack"
              id="contractSearchIsActive"
              name="is_active"
              value={values.is_active}
              onChange={handleChange}
              label={t("contract.FilterIsActive")}
            />
            <InputField
              type="number"
              classes="form__group-stack"
              id="contractSearchResultCount"
              name="count"
              value={values.count}
              onChange={handleChange}
              label={t("contract.FilterRowCount")}
            />
          </form>
        )}
      </Formik>
    );
  }
);

export default ContractVerticalSearchForm;
