import React from "react";
import { Route, Switch, withRouter } from "react-router";
import Layout from "./components/layout/Layout";
import Login from "./components/auth/login";
import "./css/react-table.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import "simplebar/dist/simplebar.min.css";
import { Logout } from "./components/auth/logout";
import "react-datepicker/dist/react-datepicker.css";
import useAppLayout from "./components/layout/hooks/useAppLayout";
import useLanguageSwitchEffect from "./components/layout/hooks/useLanguageSwitchEffect";
import useLoadAppDataEffect from "./components/layout/hooks/useLoadAppDataEffect";
import useUserAuthEffect from "./components/auth/hooks/useUserAuthEffect";
import useAuthState from "./components/auth/hooks/useAuthState";
import useContractDropdownEffect from "./components/contract/hooks/useContractDropdownEffect";
import useServiceDropdownData from "./components/service/hooks/useServiceDropdownData";
import NotFound from "./components/common/routes/notFound";
import CustomerViewPlaceholder from "./components/common/placeholders/customerViewPlaceholder/customerViewPlaceholder";
import useDbUpTimeCheck from "./components/layout/hooks/useDbUpTimeCheck";
import useAppRedirect from "./components/layout/hooks/useAppRedirect";

function App() {
  const { loginModalOpen, userAuthenticated } = useAuthState();
  const { appLayoutConfig } = useAppLayout();

  useDbUpTimeCheck();
  useUserAuthEffect();
  useLoadAppDataEffect();
  useLanguageSwitchEffect();
  useContractDropdownEffect();
  useServiceDropdownData();
  useAppRedirect(appLayoutConfig);

  // useEffect(() => {
  //     window.addEventListener("resize", () => {
  //         const width = window.innerWidth;
  //         const height = window.innerHeight;
  //         console.log(width, height);
  //     })
  //
  //     return window.removeEventListener("resize", () => {})
  // }, []);
  return (
    <React.Fragment>
      {userAuthenticated ? (
        <ToastContainer
          autoClose={3000}
          position={toast.POSITION.TOP_CENTER}
          className=""
        />
      ) : (
        ""
      )}
      <Route path="/logout" exact component={Logout} />
      <Login />
      <Layout classes={loginModalOpen ? "layout__blur" : ""}>
        <Switch>
          <Route exact path="/" component={CustomerViewPlaceholder} />

          {appLayoutConfig.map(comp => {
            return (
              <Route
                key={comp.link}
                exact
                path={comp.link}
                component={comp.mainComponent}
              />
            );
          })}
          {appLayoutConfig.length ? (
            <Route path="*" component={NotFound} />
          ) : (
            ""
          )}
        </Switch>
      </Layout>
    </React.Fragment>
  );
}

export default withRouter(App);
