import React, { useRef } from "react";
import {
  SectionBody,
  SectionHeader,
  SectionMain
} from "../../../../common/section";
import useCustomerDepositLayoutActions from "../../hooks/layout/useCustomerDepositLayoutActions";
import { useTranslation } from "react-i18next";
import useCustomerDepositState from "../../hooks/useCustomerDepositState";
import useCustomerDepositConsts from "../../hooks/useCustomerDepositConsts";
import useCustomerDepositLayoutState from "../../hooks/layout/useCustomerDepositLayoutState";
import CustomerDepositTable from "../../customerDepositTable";
import CustomerDepositButtons from "./customerDepositButtons";
import useCustomerDepositActions from "../../hooks/useCustomerDepositActions";
import useCustomerDepositUtils from "../../hooks/useCustomerDepositUtils";

const CustomerDepositMainSection = () => {
  const tableRef = useRef();
  const { t } = useTranslation();
  const { searchQuery, selectedRow } = useCustomerDepositState();
  const { handleRowSelect, handleRowToggle } = useCustomerDepositActions();
  const { filterLabels, tabs } = useCustomerDepositConsts();
  const {
    tableVisible,
    expandersVisible,
    searchOpen
  } = useCustomerDepositLayoutState();
  const {
    handleTableToggle,
    handleExpandersToggle,
    handleSearchToggle
  } = useCustomerDepositLayoutActions();
  const { getFilters } = useCustomerDepositUtils();

  return (
    <SectionMain toggleSearch={handleSearchToggle}>
      <SectionHeader
        sectionHeading={t("customerDeposit.Header")}
        getFilters={getFilters}
        searchQuery={searchQuery}
        filterLabels={filterLabels}
        tableVisible={tableVisible}
        expandersVisible={expandersVisible}
        tableEnabled={true}
        expandersEnabled={true}
        tabsEnabled={false}
        onToggleTable={handleTableToggle}
        onToggleExpanders={handleExpandersToggle}
        onToggleSearch={handleSearchToggle}
        searchOpen={searchOpen}
      />

      <SectionBody
        TableComponent={CustomerDepositTable}
        ButtonsComponent={CustomerDepositButtons}
        shortcutsEnabled={false}
        selectedRow={selectedRow}
        selectedItem={selectedRow.customer ? selectedRow.customer[0].name : ""}
        tableVisible={tableVisible}
        onRowSelect={handleRowSelect}
        onToggleRow={handleRowToggle}
        tableRef={tableRef}
        expandersEnabled={expandersVisible}
        tabs={tabs}
      />
    </SectionMain>
  );
};

export default CustomerDepositMainSection;
