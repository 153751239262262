import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {bankImport, general} from "../../../common/redux/types/selectorTypes";
import {BANK_IMPORT, GENERAL} from "../../../common/redux/types/viewTypes";
import BankImportGeneralForm from "../general/forms/BankImportGeneralForm";
import useDataState from "../../../common/redux/hooks/useDataState";

const useBankImportConsts = () => {
    const { t } = useTranslation();
    const {selectedItem} = useDataState(bankImport);


    const tabs = useMemo(() => {
        const data = [
            {
                label: t("bankFileImport.BankFileImport"),
                component: <BankImportGeneralForm t={t} data={selectedItem}/>,
                viewSelector: bankImport,
                viewType: BANK_IMPORT,
                tabSelector: general,
                tabType: GENERAL
            }

        ];
        return data;
    }, [t, selectedItem]);

    return {tabs };
};

export default useBankImportConsts;
