import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import Table from "../../../common/table";
import { SET_CUSTOMERS_CONTACT_INFO_TABLE_HEIGHT } from "./actions/customerContactInformationActions";
import useTabState from "../../../common/redux/hooks/useTabState";
import { customers } from "../../../common/redux/types/selectorTypes";
import useDataState from "../../../common/redux/hooks/useDataState";

const CustomerContactInformationTable = ({
  t,
  handleRowClick,
  selectedRow
}) => {
  const dispatch = useDispatch();

  const { table } = useTabState(customers, "contactInformation");
  const onSetTableHeight = payload => {
    dispatch(SET_CUSTOMERS_CONTACT_INFO_TABLE_HEIGHT(payload));
  };
  const { selectedItem } = useDataState(customers);
  // useEffect(() => {
  //   var index = selectedRow.index;
  //   if (index < selectedItem.customerContactPerson.length) {
  //     handleRowClick(
  //       Object.assign(
  //         { index: selectedRow.index },
  //         selectedItem.customerContactPerson[0]
  //       )
  //     );
  //   } else {
  //     handleRowClick(
  //       Object.assign({ index: 0 }, selectedItem.customerContactPerson[0])
  //     );
  //   }
  // }, [selectedItem]);

  const getContactPersonType = type => {
    switch (type) {
      case "CONTACT_PERSON_TYPE.BOARD_MEMBER": {
        return "board member";
      }
      case "CONTACT_PERSON_TYPE.CONTACT_PERSON": {
        return "contact person";
      }
      case "CONTACT_PERSON_TYPE.ACCOUNTANT": {
        return "accountant";
      }
      default: {
        return "NONE";
      }
    }
  };
  //√
  const CustomerContactTrans = "customer.customerContactData.";
  const columns = [
    {
      Header: t(CustomerContactTrans + "PersonName"),
      accessor: "name"
    },
    {
      id: "contactPersonTypeCl",
      Header: t(CustomerContactTrans + "Position"),
      accessor: data => getContactPersonType(data.contactPersonTypeCl)
    },
    {
      id: "hasSignatureRight",
      Header: t(CustomerContactTrans + "PersonSignatory"),
      accessor: data => (data.hasSignatureRight ? "√" : "")
    },
    {
      id: "isPrimary",
      Header: t(CustomerContactTrans + "Primary"),
      accessor: data => (data.isPrimary ? "√" : ""),
      width: 70
    }
  ];

  const sortColumn = "name";
  return (
    <div className="customer__comments__table">
      <Table
        classes="u-margin-right-small"
        height={182}
        setTableHeight={onSetTableHeight}
        columns={columns}
        onRowSelect={handleRowClick}
        selectedRow={selectedRow}
        data={selectedItem.customerContactPerson || []}
        sortColumn={sortColumn}
      />
    </div>
  );
};

export default CustomerContactInformationTable;
