import {useSelector} from "react-redux";

const useFormsState = name => {
  const formsState = useSelector(state => state[name].forms.forms_state);
  const dataChanged = useSelector(state => state[name].forms.dataChanged);

  return {formsState, dataChanged};
};

export default useFormsState;
