import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { loadData } from "../../../common/graphql/loadData";
import { FACTORING_DEBTOR } from "../../../common/redux/types/viewTypes";
import { fetchFactoringDebtorData } from "../graphql/factoringDebtorDataQueries";

const useFactoringDebtorActions = () => {
  const dispatch = useDispatch();
  const handleSearch = payload => {
    dispatch(
      loadData(payload, FACTORING_DEBTOR, "companies", fetchFactoringDebtorData)
    );
  };

  return {
    handleSearch
  };
};

export default useFactoringDebtorActions;
