import React from "react";
import { useTranslation } from "react-i18next";
import { Loading } from "../../common/loading";
import Table from "../../common/table";
import { numberWithSpaces } from "../../../services/numberFunctions";
import { TableResultData } from "../../common/tableFooter";
import useDataState from "../../common/redux/hooks/useDataState";
import { factoringDebtor } from "../../common/redux/types/selectorTypes";
import useTableActionDispatchers from "../../common/redux/actions/table/hooks/useTableActionDispatchers";
import { FACTORING_DEBTOR } from "../../common/redux/types/viewTypes";
import useTableLayoutActionDispatchers from "../../common/redux/actions/layout/table/hooks/useTableLayoutActionDispatchers";
import useTableState from "../../common/redux/hooks/useTableState";

const FactoringDebtorTable = () => {
  const { t } = useTranslation();
  const { items, selectedItem, isLoading } = useDataState(factoringDebtor);
  const { handleRowSelect } = useTableActionDispatchers(FACTORING_DEBTOR);
  const { handleTableHeightSet } = useTableLayoutActionDispatchers(
    FACTORING_DEBTOR
  );
  const { height } = useTableState(factoringDebtor);

  const FactoringDebtorTableTrans = "factoring.debtor.";
  const columns = [
    {
      id: "regNo",
      Header: t(FactoringDebtorTableTrans + "RegNo"),
      accessor: d => d.regNo || "",
      width: 100
    },
    {
      Header: t(FactoringDebtorTableTrans + "Customer"),
      accessor: "name"
    },
    {
      id: "legalAddress",
      Header: t(FactoringDebtorTableTrans + "Country"),
      accessor: d =>
        d.legalAddress
          ? t(`countries.${d.legalAddress[0].country[0].code}`)
          : ""
    },
    {
      id: "isActiveCl",
      Header: t(FactoringDebtorTableTrans + "IsActive"),
      accessor: d => t(d.isActiveCl)
    },
    {
      Header: t(FactoringDebtorTableTrans + "CustomerValidFrom"),
      accessor: "createdOn"
    },
    {
      id: "balance",
      Header: t(FactoringDebtorTableTrans + "CurrentBalance"),
      cellAlign: "right",
      accessor: d => (d.balance ? numberWithSpaces(d.balance.toFixed(2)) : "")
    },
    {
      id: "debt",
      Header: t(FactoringDebtorTableTrans + "CurrentDebt"),
      cellAlign: "right",
      accessor: d => (d.debt ? numberWithSpaces(d.debt.toFixed(2)) : "")
    },
    {
      id: "creditLimit",
      Header: t(FactoringDebtorTableTrans + "Limit"),
      cellAlign: "right",
      accessor: d => (d.creditLimit ? numberWithSpaces(d.creditLimit) : "")
    },
    {
      id: "creditLimitUsed",
      Header: t(FactoringDebtorTableTrans + "CreditLimitUsedPercentage"),
      cellAlign: "right",
      accessor: d => {
        return numberWithSpaces(
          (d.creditLimit - d.remainingCreditLimit).toFixed(2)
        );
      }
    },
    {
      id: "remainingCreditLimit",
      Header: t(FactoringDebtorTableTrans + "CreditLimitFree"),
      cellAlign: "right",
      accessor: d =>
        d.remainingCreditLimit
          ? numberWithSpaces(d.remainingCreditLimit.toFixed(2))
          : ""
    }
  ];

  return (
    <React.Fragment>
      <Table
        columns={columns}
        data={items}
        height={height}
        setTableHeight={handleTableHeightSet}
        isLoading={isLoading}
        loadingComponent={Loading}
        onRowSelect={handleRowSelect}
        selectedRow={selectedItem}
        selectedRowsState={[]}
      />
      <TableResultData
        count={items.length}
        selectedRows={[]}
        onRowSelect={handleRowSelect}
        selectedRow={selectedItem}
        onRemoveItem={[]}
      />
    </React.Fragment>
  );
};

export default FactoringDebtorTable;
