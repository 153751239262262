import React, { forwardRef } from "react";
import { Formik } from "formik";

import {
  BoolSelectInputField,
  DateInputField,
  InputField,
  SelectInputField
} from "../../../../common/form/form";
import AutoCompleteField from "../../../../common/autocomplete/autocompleteField";
import { loadCompanyAutocompleteData } from "../../../../common/autocomplete/graphql/fetchCustomerAutocomplete";

const FactoringInvoicesVerticalSearchForm = forwardRef(
  ({ t, handleSearch }, ref) => {
    return (
      <Formik
        ref={ref}
        enableReinitialize={true}
        initialValues={{
          count: 1000
        }}
        onSubmit={(values, { setSubmitting }) => {
          handleSearch(values);
        }}
      >
        {({
          values,
          errors,
          status,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue
        }) => (
          <form
            className="flex__flex-direction--column"
            onSubmit={handleSubmit}
          >
            <AutoCompleteField
              type="text"
              id="factoringInvoicesCustomerSearch"
              name="customer"
              classes="form__group-stack"
              value={values.customer}
              handleChange={setFieldValue}
              label={t("factoring.invoices.Customer")}
              fetchAutcompleteData={loadCompanyAutocompleteData}
            />
            <SelectInputField
              id="factoringInvoicesSearchFactoringDebtor"
              classes="form__group-stack"
              options={[{ name: "", value: "" }]}
              name="factoring_debtor"
              value={values.factoring_debtor}
              onChange={handleChange}
              label={t("factoring.invoices.FactoringDebtor")}
            />
            <InputField
              type="text"
              id="factoringInvoicesSearchInvoiceNo"
              name="invoice_no"
              classes="form__group-stack"
              value={values.invoice_no}
              onChange={handleChange}
              label={t("factoring.invoices.InvoiceNo")}
            />
            <InputField
              type="text"
              id="factoringInvoicesSearchContractAnnexNo"
              name="contract_annex_no"
              classes="form__group-stack"
              value={values.contract_annex_no}
              onChange={handleChange}
              label={t("factoring.invoices.ContractAnnexNo")}
            />
            <BoolSelectInputField
              id="factoringInvoicesSearchIsInContractAnnex"
              name="is_in_contract_annex"
              classes="form__group-stack"
              value={values.is_in_contract_annex}
              onChange={handleChange}
              label={t("factoring.invoices.FilterIsInContractAnnex")}
            />
            <BoolSelectInputField
              id="factoringInvoicesSearchIsItPaid"
              name="is_it_paid"
              classes="form__group-stack"
              value={values.is_it_paid}
              onChange={handleChange}
              label={t("factoring.invoices.FilterIsPaid")}
            />
            <DateInputField
              type="date"
              id="factoringInvoicesSearchDateFrom"
              classes="form__group-stack"
              name="date_from"
              value={values.date_from}
              handleChange={setFieldValue}
              label={t("factoring.invoices.DateFrom")}
            />
            <DateInputField
              type="date"
              id="factoringInvoicesSearchDateTo"
              classes="form__group-stack"
              name="date_to"
              value={values.date_to}
              handleChange={setFieldValue}
              label={t("factoring.invoices.DateTo")}
            />
            <DateInputField
              type="date"
              id="dueDateFrom"
              classes="form__group-stack"
              value={values.dueDateFrom}
              handleChange={setFieldValue}
              label={t("factoring.invoices.DueDateFrom")}
            />
            <DateInputField
              type="date"
              id="factoringInvoicesSearchDueDateTo"
              name="due_date_to"
              classes="form__group-stack"
              value={values.due_date_to}
              handleChange={setFieldValue}
              label={t("factoring.invoices.DueDateTo")}
            />
            <BoolSelectInputField
              id="factoringInvoicesSearchIsSummaryInvoice"
              classes="form__group-stack"
              name="is_summary_invoice"
              value={values.is_summary_invoice}
              onChange={handleChange}
              label={t("factoring.invoices.FilterIsSummaryInvoice")}
            />
            <BoolSelectInputField
              id="factoringInvoicesSearchHasCancellation"
              classes="form__group-stack"
              name="has_cancellation"
              value={values.has_cancellation}
              onChange={handleChange}
              label={t("factoring.invoices.FilterHasCancellation")}
            />

            <InputField
              id="factoringInvoicesSearchResultCount"
              classes="form__group-stack"
              nam="count"
              value={values.count}
              onChange={handleChange}
              label={t("factoring.debtor.FilterRowCount")}
            />
          </form>
        )}
      </Formik>
    );
  }
);

export default FactoringInvoicesVerticalSearchForm;
