import {useDispatch} from "react-redux";

const useFactoringDebtorAccountUtils = () => {
  const dispatch = useDispatch();
  // const onSetTableHeight = useMemo(
  //   () => payload => {
  //     dispatch(SET_FACTORING_DEBTOR_ACC_TABLE_HEIGHT(payload));
  //   },
  //   [dispatch]
  // );
  // const onToggleSearch = () => {
  //   dispatch(TOGGLE_FACTORING_DEBTOR_ACC_SEARCH());
  // };
  //
  // const onToggleTable = useCallback(() => {
  //   dispatch(TOGGLE_FACTORING_DEBTOR_ACC_TABLE());
  // }, [dispatch]);
  //
  // const onToggleExpanders = useCallback(() => {
  //   dispatch(TOGGLE_FACTORING_DEBTOR_ACC_EXPANDERS());
  // }, [dispatch]);

  const getFactoringDebtorAccountFilters = payload => {
    return payload;
  };

  return {

    getFactoringDebtorAccountFilters

  };
};

export default useFactoringDebtorAccountUtils;
