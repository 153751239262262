import React, { forwardRef, useMemo } from "react";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import {
  BoolSelectInputField,
  DateInputField,
  InputField,
  SelectInputField
} from "../../../../common/form/form";
import { loadCompanyAutocompleteData } from "../../../../common/autocomplete/graphql/fetchCustomerAutocomplete";
import AutoCompleteField from "../../../../common/autocomplete/autocompleteField";

const CustomerInvoiceSearchVerticalForm = forwardRef(
  ({ handleSearch, query, countryList, invoiceStatusTypes }, ref) => {
    const CustomerInvoiceSearchFormTrans = "customerInvoice.";
    const { t } = useTranslation();

    const invoiceStatusTypesData = invoiceStatusTypes
      ? invoiceStatusTypes
      : [{ name: "", value: "" }];

    const getInvoiceStatusTypesData = useMemo(
      () => [
        { code: t("countries.None"), id: "" },
        ...invoiceStatusTypesData.map(item => {
          return Object.assign(
            {},
            {
              name: t(
                `classifications.CONTRACT_STATUS.${item.value.split(".")[1]}`
              ),
              value: item.value
            }
          );
        })
      ],
      [invoiceStatusTypesData, t]
    );

    return (
      <Formik
        ref={ref}
        enableReinitialize={true}
        initialValues={{
          count: query.count || 1000,
          customer: query.customer || "",
          invoice: query.invoice || "",
          invoice_status: query.invoice_status || "",
          issued_country: query.issued_country || "",
          date_from: query.date_from || "",
          date_to: query.date_to || "",
          is_it_paid: query.is_it_paid || "",
          is_virtual: query.is_virtual || "",
          reseller: query.reseller || "",
          dueDateUntil: query.dueDateUntil || "",
          customer_country: query.customer_country || "",
          salesAgent: query.salesAgent || ""
        }}
        onSubmit={(values, { setSubmitting }) => {
          console.log(values);
          console.log(countryList);
          handleSearch(values);
        }}
      >
        {({
          values,
          errors,
          status,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue
        }) => (
          <form className="flex__direction--column" onSubmit={handleSubmit}>
            <AutoCompleteField
              type="text"
              classes="form__group-stack"
              id="customerInvoicesSearchCustomer"
              name="customer"
              value={values.customer}
              handleChange={setFieldValue}
              label={t("customerAccount.details.Client")}
              autocomplete="password"
              data-lpignore="true"
              fetchAutcompleteData={loadCompanyAutocompleteData}
            />
            <InputField
              label={t(CustomerInvoiceSearchFormTrans + "InvoiceNo")}
              classes="form__group-stack"
              id="customerInvoicesSearchInvoiceNo"
              name="invoice"
              onChange={handleChange}
              value={values.invoice}
            />
            <SelectInputField
              label={t(CustomerInvoiceSearchFormTrans + "InvoiceStatus")}
              options={getInvoiceStatusTypesData}
              classes="form__group-stack"
              id="customerInvoicesSearchInvoiceStatus"
              name="invoice_status"
              onChange={handleChange}
              value={values.invoice_status}
            />
            <SelectInputField
              label={t(CustomerInvoiceSearchFormTrans + "FilterIssuedCountry")}
              type="number"
              options={
                countryList.length
                  ? [
                      { code: t("countries.None"), id: "" },
                      ...countryList.map(item => {
                        return Object.assign(
                          {},
                          {
                            code: t(`countries.${item.code}`),
                            value: item.code
                          }
                        );
                      })
                    ]
                  : []
              }
              customNameKey="code"
              classes="form__group-stack"
              id="customerInvoicesSearchIssuedCountry"
              name="issued_country"
              onChange={handleChange}
              value={values.issued_country}
            />
            <DateInputField
              type="date"
              label={t(CustomerInvoiceSearchFormTrans + "DateFrom")}
              classes="form__group-stack"
              id="customerInvoicesSearchDateFrom"
              name="date_from"
              handleChange={setFieldValue}
              value={values.date_from}
            />
            <DateInputField
              type="date"
              label={t(CustomerInvoiceSearchFormTrans + "DateTo")}
              classes="form__group-stack"
              id="customerInvoicesSearchDateTo"
              name="date_to"
              handleChange={setFieldValue}
              value={values.date_to}
            />
            <BoolSelectInputField
              label={t(CustomerInvoiceSearchFormTrans + "FilterIsPaid")}
              classes="form__group-stack"
              id="customerInvoicesSearcghIsItPaid"
              name="is_it_paid"
              onChange={handleChange}
              value={values.is_it_paid}
            />
            <SelectInputField
              label={t(CustomerInvoiceSearchFormTrans + "FilterIsVirtual")}
              options={[
                { name: "", value: "" },
                { name: t("common.ReplyYes"), value: "BOOLEAN.YES" },
                { name: t("common.ReplyNo"), value: "BOOLEAN.NO" }
              ]}
              classes="form__group-stack"
              id="customerInvoicesSearchIsVirtual"
              name="is_virtual"
              onChange={handleChange}
              value={values.is_virtual}
            />
            <SelectInputField
              label={t(CustomerInvoiceSearchFormTrans + "ResellerName")}
              options={["", "yes", "no"]}
              classes="form__group-stack"
              id="reseller"
              onChange={handleChange}
              value={values.reseller}
            />
            <DateInputField
              type="date"
              label={t(CustomerInvoiceSearchFormTrans + "DueDateUntil")}
              classes="form__group-stack"
              id="dueDateUntil"
              placement="top"
              handleChange={setFieldValue}
              value={values.dueDateUntil}
            />
            <SelectInputField
              label={t(
                CustomerInvoiceSearchFormTrans + "FilterCustomerCountry"
              )}
              options={
                countryList.length
                  ? [
                      { code: t("countries.None"), id: "" },
                      ...countryList.map(item => {
                        return Object.assign(
                          {},
                          { code: t(`countries.${item.code}`), id: item.id }
                        );
                      })
                    ]
                  : []
              }
              customNameKey="code"
              customValueKey="id"
              classes="form__group-stack"
              name="customer_country"
              value={values.customer_country}
              id="customerInvoicesSearchCustomerCountry"
              onChange={handleChange}
            />
            <SelectInputField
              label={t(CustomerInvoiceSearchFormTrans + "SalesAgent")}
              options={[{ name: "", value: "" }]}
              classes="form__group-stack"
              id="salesAgent"
              onChange={handleChange}
              value={values.salesAgent}
            />
            <InputField
              label={t(CustomerInvoiceSearchFormTrans + "ResultsNo")}
              classes="form__group-stack"
              id="customerInvoicesSearchCount"
              name="count"
              onChange={handleChange}
              value={values.count}
            />
          </form>
        )}
      </Formik>
    );
  }
);
export default CustomerInvoiceSearchVerticalForm;
