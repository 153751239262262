import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { SELECT_VAT_APPLICATIONS_ROW } from "../actions/vatApplicationsActions";
import { getVatApplicationsData } from "../actions/queries/vatApplicationQueries";

const useVatApplicationsActions = () => {
  const dispatch = useDispatch();
  const handleSearch = useCallback(
    payload => {
      dispatch(getVatApplicationsData(payload));
    },
    [dispatch]
  );

  const handleRowSelect = useCallback(
    payload => {
      dispatch(SELECT_VAT_APPLICATIONS_ROW(payload));
    },
    [dispatch]
  );

  return { handleSearch, handleRowSelect };
};

export default useVatApplicationsActions;
