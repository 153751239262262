import React, { useCallback } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import useCustomersActions from "./useCustomersActions";
import CustomerDetailsForm from "../details/forms/customerDetailsForm";
import CustomerFormAddresses from "../addresses/forms/customerFormAddresses";
import CustomerCommentsTable from "../comments/customerCommentsTable";
import CustomerContactInformation from "../contact_information/customerContactInformation";
import ClientNoEUform from "../clientNoEU/forms/clientNoEUform";
import CustomerContractOverviewTable from "../contractOverview/customerContractOverviewTable";
import CustomerVatInfoTable from "../misc/customerVatInfoTable";
import useDataState from "../../../common/redux/hooks/useDataState";
import CustomersInvoiceSettingsForm from "../invoice_settings/forms/customersInvoiceSettingsForm";
import {
  addresses,
  clientNoEuTaxOffice,
  comments,
  contactInformation,
  contractOverview,
  customers,
  details,
  invoiceSettings,
  vatInfo
} from "../../../common/redux/types/selectorTypes";
import {
  ADDRESSES,
  CLIENT_NO_EU,
  COMMENTS,
  CONTACT_INFORMATION,
  CONTRACT_OVERVIEW,
  CUSTOMERS,
  DETAILS,
  INVOICE_SETTINGS,
  VAT_INFO
} from "../../../common/redux/types/viewTypes";
import useUserData from "../../../auth/hooks/useUserData";
import CommentsTab from "../../../common/tabComponents/comments/commentsTab";

const useCustomersConsts = () => {
  const { t } = useTranslation();

  const {
    getCustomerInvoicesShortcutData,
    getCustomerAccountShortcutData
  } = useCustomersActions();

  const { selectedItem } = useDataState(customers);
  const { userPermissions } = useUserData();

  const customersShortcuts = [
    {
      name: t("customer.shortcuts.Invoices"),
      link: "/customerinvoice",
      callback: getCustomerInvoicesShortcutData
    },
    {
      name: t("customer.shortcuts.Account"),
      link: "/customeraccount",
      callback: getCustomerAccountShortcutData
    }
  ];

  const customersLabels = {
    name: t("customer.customerView.FilterCustomerName"),
    id: t("customer.customerView.FilterCustomerId"),
    reg_no: t("customer.customerView.FilterRegNo"),
    vat_no: t("customer.customerView.VatNo"),
    contact_person: t("customer.customerView.FilterContactPerson"),
    count: t("customer.customerView.FilterResultCount"),
    general_phone: t("customer.customerView.FilterGeneralPhone"),
    email: t("customer.customerView.FilterGeneralOrInvoiceEmail"),
    sales_agent: t("customer.customerView.SalesAgent"),
    customer_manager: t("customer.customerView.CustomerManager"),
    without_customer_manager: t("customer.customerView.WithoutCustomerManager"),
    is_active: t("customer.customerView.FilterIsActive"),
    valid_from: t("customer.customerView.FilterCustomerValidFrom"),
    portOneContractsCount: t(
      "customer.customerView.SearchResultColumnNumberOfP1Contracts"
    ),
    otherContractsCount: t(
      "customer.customerView.SearchResultColumnNumberOfOtherContracts"
    )
  };

  const getTabs = useCallback(() => {
    const tabs = [
      {
        name: "details",
        label: t("customer.customerView.TabHeaderCustomerDetailData"),
        component: (
          <CustomerDetailsForm
            onChange={v => console.log(v)}
            data={!_.isEmpty(selectedItem) ? selectedItem : {}}
          />
        ),
        viewSelector: customers,
        tabSelector: details,
        viewType: CUSTOMERS,
        tabType: DETAILS,
        permission: "exempt"
      },
      {
        name: "addresses",
        label: t("customer.customerView.TabHeaderCustomerAddressesData"),
        component: <CustomerFormAddresses data={selectedItem || {}} t={t} />,
        viewSelector: customers,
        tabSelector: addresses,
        viewType: CUSTOMERS,
        tabType: ADDRESSES,
        permission: "View.Customer.Addresses"
      },
      {
        name: "comments",
        label: t("customer.customerView.TabHeaderCustomerCommentData"),
        component: (
          <CommentsTab
            data={selectedItem || {}}
            viewType={CUSTOMERS}
            viewSelector={customers}
            tabType={COMMENTS}
            tabSelector={comments}
          />
        ),
        viewSelector: customers,
        tabSelector: comments,
        viewType: CUSTOMERS,
        tabType: COMMENTS,
        permission: "View.Customer.Comments"
      },
      {
        name: "contactInfo",
        label: t("customer.customerView.TabHeaderCustomerContactData"),
        component: <CustomerContactInformation data={selectedItem} />,
        viewSelector: customers,
        tabSelector: contactInformation,
        viewType: CUSTOMERS,
        tabType: CONTACT_INFORMATION,
        permission: "View.Customer.ContactInformation"
      },
      {
        name: "clientNoEu",
        label: t("customer.clientNoEU.Header"),
        component: <ClientNoEUform t={t} />,
        viewSelector: customers,
        tabSelector: clientNoEuTaxOffice,
        viewType: CUSTOMERS,
        tabType: CLIENT_NO_EU,
        permission: "View.Customer.EuTaxOfficeNumber"
      },
      {
        name: "contractOverview",
        label: t("customer.contractOverview.Header"),
        component: <CustomerContractOverviewTable />,
        viewSelector: customers,
        tabSelector: contractOverview,
        viewType: CUSTOMERS,
        tabType: CONTRACT_OVERVIEW,
        permission: "View.Customer.ContractOverview"
      },
      {
        name: "invoiceSettings",
        label: t("customer.customerView.TabHeaderCustomerInvoiceSettings"),
        component: (
          <CustomersInvoiceSettingsForm t={t} selectedRow={selectedItem} />
        ),
        viewSelector: customers,
        tabSelector: invoiceSettings,
        viewType: CUSTOMERS,
        tabType: INVOICE_SETTINGS,
        permission: "View.Customer.InvoiceSettings"
      },
      {
        name: "vatInfo",
        label: t("customer.customerVatData.HeaderVatInformation"),
        component: <CustomerVatInfoTable t={t} />,
        viewSelector: customers,
        tabSelector: vatInfo,
        viewType: CUSTOMERS,
        tabType: VAT_INFO,
        permission: "View.Customer.VatInformation"
      }
    ];
    if (userPermissions === undefined) {
      return [];
    } else {
      return [
        ...tabs.filter(item => {
          return userPermissions.find(
            x => x.key === item.permission || item.permission === "exempt"
          );
        })
      ];
    }
  }, [selectedItem, t, userPermissions]);

  const customersTabs = getTabs();

  return { customersShortcuts, customersLabels, customersTabs };
};

export default useCustomersConsts;
