import React from "react";
import Table from "../../../common/table";
import useTabState from "../../../common/redux/hooks/useTabState";
import { customers } from "../../../common/redux/types/selectorTypes";
import { COMMENTS, CUSTOMERS } from "../../../common/redux/types/viewTypes";
import useTabTableActionDispatchers from "../../../common/redux/actions/table/useTabTableActionDispatchers";
import { Button } from "../../../common/buttons/button";
import { FormFlexWrapper } from "../../../common/form/form";

const CustomerCommentsTable = ({ data, t }) => {
  const { table } = useTabState(customers, "comments");
  console.log(data, "COMMEBNTS");
  const { selectedItem } = useTabState(customers, "comments");
  const { handleTableHeightSet } = useTabTableActionDispatchers(
    CUSTOMERS,
    COMMENTS
  );
  const CustomerComments = "customer.customerComments.";
  const columns = [
    {
      Header: t(CustomerComments + "HeaderModifiedOn"),
      accessor: "createdOn",
      width: 80
    },
    {
      Header: t(CustomerComments + "HeaderModifiedByUser"),
      accessor: "createdByUserId",
      width: 80
    },
    {
      Header: t(CustomerComments + "HeaderComment"),
      accessor: "comment",
      width: 900
    }
  ];

  return (
    <div style={{ padding: "0.2rem 0rem 0.8rem 0rem" }}>
      <Table
        classes="u-margin-top-small"
        height={table.height}
        setTableHeight={handleTableHeightSet}
        columns={columns}
        data={data.customerComments || []}
        selectedRow={selectedItem}
        onRowSelect={() => {}}
      />
      <FormFlexWrapper classes="u-margin-top-tiny">
        <Button
          classes="btn--footer"
          btnStyle="primary"
          size="medium"
          label="Add"
        />
        <Button
          classes="btn--footer u-margin-left-tiny"
          label="Delete"
          btnStyle="danger"
          size="medium"
        />
      </FormFlexWrapper>
    </div>
  );
};

export default CustomerCommentsTable;
