import {authorizationHeaders} from "../../../../services/queries";
import axios from "axios";
import {
    LOAD_CITY_AUTOCOMPLETE_ERROR,
    LOAD_CITY_AUTOCOMPLETE_START,
    LOAD_CITY_AUTOCOMPLETE_SUCCESS
} from "../../redux/actions/search/searchActions";

export const loadCityAutocompleteData = async payload => {
    const data = await axios.post(
        "/graphql",
        {
            query: `
        query {
  cities(name:"${payload}"){
    id
    name
  }
}
      `
        },
        authorizationHeaders()
    );
    console.log(data);
    return data.data.data.cities;
};

export const cityAutocompleteQuery = (name, payload) => {
    return dispatch => {
        dispatch(LOAD_CITY_AUTOCOMPLETE_START(name));
        loadCityAutocompleteData(payload)
            .then(data => dispatch(LOAD_CITY_AUTOCOMPLETE_SUCCESS(name, data)))
            .catch(error => dispatch(LOAD_CITY_AUTOCOMPLETE_ERROR(name, error)));
    };
};
