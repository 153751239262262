import React from "react";
import View from "../../common/view";
import BrokerClaimsMainsSection from "./layout/main/brokerClaimsMainSection";
import BrokerClaimsSearchSection from "./layout/search/brokerClaimsSearchSection";

const BrokerClaimsView = () => {
  return (
    <View
      MainSection={BrokerClaimsMainsSection}
      SearchSection={BrokerClaimsSearchSection}
    />
  );
};

export default BrokerClaimsView;
