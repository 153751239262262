import {useSelector} from "react-redux";

const useContractState = () => {
  const isLoading = useSelector(state => state.contract.data.isLoading);
  const searchQuery = useSelector(state => state.contract.search.query);
  const contractData = useSelector(state => state.contract.data.items);
  const selectedRow = useSelector(state => state.contract.data.selectedItem);
  const contractTypes = useSelector(
    state => state.layout.classifications.contract_types
  );
  const contractStatuses = useSelector(
    state => state.layout.classifications.contract_status_types
  );
  const resellers =
    useSelector(state => state.contract.search.dropdowns.resellers) || [];
  const serviceProviders =
    useSelector(state => state.contract.search.dropdowns.serviceProviders) ||
    [];
  const countries = useSelector(
    state => state.layout.classifications.countries
  );
  // const statusHistoryData = useSelector(
  //   state => state.contract.selectedRow.contractStatusHistory
  // );

  return {
    isLoading,
    searchQuery,
    contractData,
    selectedRow,
    contractTypes,
    contractStatuses,
    resellers,
    serviceProviders,
    countries
    // statusHistoryData
  };
};

export default useContractState;
