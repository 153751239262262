import React, { useLayoutEffect, useMemo } from "react";
import SimpleBar from "simplebar-react";
import { AppliedFilters } from "./appliedFilters";
import { ToggleSwitchWLabel } from "./buttons/toggleSwitch";
import { useTranslation } from "react-i18next";
import { Tabs } from "react-tabs";
import {
  SectionTab,
  SectionTabList,
  SectionTabPanel
} from "./tabs/sectionTabs";
import Expander from "./expander";
import { TableFooter, TableFooterWShortcuts } from "./tableFooter";
import { ButtonReset, ButtonSubmit } from "./buttons/button";

export const Section = ({ toggleSearch, children }) => {
  const handleKeyDown = useMemo(
    () => e => {
      if (
        (window.navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey) &&
        e.keyCode === 83
      ) {
        e.preventDefault();
        alert("CONTROL + S");
      } else if (
        (window.navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey) &&
        e.keyCode === 70
      ) {
        e.preventDefault();
        document.activeElement.blur();
        toggleSearch();
      }
    },
    [toggleSearch]
  );

  useLayoutEffect(() => {
    window.addEventListener("keydown", handleKeyDown, false);
    return () => {
      window.removeEventListener("keydown", handleKeyDown, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <div className="section">{children}</div>;
};

export const SectionMain = ({ children, toggleSearch }) => {
  return (
    <div className="section__main">
      <Section toggleSearch={toggleSearch}>{children}</Section>
    </div>
  );
};

export const SectionHead = props => {
  return <div className="section__header">{props.children}</div>;
};

export const SectionHeadBtnGroup = props => {
  return <div className="section__header__btn-group">{props.children}</div>;
};

export const SectionHeading = ({ heading }) => {
  return <h1 className="heading__primary">{heading}</h1>;
};

export const SectionBody = ({
  TableComponent,
  ButtonsComponent,
  shortcutsEnabled,
  selectedRow,
  tableVisible,
  onRowSelect,
  removeItem,
  onToggleRow,
  disclaimer,
  tableRef,
  shortcuts,
  expandersEnabled,
  selectedItem,
  tabs,
  tableDisabled
}) => {
  return (
    <div className="section__body">
      <div>
        {tableVisible && !tableDisabled ? (
          <TableComponent
            onRowSelect={onRowSelect}
            removeItem={removeItem}
            onToggleRow={onToggleRow}
            tableRef={tableRef}
          />
        ) : (
          ""
        )}
        {shortcutsEnabled ? (
          <TableFooterWShortcuts
            selectedItem={selectedRow.name || ""}
            selectedObj={selectedRow || {}}
            shortcuts={shortcuts}
          >
            <SectionBtnGroup>
              <ButtonsComponent />
            </SectionBtnGroup>
          </TableFooterWShortcuts>
        ) : !tableDisabled ? (
          <TableFooter selectedItem={selectedItem || ""}>
            <SectionBtnGroup>
              <ButtonsComponent />
            </SectionBtnGroup>
          </TableFooter>
        ) : (
          ""
        )}

        {tableDisabled && ButtonsComponent ? (
          <SectionBtnGroup>
            <ButtonsComponent />
          </SectionBtnGroup>
        ) : (
          ""
        )}
      </div>
      <div style={{ marginBottom: "0.5rem", fontSize: "1rem" }}>
        {disclaimer}
      </div>
      <SectionData>
        <SectionDataComponent
          expandersEnabled={expandersEnabled}
          items={tabs}
        />
      </SectionData>
    </div>
  );
};

export const SectionData = props => {
  return <div className="section__body-data">{props.children}</div>;
};

export const SectionBtnGroup = props => {
  return <div className="section__header__btn-group">{props.children}</div>;
};

export const SectionDataComponent = ({ items, expandersEnabled }) => {
  if (!expandersEnabled) {
    return (
      <Tabs>
        <SectionTabList>
          {items.map(item => {
            return <SectionTab key={item.label}>{item.label}</SectionTab>;
          })}
        </SectionTabList>
        <SimpleBar style={{ height: "100%", overflowX: "hidden" }}>
          {items.map(item => {
            return (
              <SectionTabPanel key={item.label}>
                {item.component}
              </SectionTabPanel>
            );
          })}
        </SimpleBar>
      </Tabs>
    );
  } else {
    return (
      <div className="expander__container">
        <SimpleBar style={{ overflowX: "hidden", height: "100%" }}>
          {items.map(item => {
            return (
              <Expander
                key={item.label}
                heading={item.label}
                viewType={item.viewType}
                tabType={item.tabType}
                viewSelector={item.viewSelector}
                tabSelector={item.tabSelector}
              >
                {item.component}
              </Expander>
            );
          })}
        </SimpleBar>
      </div>
    );
  }
};

export const SectionHeader = ({
  sectionHeading,
  getFilters,
  searchQuery,
  filterLabels,
  tableEnabled,
  expandersEnabled,
  tabsEnabled,
  onToggleTable,
  onToggleExpanders,
  onToggleTabs,
  onSetTabsState,
  onToggleSearch,
  onSearch,
  tableVisible,
  expandersVisible,
  searchOpen
}) => {
  const { t } = useTranslation();
  return (
    <SectionHead>
      <SectionHeading heading={sectionHeading} />
      <AppliedFilters
        items={getFilters(searchQuery, t)}
        labels={filterLabels}
      />
      <div className="form--flex--header">
        {tableEnabled ? (
          <ToggleSwitchWLabel
            label="Table"
            classes="u-margin-right-small"
            handleOnChange={() => onToggleTable()}
            value={tableVisible}
          />
        ) : (
          ""
        )}
        {expandersEnabled ? (
          <ToggleSwitchWLabel
            label={t("common.DetailsView")}
            classes="u-margin-right-small"
            handleOnChange={() => onToggleExpanders()}
            value={expandersVisible}
          />
        ) : (
          ""
        )}
        {tabsEnabled ? (
          <ToggleSwitchWLabel
            label={t("common.Tabs")}
            classes="u-margin-right-small"
            handleOnChange={e => onSetTabsState(e)}
            value={expandersVisible}
          />
        ) : (
          ""
        )}
        {/*<Search*/}
        {/*  isOpen={searchOpen}*/}
        {/*  toggleOpen={onToggleSearch}*/}
        {/*  t={t}*/}
        {/*  classes={"u-margin-left-small"}*/}
        {/*/>*/}
      </div>
    </SectionHead>
  );
};

export const SearchSection = ({
  children,
  isOpen,
  formRef,
  clearingEnabled,
  onSearchToggle,
  dataLoading,
  searchDisabled
}) => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        // transform: isOpen ? "translateX(0)" : "translateX(100rem)",
        transition: "width 0.3s",
        // flexGrow: isOpen ? "1" : "0",
        width: isOpen ? "20rem" : "0"
      }}
    >
      <div className="section__search">
        <SimpleBar
          style={{
            display: "flex",
            height: "100%",
            width: "100%",
            overflowX: "auto"
          }}
          className="section__search--form"
        >
          {children}
        </SimpleBar>

        <div className="search__toggle-bubble" onClick={() => onSearchToggle()}>
          <span className="search__toggle-bubble--arrow">
            <img
              style={{ transform: isOpen ? "rotate(-90deg)" : "rotate(90deg)" }}
              className="search__icon--img search__toggle-bubble--arrow--icon"
              src={process.env.PUBLIC_URL + "/assets/icons/arrow-down.svg"}
              alt="search_icon"
            />
          </span>
        </div>

        {clearingEnabled ? (
          <div className="section__search--util-wrapper">
            <ButtonSubmit
              label={t("customer.customerView.BtnSearch")}
              handleSubmit={() => {
                formRef.current.handleSubmit();
              }}
              classes="section__search--util-wrapper--searchBtn btn--small u-margin-right-tiny"
            />
            <ButtonReset
              label="Clear"
              classes="btn--small"
              handleReset={() => formRef.current.resetForm()}
            />
          </div>
        ) : (
          <div className="section__search--util-wrapper">
            <ButtonSubmit
              label={t("customer.customerView.BtnSearch")}
              handleSubmit={() => {
                formRef.current.handleSubmit();
              }}
              classes="section__search--util-wrapper--searchBtn"
              disabled={dataLoading || searchDisabled}
            />
          </div>
        )}
      </div>
    </div>
  );
};
