import React from "react";
import Modal from "../../../../common/modal/Modal";
import { useTranslation } from "react-i18next";
import CustomerInvoiceGenerateInvoiceModalForm from "./customerInvoiceGenerateInvoiceModalForm";
import { FormCol, FormFlexWrapper } from "../../../../common/form/form";
import CustomerInvoiceGenerateInvoiceModalTable from "./customerInvoiceGenerateInvoiceModalTable";
import { Button } from "../../../../common/buttons/button";
import AutoCompleteField from "../../../../common/autocomplete/autocompleteField";
import { loadCompanyAutocompleteData } from "../../../../common/autocomplete/graphql/fetchCustomerAutocomplete";

const CustomerInvoiceGenerateInvoiceModal = ({ hide }) => {
  const { t } = useTranslation();
  return (
    <Modal>
      <h2 className="heading__secondary">Arve genereerimine</h2>
      <FormFlexWrapper>
        <FormCol className="form__col50">
          <CustomerInvoiceGenerateInvoiceModalForm t={t} />
        </FormCol>
        <FormCol className="u-margin-left-small">
          <AutoCompleteField
            id="customerInvoiceModalCustomerFilter"
            classes="form__group"
            style={{ margin: "1rem 0", maxWidth: "20rem" }}
            label="Kliendi nimi"
            handleChange={() => {}}
            fetchAutcompleteData={loadCompanyAutocompleteData}
          />
          <div style={{ marginTop: "auto" }}>
            <p className="u-margin-bottom-tiny" style={{ fontSize: "1.2rem" }}>
              Märgi kliendid, kellele arve koostatakse
            </p>
            <CustomerInvoiceGenerateInvoiceModalTable />
          </div>
          <FormFlexWrapper
            style={{
              justifyContent: "flex-end",
              marginTop: "2rem",
              padding: "1rem"
            }}
          >
            <Button
              btnStyle="primary"
              size="medium"
              disabled={true}
              classes="btn--footer"
              label="Otsi kliendid"
            />
            <Button
              btnStyle="lime"
              size="medium"
              disabled={true}
              label="Genereeri"
              classes="btn--footer u-margin-left-small"
            />
            <Button
              btnStyle="danger"
              handleClick={hide}
              label="Tagasi"
              size="medium"
              classes="btn--footer u-margin-left-small"
            />
          </FormFlexWrapper>
        </FormCol>
      </FormFlexWrapper>
    </Modal>
  );
};

export default CustomerInvoiceGenerateInvoiceModal;
