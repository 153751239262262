import { useCallback } from "react";
import jwt from "jwt-decode";
import { useDispatch } from "react-redux";
import {
  getUserData,
  TOGGLE_LOGIN_MODAL_OPEN,
  USER_LOGIN_SET_TOKEN
} from "../actions/authActions";
import {
  getInstallationPermissions,
  SET_INSTALLATION_PERMISSIONS
} from "../../layout/actions/layoutActions";

const useAuthActions = () => {
  const dispatch = useDispatch();

  const setReduxToken = useCallback(
    payload => dispatch(USER_LOGIN_SET_TOKEN(payload)),
    [dispatch]
  );

  const loadUserData = useCallback(
    payload => dispatch(getUserData(jwt(payload).id)),
    [dispatch]
  );

  const loadInstallationPermissions = useCallback(
    () =>
      getInstallationPermissions().then(d =>
        dispatch(SET_INSTALLATION_PERMISSIONS(d))
      ),
    [dispatch]
  );

  const toggleLoginModalOpen = useCallback(() => {
    dispatch(TOGGLE_LOGIN_MODAL_OPEN());
  }, [dispatch]);

  return {
    setReduxToken,
    loadUserData,
    loadInstallationPermissions,
    toggleLoginModalOpen
  };
};

export default useAuthActions;
