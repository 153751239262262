import React from "react";
import {
  DateInputField,
  FormBody,
  FormCol,
  FormFlexWrapper,
  InputField,
  SelectInputField
} from "../../../common/form/form";
import { Formik } from "formik";
import { Button } from "../../../common/buttons/button";

const FactoringInvoicesSectionDetailsForm = ({ t, data }) => {
  const FactoringInvoiceDetailsTrans = "factoring.invoices.details.";
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        customer: data.customer ? data.customer[0].name : "",
        factoringDebtor: data.factoringDebtor
          ? data.factoringDebtor[0].name
          : "",
        entryDate: data.invoiceDate || "",
        invoiceNo: data.invoiceNo ? data.invoiceNo : "",
        invoiceDate: data.invoiceDate ? data.invoiceDate : "",
        total: data.total ? data.total : "",
        annexNo: data.contract ? data.contract[0].contractNo : "",
        isSummaryInvoice:
          typeof data.isSummaryInvoice === "boolean"
            ? data.isSummaryInvoice
            : "",
        dueDate: data.dueDate ? data.dueDate : ""
      }}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          alert(JSON.stringify(values, null, 2));
          setSubmitting(false);
        }, 400);
      }}
    >
      {({
        values,
        errors,
        status,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue
      }) => (
        <FormBody>
          <FormFlexWrapper>
            <FormCol>
              <InputField
                type="text"
                label={t(FactoringInvoiceDetailsTrans + "Customer")}
                classes="form__group"
                value={values.customer}
                onChange={handleChange}
                id="customer"
              />
              <InputField
                type="date"
                label={t(FactoringInvoiceDetailsTrans + "InvoiceDate")}
                classes="form__group"
                value={values.invoiceDate}
                onChange={handleChange}
                id="invoiceDate"
              />
              <InputField
                type="text"
                label={t(FactoringInvoiceDetailsTrans + "ContractAnnexNo")}
                classes="form__group"
                value={values.annexNo}
                onChange={handleChange}
                id="contractAnnexNo"
              />
            </FormCol>
            <FormCol className="u-margin-left-small">
              <InputField
                type="text"
                label={t(FactoringInvoiceDetailsTrans + "FactoringDebtor")}
                classes="form__group"
                value={values.factoringDebtor}
                onChange={handleChange}
                id="factoringDebtor"
              />
              <DateInputField
                id="factoringInvoicesDetailsDueDate"
                name="dueDate"
                label={t(FactoringInvoiceDetailsTrans + "DueDate")}
                classes="form__group"
                value={values.dueDate}
                onChange={setFieldValue}
              />
              <DateInputField
                id="factoringInvoicesDetailsEntryDate"
                name="entryDate"
                label={t(FactoringInvoiceDetailsTrans + "EntryDate")}
                classes="form__group"
                value={values.invoiceDate}
                onChange={setFieldValue}
              />
            </FormCol>
            <FormCol className="u-margin-left-small">
              <InputField
                type="text"
                label={t(FactoringInvoiceDetailsTrans + "InvoiceNo")}
                classes="form__group"
                value={values.invoiceNo}
                onChange={handleChange}
                id="invoiceNo"
              />
              <InputField
                type="text"
                label={t(FactoringInvoiceDetailsTrans + "Total")}
                classes="form__group"
                value={values.total}
                onChange={handleChange}
                id="total"
              />
              <SelectInputField
                label={t(FactoringInvoiceDetailsTrans + "SummaryInvoice")}
                options={[
                  { name: "", value: "" },
                  { name: t("common.ReplyYes"), value: "true" },
                  { name: t("common.ReplyNo"), value: "false" }
                ]}
                classes="form__group"
                id="isSummaryInvoice"
                name="isSummaryInvoice"
                onChange={handleChange}
                value={values.isSummaryInvoice}
              />
            </FormCol>
          </FormFlexWrapper>
          <FormFlexWrapper
            direction="column"
            style={{
              borderTop: "1px solid rgba(0,0,0,.2)",
              marginTop: "0.5rem",
              paddingTop: "0.5rem"
            }}
          >
            <h4 className="heading__quaternary">Faktooringu tühistamine</h4>
            <FormFlexWrapper>
              <InputField
                id="factoringInvoicesFactoringCancellationSum"
                name="cancel_sum"
                classes="form__group u-margin-right-tiny"
                value={values.cancel_sum}
                label="Summa"
              />
              <InputField
                id="factoringInvoicesFactoringCancellationInvoiceNo"
                name="cancel_invoice_no"
                classes="form__group u-margin-right-tiny"
                value={values.cancel_invoice_no}
                label="Arve nr."
              />
              <InputField
                id="factoringInvoicesFactoringCancellationDescription"
                name="cancel_description"
                classes="form__group u-margin-right-small"
                value={values.cancel_description}
                label="Selgitus"
              />
              <Button
                btnStyle="primary"
                label="Küsi vahe tagasi"
                size="small"
                style={{
                  whiteSpace: "nowrap",
                  height: "50%",
                  alignSelf: "center"
                }}
                classes="btn--footer"
                disabled={true}
              />
            </FormFlexWrapper>
          </FormFlexWrapper>
        </FormBody>
      )}
    </Formik>
  );
};
export default FactoringInvoicesSectionDetailsForm;
