import React from "react";
import { Formik } from "formik";
import {
  BoolSelectInputField,
  DateInputField,
  FormBody,
  FormCol,
  FormFlexItem,
  FormFlexWrapper,
  InputField,
  SelectInputField,
  TextareaInputField
} from "../../common/form/form";
import { Button } from "../../common/buttons/button";
import useAppClassifications from "../../layout/hooks/useAppClassifications";

const CardSectionDetailsForm = ({ t, data }) => {
  const CardSectionDetailsTrans = "card.details.";
  const { cardStatuses, cardTypes } = useAppClassifications();
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        customer: data.customer ? data.customer[0].name : "",
        chipCurrentStatusCl: data.chipCurrentStatusCl || "",
        magneticStripeCurrentStatusCl: data.magneticStripeCurrentStatusCl || "",
        vehicleRegistrationNo: data.vehicleRegistrationNo || "",
        vehicleRegistrationNoForInvoice:
          data.vehicleRegistrationNoForInvoice || "",
        cardType: data.cardType ? data.cardType[0].cardTypeCl : "",
        chipNo: data.chipNo || "",
        magneticStripeNo: data.magneticStripeNo || "",
        isActiveCl: data.isActiveCl || "",
        chipLastUsedDate: data.chipLastUsedDate || "",
        validFrom: data.validFrom || "",
        validThru: data.validThru || "",
        additionalInfo: data.additionalInfo || ""
      }}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          alert(JSON.stringify(values, null, 2));
          setSubmitting(false);
        }, 400);
      }}
    >
      {({
        values,
        errors,
        status,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting
      }) => (
        <FormBody>
          <FormFlexWrapper>
            <FormCol>
              <InputField
                id="cardDetailsCustomer"
                name="customer"
                label={t(CardSectionDetailsTrans + "CustomerName")}
                classes="form__group"
                options={["KAIU AUTO AS"]}
                onChange={handleChange}
                value={values.customer}
              />
              <SelectInputField
                id="cardDetailsChipCurrentStatus"
                name="chipCurrentStatusCl"
                label={t(CardSectionDetailsTrans + "CardChipCurrentStatus")}
                classes="form__group"
                value={values.chipCurrentStatusCl}
                options={[
                  { name: "", value: "" },
                  ...cardStatuses.map(item => {
                    return {
                      name: t(`classifications.${item.name}`),
                      value: item.value
                    };
                  })
                ]}
                onChange={handleChange}
              />
              <SelectInputField
                id="cardDetailsMagneticStripeCurrentStatus"
                name="magneticStripeCurrentStatusCl"
                label={t(
                  CardSectionDetailsTrans + "CardMagnetStripCurrentStatus"
                )}
                classes="form__group"
                value={values.magneticStripeCurrentStatusCl}
                options={[
                  { name: "", value: "" },
                  ...cardStatuses.map(item => {
                    return {
                      name: t(`classifications.${item.name}`),
                      value: item.value
                    };
                  })
                ]}
                onChange={handleChange}
              />
              <InputField
                id="cardDetailsVehicleRegistrationNo"
                name="vehicleRegistrationNo"
                value={values.vehicleRegistrationNo}
                label={t(CardSectionDetailsTrans + "CardCarRegNo")}
                classes="form__group"
                onChange={handleChange}
              />
            </FormCol>
            <FormCol className="u-margin-left-small">
              <FormFlexWrapper classes="form--flex-center form--space-between">
                <FormFlexItem flexGrow="1">
                  <SelectInputField
                    id="cardDetailsCardType"
                    name="cardType"
                    value={values.cardType}
                    label={t(CardSectionDetailsTrans + "CardTypeList")}
                    classes="form__group"
                    options={[
                      { name: "", value: "" },
                      ...cardTypes.map(item => {
                        return {
                          name: t(`classifications.${item.name}`),
                          value: item.value
                        };
                      })
                    ]}
                    onChange={handleChange}
                  />
                </FormFlexItem>
                <FormFlexItem>
                  <Button
                    size="small"
                    disabled={true}
                    btnStyle="primary"
                    classes="u-margin-left-tiny"
                    label={t(CardSectionDetailsTrans + "CardOpenApplication")}
                  />
                </FormFlexItem>
              </FormFlexWrapper>
              <FormFlexWrapper classes="form--flex-center form--space-between">
                <FormFlexItem flexGrow="1">
                  <InputField
                    id="cardDetailsChipNo"
                    name="chipNo"
                    value={values.chipNo}
                    label={t(CardSectionDetailsTrans + "CardChipNo")}
                    classes="form__group"
                    onChange={handleChange}
                  />
                </FormFlexItem>
                <FormFlexItem>
                  <Button
                    label={t(
                      CardSectionDetailsTrans + "CardGenerateChipNumber"
                    )}
                    size="small"
                    disabled={true}
                    classes="u-margin-left-tiny"
                    btnStyle="primary"
                  />
                </FormFlexItem>
              </FormFlexWrapper>

              <InputField
                id="cardDetailsMagneticStripNo"
                name="magneticStripeNo"
                value={values.magneticStripeNo}
                label={t(CardSectionDetailsTrans + "CardMagnetStripNo")}
                classes="form__group"
                onChange={handleChange}
              />
              <InputField
                id="cardDetailsVehicleRegistrationNoForInvoice"
                name="vehicleRegistrationNoForInvoice"
                value={values.vehicleRegistrationNoForInvoice}
                label={t(CardSectionDetailsTrans + "CardCarRegNoForInvoice")}
                classes="form__group"
                onChange={handleChange}
              />
            </FormCol>
            <FormCol className="u-margin-left-small">
              <BoolSelectInputField
                id="cardDetailsIsActiveCl"
                name="isActiveCl"
                value={values.isActiveCl}
                isCl={true}
                label={t(CardSectionDetailsTrans + "CardIsActive")}
                classes="form__group"
                onChange={handleChange}
              />
              <DateInputField
                id="cardDetailsChipLastUsedDate"
                name="chipLastUsedDate"
                value={values.chipLastUsedDate}
                label={t(CardSectionDetailsTrans + "CardChipLastUsedDate")}
                type="date"
                classes="form__group"
                onChange={e => handleChange(e)}
              />
              <DateInputField
                id="cardDetailsValidFrom"
                name="validFrom"
                value={values.validFrom}
                label={t(CardSectionDetailsTrans + "CardValidFrom")}
                type="date"
                classes="form__group"
                onChange={e => handleChange(e)}
              />
              <DateInputField
                id="cardDetailsValidThru"
                name="validThru"
                value={values.validThru}
                label={t(CardSectionDetailsTrans + "CardValidThru")}
                type="date"
                classes="form__group"
                onChange={e => handleChange(e)}
              />
            </FormCol>
          </FormFlexWrapper>
          <TextareaInputField
            id="cardDetailsAdditionalInfo"
            name="additionalInfo"
            value={values.additionalInfo}
            type="text"
            classes="form__block form__group form__block--multiline"
            label={t(CardSectionDetailsTrans + "CardAdditionalInfo")}
            onChange={handleChange}
          />
        </FormBody>
      )}
    </Formik>
  );
};

export default CardSectionDetailsForm;
