import React, { useRef } from "react";
import {
  SectionBody,
  SectionHeader,
  SectionMain
} from "../../../common/section";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import useLayoutActionDispatchers from "../../../common/redux/actions/layout/hooks/useLayoutActionDispatchers";
import { TASKS } from "../../../common/redux/types/viewTypes";
import useLayoutState from "../../../common/redux/hooks/useLayoutState";
import { tasks } from "../../../common/redux/types/selectorTypes";
import useFactoringDebtorActions from "../../../factoring/debtor/hooks/useFactoringDebtorActions";
import useTasksConsts from "../../hooks/useTasksConsts";
import useDataState from "../../../common/redux/hooks/useDataState";
import useSearchState from "../../../common/redux/hooks/useSearchState";
import TasksTable from "../../tasksTable";
import TasksButtons from "./tasksButtons";

const TasksMainSection = () => {
  const { t } = useTranslation();
  const tableRef = useRef();
  const {
    handleSearchToggle,
    handleExpandersToggle,
    handleTableToggle
  } = useLayoutActionDispatchers(TASKS);
  const { filterLabels, tabs } = useTasksConsts();
  const { tableVisible, expandersVisible, searchOpen } = useLayoutState(tasks);
  const { handleSearch, handleRowSelect } = useFactoringDebtorActions();
  const { selectedItem } = useDataState(tasks);
  const { searchQuery } = useSearchState(tasks);
  return (
    <SectionMain toggleSearch={handleSearchToggle}>
      <SectionHeader
        sectionHeading={t("tasks.Header")}
        getFilters={() => {}}
        searchQuery={searchQuery}
        filterLabels={filterLabels}
        tableVisible={tableVisible}
        expandersVisible={expandersVisible}
        tableEnabled={true}
        expandersEnabled={true}
        searchOpen={searchOpen}
        tabsEnabled={false}
        onToggleTable={handleTableToggle}
        onToggleExpanders={handleExpandersToggle}
        onToggleSearch={handleSearchToggle}
        onSearch={handleSearch}
      />

      <SectionBody
        TableComponent={TasksTable}
        ButtonsComponent={TasksButtons}
        shortcutsEnabled={false}
        selectedRow={selectedItem}
        tableVisible={tableVisible}
        onRowSelect={handleRowSelect}
        selectedItem={!_.isEmpty(selectedItem) ? selectedItem.name : ""}
        tableRef={tableRef}
        expandersEnabled={expandersVisible}
        tabs={tabs}
      />
    </SectionMain>
  );
};

export default TasksMainSection;
