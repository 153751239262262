import axios from "axios";
import { toast } from "react-toastify";
import { authorizationHeaders } from "../../../services/queries";
import {
  LOAD_DATA_ERROR,
  LOAD_DATA_START,
  LOAD_DATA_SUCCESS,
  SUBMIT_QUERY
} from "../../common/redux/actions/search/searchActions";
import { CONTRACT } from "../../common/redux/types/viewTypes";
import { extractQueryParameters } from "../../../services/query";

export const SET_CONTRACT_DROPDOWN_VALUES = payload => ({
  type: "SET_CONTRACT_DROPDOWN_VALUES",
  payload: payload
});

export const getContractDropdownData = () => {
  return dispatch => {
    getContractResellersDropdownData().then(d => {
      dispatch(SET_CONTRACT_DROPDOWN_VALUES({ resellers: d }));
      getContractServiceProvidersDropdownData().then(d =>
        dispatch(SET_CONTRACT_DROPDOWN_VALUES({ serviceProviders: d }))
      );
    });
  };
};

export const contractQuery = payload => {
  return dispatch => {
    dispatch(SUBMIT_QUERY(CONTRACT, payload));
    dispatch(LOAD_DATA_START(CONTRACT));
    loadContractData(payload)
      .then(data => dispatch(LOAD_DATA_SUCCESS(CONTRACT, data)))
      .catch(error => {
        toast.error("Error loading CONTRACT data");
        dispatch(LOAD_DATA_ERROR(CONTRACT, error.message));
      });
  };
};

export const getContractResellersDropdownData = async () => {
  try {
    const data = await axios.post(
      "/graphql",
      {
        query: `query {
      resellers{
        name
        id
      }
    }`
      },
      authorizationHeaders()
    );
    return data.data.data.resellers;
  } catch (err) {
    toast.error("Error loading RESELLERS");
    return [];
  }
};

export const getContractServiceProvidersDropdownData = async () => {
  try {
    const data = await axios.post(
      "/graphql",
      {
        query: `query {
      serviceProviders{
        name
        id
      }
    }`
      },
      authorizationHeaders()
    );
    return data.data.data.serviceProviders;
  } catch (err) {
    toast.error("Error loading SERVICE PROVIDERS");
    return [];
  }
};

export const loadContractData = async payload => {
  const queryData = extractQueryParameters(payload);
  console.log(queryData);
  try {
    const data = await axios.post(
      "/graphql",
      {
        query: `
      query {
        contracts(${queryData}) {
          id
          contractTypeCl
          customer {name}
          isActiveCl
          contractTemplate {name id}
          serviceProvider {name id}
          validFrom
          validThru
          contractNo
          reseller {name id}
          currentStatusCl
          currentStatusDate
          customerNo
          additionalInfo
          contractStatusHistory {id statusCl statusDate}
          productCostOverview {
         id
            code
            default_name
            sales_point_group_name
            default_name_2
            value_type_cl
            default_value
            level1_quantity_min
            level1_value
            level2_quantity_min
            level2_value
          }
          factoringBankAccount{
            bankAccountTypeCl
            bank{swift name}
            iban
          }
          factoringInvoices {
            factoringDebtor {name}
            invoiceNo
            invoiceDate
            dueDate
            total
            handlingFee
            contractFeePercent
          }
          
          
          factoringContractDebtors {
            id
            company {
              id
              name
              factoringDebtorServiceFees {
                createdOn
                invoiceFactoringServiceFeeTypeCl
                fixedValue
                dailyValue
              }
            }
            contract {
              handlingFees {
                createdOn
                invoiceFactoringHandlingFeeTypeCl
                value
              }
            }
          }
        }
        
      }`
      },
      authorizationHeaders()
    );
    return data.data.data.contracts;
  } catch (err) {
    toast.error("Error loading contracts");
    return [];
  }
};

const getContractQueryData = ({
  resultCount,
  customerName,
  contractType,
  contractStatus,
  contractNo,
  isActive,
  id,
  contractExpiresFrom,
  contractExpiresUntil,
  customerCountry,
  regno,
  vatNo,
  generalPhone,
  generalEmail,
  salesAgent
}) => {
  const queryData = `${resultCount ? `count: ${resultCount}` : ""}${
    customerName ? `customer: "${customerName}"` : ""
  }${isActive ? `is_active: ${isActive}` : ""} ${id ? "id: " + id : ""} ${
    regno ? "reg_no: " + regno : ""
  } 
  ${contractType ? `contract_type: "${contractType}"` : ""}
  ${contractStatus ? `contractStatus: "${contractStatus}"` : ""}
  ${vatNo ? "vat_no: " + vatNo : ""} 
  ${customerCountry ? `customerCountry: ${customerCountry}` : ""}
  ${contractExpiresFrom ? `expiresFrom: "${contractExpiresFrom}"` : ""}
  ${contractExpiresUntil ? `expiresUntil: "${contractExpiresUntil}"` : ""}
  ${contractNo ? `contractNo: "${contractNo}"` : ""}
  ${generalPhone ? `general_phone: "${generalPhone}"` : ""} ${
    generalEmail ? `email: "${generalEmail}"` : ""
  } ${salesAgent ? `sales_agent: "${salesAgent}"` : ""}`;
  return queryData;
};
