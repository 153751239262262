import React from "react";
import {
  FormBody,
  FormCol,
  InputField,
  SelectInputField
} from "../../../../common/form/form";
import useDataState from "../../../../common/redux/hooks/useDataState";
import { Formik } from "formik";
import { customerAccount } from "../../../../common/redux/types/selectorTypes";
import useAppClassifications from "../../../../layout/hooks/useAppClassifications";

const CustomerAccountDetailsForm = ({ t }) => {
  const CustomerAccountDetailsTrans = "customerAccount.details.";
  const { entrySourceTypes } = useAppClassifications();
  const { selectedItem: data } = useDataState(customerAccount);
  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={{
          additionalInfo: data.additionalInfo || "",
          client: data.customer ? data.customer[0].name : "",
          date: data.entryTime || "",
          source_reference: data.entrySource || "",
          entry_source_type: data.entrySourceTypeCl || "",
          amount: data.sum || "",
          total: data.total || ""
        }}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            alert(JSON.stringify(values, null, 2));
            setSubmitting(false);
          }, 400);
        }}
      >
        {({
          values,
          errors,
          status,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting
        }) => (
          <FormBody flex={"true"}>
            <FormCol>
              <InputField
                id="customerAccountDetailsClient"
                name="client"
                disabled={true}
                classes="form__group form__group--display"
                label={t(CustomerAccountDetailsTrans + "Client")}
                value={values.client}
              />
              <InputField
                id="customerAccountDetailsSourceReference"
                name="source_reference"
                disabled={true}
                classes="form__group form__group--display"
                label={t(CustomerAccountDetailsTrans + "SourceReference")}
                value={values.source_reference}
              />
            </FormCol>
            <FormCol className="u-margin-left-small">
              <InputField
                id="customerAccountDetailsDate"
                name="date"
                disabled={true}
                classes="form__group form__group--display"
                label={t(CustomerAccountDetailsTrans + "Date")}
                value={values.date}
              />
              <InputField
                id="customerAccountDetailsAmount"
                name="amount"
                disabled={true}
                classes="form__group form__group--display"
                label={t(CustomerAccountDetailsTrans + "Total")}
                value={values.amount}
              />
            </FormCol>
            <FormCol className="u-margin-left-small">
              <div className="form__group form__group--display">
                <label>
                  {t(CustomerAccountDetailsTrans + "EntrySourceType")}
                </label>
                <SelectInputField
                  id="customerAccountSearchSourceReference"
                  name="source_reference"
                  value={values.entry_source_type}
                  options={[
                    { name: "", value: "" },
                    ...entrySourceTypes.map(item => {
                      return {
                        name: t(`classifications.${item.name}`),
                        value: item.value
                      };
                    })
                  ]}
                >
                  <option>SWED EE</option>
                </SelectInputField>
              </div>
            </FormCol>
            <div className="form-col u-margin-left-small">
              <div className="form__block form__group form__block--multiline form__group--display">
                <label>
                  {t(CustomerAccountDetailsTrans + "AdditionalInformation")}
                </label>
                <textarea
                  type="text"
                  name="additionalInfo"
                  value={values.additionalInfo}
                  onChange={handleChange}
                />
              </div>
            </div>
          </FormBody>
        )}
      </Formik>
    </div>
  );
};

export default CustomerAccountDetailsForm;
