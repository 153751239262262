import axios from "axios";
import { authorizationHeaders } from "../../../../services/queries";
import { extractQueryParameters } from "../../../../services/query";

export const fetchFactoringDebtorOverdueInvoicesData = async payload => {
  const queryData = extractQueryParameters(payload);
  const data = await axios.post(
    "/graphql",
    {
      query: `
        query {
  penaltyInvoices(${queryData} type:FACTORING_DEBTOR){
    id
    customerName
    invoiceNo
    invoiceDate
    dueDate
    totalInPcy
    isEmailSentCl
  }
}
        `
    },
    authorizationHeaders()
  );

  return data;
};
