import React, { forwardRef } from "react";
import { Formik } from "formik";
import {
  BoolSelectInputField,
  DateInputField,
  InputField
} from "../../../../common/form/form";
import { useTranslation } from "react-i18next";
import AutoCompleteField from "../../../../common/autocomplete/autocompleteField";
import { loadCompanyAutocompleteData } from "../../../../common/autocomplete/graphql/fetchCustomerAutocomplete";

const FactoringDebtorOverdueInvoicesSearchForm = forwardRef(
  ({ handleSearch, query }, ref) => {
    const { t } = useTranslation();
    const TranslationNS = "factoring.debtor.overdueInvoices.";

    return (
      <div className="section__search-form">
        <div style={{ height: "100%", overflow: "visible" }}>
          <Formik
            ref={ref}
            enableReinitialize={true}
            // validationSchema={CustomerSearchSchema}
            initialValues={{
              company: query.company || "",
              invoice_no: query.invoice_no || "",
              email_sent_cl: query.email_sent_cl || "",
              invoice_date_from: query.invoice_date_from || "",
              invoice_date_to: query.invoice_date_to || "",
              is_paid: query.is_paid || "",
              invoice_not_issued: query.invoice_not_issued || "",
              issued_for_invoice_no: query.issued_for_invoice_no || "",
              count: query.count || 1000
            }}
            onSubmit={(values, { setSubmitting }) => {
              handleSearch(values);
            }}
          >
            {({
              values,
              handleChange,
              handleSubmit,
              validateField,
              validateOnChange,
              errors,
              touched,
              setFieldValue
            }) => (
              <form onSubmit={handleSubmit}>
                <AutoCompleteField
                  type="text"
                  classes="form__group-stack"
                  id="factoringDebtorOverdueInvoicesCustomer"
                  name="company"
                  value={values.company}
                  handleChange={handleChange}
                  label={t(TranslationNS + "FilterFactoringDebtor")}
                  autocomplete="password"
                  data-lpignore="true"
                  fetchAutcompleteData={loadCompanyAutocompleteData}
                />

                <InputField
                  id="factoringDebtorOverdueInvoicesSearchInvoiceNo"
                  name="invoice_no"
                  type="text"
                  classes="form__group-stack"
                  value={values.invoice_no}
                  onChange={handleChange}
                  label={t(TranslationNS + "FilterInvoiceNo")}
                />
                <InputField
                  type="text"
                  classes="form__group-stack"
                  id="factoringDebtorOverdueInvoicesSearchEmailSent"
                  name="isEmailSentCl"
                  value={values.isEmailSentCl}
                  onChange={handleChange}
                  label={t(TranslationNS + "FilterEmailSent")}
                />
                <DateInputField
                  type="date"
                  classes="form__group-stack"
                  id="factoringDebtorOverdueInvoicesInvoiceDateFrom"
                  name="invoice_date_from"
                  value={values.invoice_date_from}
                  handleChange={setFieldValue}
                  label={t(TranslationNS + "FilterInvoiceDateFrom")}
                />

                <DateInputField
                  type="date"
                  classes="form__group-stack"
                  id="factoringDebtorOverdueInvoicesInvoiceDateTo"
                  name="invoice_date_to"
                  placement="top"
                  value={values.invoice_date_to}
                  handleChange={setFieldValue}
                  label={t(TranslationNS + "FilterInvoiceDateTo")}
                />

                <BoolSelectInputField
                  classes="form__group-stack"
                  name="is_paid"
                  id="factoringDebtorOverdueInvoicesIsPaid"
                  value={values.is_paid}
                  onChange={handleChange}
                  label={t(TranslationNS + "FilterIsPaid")}
                />

                <BoolSelectInputField
                  classes="form__group-stack"
                  name="invoice_not_issued"
                  id="factoringDebtorOverdueInvoicesInvoiceNotIssued"
                  value={values.invoice_not_issued}
                  onChange={handleChange}
                  label={t(TranslationNS + "FilterInvoiceNotIssued")}
                />

                <InputField
                  type="number"
                  classes="form__group-stack"
                  id="factoringDebtorOverdueInvoicesSearchIssuedForInvoiceNo"
                  name="issued_for_invoice_no"
                  value={values.issued_for_invoice_no}
                  // error={errors.count}
                  onChange={handleChange}
                  label={t(TranslationNS + "FilterIssuedForInvoiceNo")}
                />
                <InputField
                  type="number"
                  classes="form__group-stack"
                  id="factoringDebtorOverdueInvoicesResultCount"
                  name="count"
                  value={values.count}
                  onChange={handleChange}
                  label={t(TranslationNS + "FilterRowCount")}
                />
              </form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
);

export default FactoringDebtorOverdueInvoicesSearchForm;
